<div layout="row" layout-align="space-around center">
  <div layout="row" layout-align="center center">
    <md-input-container>
      <label>{{'SEASON' | translate}}</label>
      <md-select ng-model="vm.selectedSeasonId" ng-change="vm.changeMandatoryFilter()">
        <md-option ng-repeat="season in vm.seasons" ng-value="season.id">
          {{season.name}}
        </md-option>
      </md-select>
    </md-input-container>

    <md-input-container>
      <label>{{'ENTITY' | translate}}</label>
      <md-select ng-model="vm.selectedEntityId" ng-change="vm.changeMandatoryFilter()">
        <md-option ng-repeat="entity in vm.entities" ng-value="entity.id">
          {{entity.name}}
        </md-option>
      </md-select>
    </md-input-container>

    <md-input-container>
      <label>{{'PROGRAM_TYPE' | translate}}</label>
      <md-select ng-model="vm.selectedProgramme" ng-change="vm.changeFilter()">
        <md-option ng-value="undefined"><em>{{'ALL_PROGRAMMES' | translate}}</em></md-option>
        <md-option ng-repeat="programme in vm.programmes" ng-value="programme.name">
          {{programme.name | translate}}
        </md-option>
      </md-select>
    </md-input-container>
  </div>
</div>

<div layout="row" layout-align="center center" ng-if="!vm.selectedEntityId" flex="100">
  <span style="padding-top: 1%" ><b>{{'STATISTICS.SELECT_AN_ENTITY' | translate}}</b></span>
</div>

<div layout="column" layout-align="space-between center" ng-if="vm.selectedEntityId">
  <div layout="column">
    <h1 class="md-title">{{vm.myChartObjectPlayers.options.title | translate}}</h1>
    <div google-chart chart="vm.myChartObjectPlayers" style="width:100%;"
         agc-on-ready="vm.readyPlayerHandler(chartWrapper)"></div>
    <div layout="row" layout-align="end center">
      <neo-download-csv-table html-table="vm.htmlPlayerTable"
                              filename='STATISTICS.PLAYER_LICENSES_EXCEL_FILENAME'></neo-download-csv-table>
    </div>
  </div>

  <div layout="column">
    <h1 class="md-title">{{vm.myChartObjectNoPlayers.options.title | translate}}</h1>
    <div google-chart chart="vm.myChartObjectNoPlayers" style="width:100%;"
         agc-on-ready="vm.readyNoPlayerHandler(chartWrapper)"></div>
    <div layout="row" layout-align="end center">
      <neo-download-csv-table html-table="vm.htmlNoPlayerTable"
                              filename='STATISTICS.NO_PLAYER_LICENSES_EXCEL_FILENAME'></neo-download-csv-table>
    </div>
  </div>

  <div layout="column">
    <h1 class="md-title">{{vm.myChartObjectTeams.options.title | translate}}</h1>
    <div google-chart chart="vm.myChartObjectTeams" style="width:100%;"
         agc-on-ready="vm.readyTeamHandler(chartWrapper)"></div>
    <div layout="row" layout-align="end center">
      <neo-download-csv-table html-table="vm.htmlTeamTable"
                              filename='STATISTICS.TEAM_EXCEL_FILENAME'></neo-download-csv-table>
    </div>
  </div>
</div>
