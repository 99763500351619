<div layout="row" layout-padding layout-align="space-around center" flex>
    <md-card class="md-whiteframe-z2" flex-gt-md="70" flex="100">
        <md-card-header layout-padding class="md-whiteframe-1dp">
            <md-card-header-text>
                <span class="md-headline">{{ 'CHAMPIONSHIPS.DRAW.TITLE' | translate}}</span>
            </md-card-header-text>
        </md-card-header>
        <md-card-content layout="column" layout-align="space-around stretch">
            <div flex>
                <h1 class="md-title">{{'CHAMPIONSHIPS.DATA' | translate}}</h1>
                <md-divider></md-divider>
                <div layout="row" layout-padding layout-wrap>
                    <!-- NAME -->
                    <neo-input name="name" label="CHAMPIONSHIPS.NAME" icon="images/ic_title_black_48px.svg"
                               ng-model="vm.championship.name" flex="50" disabled="true"></neo-input>
                    <!-- ROUNDS -->
                    <neo-input name="rounds" label="CHAMPIONSHIPS.ROUNDS" ng-model="vm.championship.rounds" flex="33"
                               icon="images/ic_repeat_black_48px.svg" disabled="true"></neo-input>
                    <!-- CATEGORY -->
                    <neo-input name="category" label="CATEGORY" icon="images/ic_folder_black_48px.svg"
                               ng-model="vm.category" flex="33" disabled="true"></neo-input>
                    <!-- SPORT -->
                    <neo-input name="sport" label="SPORT" icon="images/football.svg"
                               ng-model="vm.sport" flex="33" disabled="true"></neo-input>
                    <!-- GENDER -->
                    <neo-input name="gender" label="GENDER" icon="images/gender-male-female.svg"
                               ng-model="vm.gender" flex="33" disabled="true"></neo-input>

                </div>
            </div>

            <form name="drawForm" ng-submit="vm.drawChampionship()" flex layout="row">
                <div flex>
                    <h1 class="md-title">{{'CHAMPIONSHIPS.DRAW.SELECT_MATCH_DAYS' | translate}}</h1>
                    <md-divider></md-divider>
                    <div layout-padding>
                        <md-input-container ng-repeat="matchDay in vm.matchDays">
                            <label>{{'CHAMPIONSHIPS.MATCH_DAY' | translate}} {{$index + 1}}</label>
                            <md-datepicker name="{{matchDay.label}}" ng-model="matchDay.date"
                                           md-current-view="month" ng-required="true"></md-datepicker>
                            <div ng-messages="drawForm.elements.namedItem(matchDay.label).$error" role="alert">
                                <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                            </div>
                        </md-input-container>
                    </div>
                </div>
                <div class="listTeams" flex>
                    <h1 class="md-title">{{'CHAMPIONSHIPS.DRAW.SELECT_TEAM_ORDER' | translate}}</h1>
                    <md-divider></md-divider>

                    <div layout="row">
                        <ul class="matchIndex" flex="10">
                            <li ng-repeat="team in vm.teams">
                                {{$index + 1}}
                            </li>
                        </ul>
                        <ul dnd-list="vm.teams" flex="90">
                            <li ng-repeat="item in vm.teams"
                                dnd-draggable="item"
                                dnd-moved="vm.teams.splice($index, 1)"
                                dnd-effect-allowed="move"
                                dnd-selected="vm.selectedTeam = item"
                                ng-class="{'selected': vm.selectedTeam === item}">
                                {{item.name}}
                            </li>
                        </ul>
                    </div>
                </div>
                <md-button type="submit" class="md-fab md-accent md-fab-bottom-right" ng-disabled="drawForm.$invalid"
                           ng-if="vm.teams.length > 0">
                    <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="draw"></md-icon>
                </md-button>
            </form>
        </md-card-content>
    </md-card>
</div>
