<div layout="row" layout-padding layout-align="space-around center" flex>
  <md-card class="md-whiteframe-z2" flex-gt-md="100" flex="100">
    <md-card-header layout-padding class="md-whiteframe-1dp">
      <md-card-header-text>
        <span class="md-headline">{{ 'CHAMPIONSHIPS.EDIT_CALENDAR' | translate}}</span>
      </md-card-header-text>
    </md-card-header>
    <md-card-content layout="column" layout-align="space-around stretch">

      <div flex>
        <h1 class="md-title">{{'CHAMPIONSHIPS.CHAMPIONSHIP' | translate}}</h1>
        <md-divider></md-divider>
        <div layout="row" layout-align="space-around none" layout-padding layout-wrap>
          <!-- NAME -->
          <neo-input name="name" label="CHAMPIONSHIPS.NAME" icon="images/ic_title_black_48px.svg"
                     ng-model="vm.championship.name" flex="25" is-disabled="true"></neo-input>
          <!-- CATEGORY -->
          <neo-input name="category" label="CATEGORY" icon="images/ic_folder_black_48px.svg"
                     ng-model="vm.category" flex="25" is-disabled="true"></neo-input>
          <!-- SPORT -->
          <neo-input name="sport" label="SPORT" icon="images/football.svg"
                     ng-model="vm.sport" flex="25" is-disabled="true"></neo-input>
          <!-- ROUNDS -->
          <neo-input name="gender" label="GENDER" icon="images/gender-male-female.svg"
                     ng-model="vm.gender" flex="25" is-disabled="true"></neo-input>
        </div>
      </div>

      <div flex id="matches">
        <h1 class="md-title">{{'CALENDAR.TITLE' | translate}}</h1>
        <md-divider></md-divider>

        <form name="matchesForm" layout="column" layout-wrap layout-padding>

          <div>
            <md-button class="md-raised md-primary" ng-click="vm.addMatchDay(vm.matchDays)">{{'CALENDAR.ADD_MATCH_DAY' |
              translate}}
            </md-button>
          </div>

          <div id="matchday{{$index}}" class="matchday-container" ng-repeat="matchDay in vm.matchDays" flex>
            <md-list layout="column" flex>
              <div class="match-header" layout="row" layout-align="space-between center"
                   md-colors="{background: 'primary'}" layout-padding flex>
                <span class="md-title">{{'CHAMPIONSHIPS.MATCH_DAY' | translate}} {{matchDay.number}}</span>
                <md-button class="md-icon-button md-warn" ng-click="vm.deleteMatchDay(vm.matchDays, $index)">
                  <md-tooltip md-direction="right" md-delay="50">{{'DELETE' | translate}}</md-tooltip>
                  <md-icon>delete</md-icon>
                </md-button>
              </div>
              <div layout="row" layout-align="center">
                <md-input-container>
                  <input type="number" ng-model="matchDay.number" min="1" name="matchDay{{$index}}"
                         placeholder="{{'CALENDAR.MATCH_DAY_NUMBER' | translate}}"/>
                </md-input-container>
                <md-input-container>
                  <md-datepicker name="date" ng-model="matchDay.date" ng-required="true"
                                 md-placeholder="Enter date"></md-datepicker>
                  <div ng-messages="matchesForm.iniDate.$error" role="alert">
                    <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                  </div>
                </md-input-container>
              </div>


              <md-list-item class="match-container" ng-repeat="match in matchDay.matches"
                            layout-xs="column" layout-sm="column" layout-md="row" layout-gt-md="row"
                            layout-align="space-around stretch" layout-fill layout-wrap
                            ng-style="$odd && {'background-color': vm.getColor('primary-50')} ||
                            {'background-color': vm.getColor('background')}">


                <div flex="45" layout="row" layout-align="space-around stretch" layout-fill layout-wrap>
                  <md-input-container flex>
                    <md-select ng-model="match.localTeamId" placeholder="{{'CALENDAR.LOCAL_TEAM' | translate}}"
                               ng-required="true" md-no-asterisk="true">
                      <md-option ng-value="null">
                        <em>{{'CALENDAR.REST' | translate}}</em>
                      </md-option>
                      <md-option ng-repeat="(id, team) in vm.teams" ng-value="id">
                        {{team.teamName}}
                      </md-option>
                    </md-select>
                  </md-input-container>

                  <md-input-container flex>
                    <md-select ng-model="match.visitorTeamId" placeholder="{{'CALENDAR.VISITOR_TEAM' | translate}}"
                               ng-required="true" md-no-asterisk="true">
                      <md-option ng-value="null">
                        <em>{{'CALENDAR.REST' | translate}}</em>
                      </md-option>
                      <md-option ng-repeat="(id, team) in vm.teams" ng-value="id">
                        {{team.teamName}}
                      </md-option>
                    </md-select>
                  </md-input-container>
                </div>

                <div flex="25" layout="row" layout-align="space-around stretch" layout-fill>
                  <md-input-container flex="60">
                    <md-datepicker name="date" ng-model="match.date" ng-required="true"
                                   md-placeholder="Enter date"></md-datepicker>
                    <div ng-messages="matchesForm.iniDate.$error" role="alert">
                      <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                    </div>
                  </md-input-container>
                  <md-input-container flex="40" md-no-float>
                    <label>hh:mm</label>
                    <input type="time" ng-model="match.time"/>
                  </md-input-container>
                </div>

                <div flex="25" layout="row" layout-align="space-around stretch" layout-fill layout-wrap>
                  <md-input-container flex>
                    <md-select ng-model="match.facilityId" placeholder="{{'CALENDAR.FACILITY' | translate}}"
                               ng-required="true" md-no-asterisk="true">
                      <md-option ng-value="null">
                        <em>{{'CALENDAR.NOT_FACILITY_ASSIGNED' | translate}}</em>
                      </md-option>
                      <md-option ng-repeat="facility in vm.facilities" ng-value="facility.id">
                        {{facility.name}}
                      </md-option>
                    </md-select>
                  </md-input-container>
                </div>
                <div flex>
                  <md-button class="md-icon-button md-warn" ng-click="vm.deleteMatch($parent.$index, $index)">
                    <md-tooltip md-direction="right" md-delay="50">{{'DELETE' | translate}}</md-tooltip>
                    <md-icon>delete</md-icon>
                  </md-button>
                </div>

              </md-list-item>

              <div>
                <md-button class="md-raised md-primary" ng-click="vm.addMatch(matchDay)">{{'CALENDAR.ADD_MATCH' |
                  translate}}
                </md-button>
              </div>

            </md-list>
          </div>
        </form>
      </div>

    </md-card-content>
  </md-card>
  <div flex>
    <a ui-sref="home.championships.championship">
      <md-button class="md-fab md-warn md-fab-bottom-right" style="margin-bottom: 72px;">
        <md-icon md-svg-src="images/ic_cancel_white_48px.svg" class="s24" aria-label="cancel"></md-icon>
        <md-tooltip md-delay="50" md-direction="left">{{'CANCEL' | translate}}</md-tooltip>
      </md-button>
    </a>
  </div>
  <div flex>
    <md-button class="md-fab md-accent md-fab-bottom-right" ng-disabled="matchesForm.$invalid"
               ng-click="vm.saveMatchDays()">
      <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
      <md-tooltip md-delay="50" md-direction="left">{{'SAVE' | translate}}</md-tooltip>
    </md-button>
  </div>

</div>
