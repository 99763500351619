<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/>
<div layout="column" ui-view>
  <div layout="row" layout-align="space-around center" layout-padding>
    <form ng-submit="vm.applyFilters()" name="listFilters" layout="row" flex-gt-md="70" flex="100"
          layout-align="space-around center">
      <md-autocomplete flex="40" flex-gt-md="50" md-selected-item="vm.selectedTownHall"
                       md-search-text="vm.searchText"
                       md-items="townhall in vm.townhalls | filterAccents: vm.searchText"
                       md-item-text="townhall.name"
                       md-require-match
                       ng-required="true"
                       md-min-length="0"
                       ng-disabled="vm.townhalls.length === 1"
                       md-clear-button="true"
                       md-floating-label="{{'SELECT_TOWNHALL_TO_FILTER' | translate}}" style="padding-top: 15px">
        <md-item-template>
          <span md-highlight-text="vm.searchText" md-highlight-flags="^i">{{townhall.name}}</span>
        </md-item-template>
        <md-not-found>
          {{'RESULT_NOT_FOUND' | translate}}
        </md-not-found>
      </md-autocomplete>
      <md-datepicker md-current-view="month" ng-required="true" ng-model="vm.iniDate"
                     md-placeholder="{{'INI_DATE' | translate}}" md-min-date="vm.currentSeason.iniDate"
                     md-max-date="vm.endDate"></md-datepicker>
      <md-datepicker md-current-view="month" ng-required="true" ng-model="vm.endDate"
                     md-placeholder="{{'END_DATE' | translate}}" md-min-date="vm.iniDate"
                     md-max-date="vm.currentSeason.endDate"
                     ng-disabled="vm.iniDate === null"></md-datepicker>
      <md-button type="submit" ng-disabled="listFilters.$invalid" class="md-raised md-primary">{{'FILTER' |
        translate}}
      </md-button>
    </form>
    
  </div>



   

    <div layout="row" layout-align="space-around center" ng-if="vm.isFiltered">
    <md-card flex-gt-md="80" flex="100">

      <div class="md-whiteframe-1dp" flex>
             <md-button ng-click="exportToExcel('table1', 'Report')">
                 <md-icon md-svg-src = '../../images/notebook.svg'></md-icon>
            </md-button> 
        <md-card-header layout-padding>
          <md-card-header-text>
            <span class="md-headline">Partits arbitrats</span> 
          </md-card-header-text>
        </md-card-header>
      </div>
      <md-card-content>
        <md-table-container>
          <table md-table md-row-select="true" ng-model="vm.selected"  id="table1">
            <thead md-head md-order="vm.query.order">
                
                
            <tr md-row ng-hide="!vm.filteredList.length">
                 <th>Facturar</th>
             <th md-column md-order-by="numinvoice"><span class="md-title">Num.Factura</span></th>
              <th md-column md-order-by="fecha"><span class="md-title">Data</span></th>
               <th md-column md-order-by="idreceptor"><span class="md-title">idReceptor</span></th>
              <th md-column md-order-by="cognom1"><span class="md-title">Cognom 1</span></th>
               <th md-column md-order-by="cognom2"><span class="md-title">Cognom 2</span></th>
                <th md-column md-order-by="nom"><span class="md-title">Nom</span></th>
             
              <th md-column show-gt-xs hide><span class="md-title">DNI</span></th>
              <th md-column show-gt-xs hide><span class="md-title">Adreça</span></th>
               <th md-column show-gt-xs hide><span class="md-title">Municipi</span></th>
             <th md-column show-gt-xs hide><span class="md-title">Concepte</span></th>
                 <th md-column show-gt-xs hide><span class="md-title">Num. partits</span></th>
                   <th md-column show-gt-xs hide><span class="md-title">Preu brut</span></th>
                <th md-column show-gt-xs hide><span class="md-title">Total</span></th>
            </tr>
            </thead>
            <tbody md-body>
            <tr md-row md-select="vm.select"
                ng-repeat="persona in vm.filteredList = (vm.llistatpersones | filter:searchText ) | orderBy: vm.query.order">
                  <td md-cell><md-checkbox ng-model="persona.selected" aria-label="license" ng-click="vm.enable()"></md-checkbox></td>
                   <td md-cell><span class="md-body-2">{{ persona.numinvoice }} </span></td>
                   <td md-cell><span class="md-body-2">{{ persona.fecha }} </span></td>
                     <td md-cell><span class="md-body-2">{{ persona.idreceptor }} </span></td>
                 <td md-cell><span class="md-body-2">{{ persona.cognom1 }} </span></td>
                 <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.cognom2 }}</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.nom }}</span></td>
          
            <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.dni }}</span></td>
             <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.adress}}</span></td>
             <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.municipi}}</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.esport}}</span></td>
             <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.numpartits }}</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.preu }}</span></td>
                    <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.total}}</span></td>
            </tr>
            </tbody>
          </table>
        </md-table-container>
        <span class="md-body-2" ng-hide="vm.filteredList.length"> No s'han trobat dades </span>
      </md-card-content>
    </md-card>
</div>
</div>
            <div>
  <neo-action-button buttons="vm.buttons" ng-if="(vm.filteredList.length > 0)&&((vm.isAdm)||(vm.isCorn))"></neo-action-button>
</div>