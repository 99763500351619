<div class="md-whiteframe-2dp" flex layout-padding>
  <h1 class="md-title">{{'PERSONAL' | translate }}</h1>
  <md-divider></md-divider>
  <md-list class="md-dense" flex>
    <md-list-item ng-repeat="person in vm.people">
      <span class="md-body-1">{{ person.email }}</span>
      <md-divider ng-if="!$last"></md-divider>
    </md-list-item>
  </md-list>
</div>

<div class="md-whiteframe-2dp" flex layout-padding>
  <h1 class="md-title">{{'USERS_ACCESS' | translate}}</h1>
  <md-divider></md-divider>
  <md-list flex>
    <md-list-item ng-repeat="user in vm.users" ui-sref="home.userProfile({userId: user.id})">
      <span class="md-body-1">{{ user.username }}</span>
      <md-divider ng-if="!$last"></md-divider>
    </md-list-item>
  </md-list>
</div>