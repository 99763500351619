<div ui-view>
  <md-content layout="column" flex-gt-md="30" flex="70" style="margin: auto">
    <md-input-container>
      <md-icon md-svg-src="images/ic_search_black_48px.svg" class="s24" aria-label="search"></md-icon>
      <input ng-model="searchText" placeholder="Buscar">
    </md-input-container>
  </md-content>

  <div layout="row" layout-align="space-around center">
    <md-card flex-gt-md="70" flex="100">
      <div class="md-whiteframe-1dp" flex>
        <md-card-header layout-padding>
          <md-card-header-text>
            <span class="md-headline">{{'TOWN_HALL_LIST' | translate}}</span>
          </md-card-header-text>
        </md-card-header>
      </div>
      <md-card-content>

        <md-table-container>
          <table md-table md-row-select="true" ng-model="vm.selected">
            <thead md-head md-order="vm.query.order">
            <tr md-row ng-hide="!vm.filteredList.length">
              <th md-column md-order-by="name"><span class="md-title">{{'NAME' | translate}}</span></th>
              <th md-column show-gt-xs hide><span class="md-title">{{'EMAIL' | translate}}</span></th>
              <th md-column show-gt-xs hide><span class="md-title">{{'PHONE' | translate}}</span></th>
            </tr>
            </thead>
            <tbody md-body>
            <tr md-row md-select="vm.select" class="pointer-cursor"
                ui-sref="home.ajuntaments.ajuntament.informacio({ajuntamentId: ajuntament.id})"
                ng-repeat="ajuntament in vm.filteredList = (vm.llistaAjuntaments | filterAccents: searchText ) | orderBy: vm.query.order">
              <td md-cell><span class="md-body-1">{{ ajuntament.name }}</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-1">{{ ajuntament.email }}</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-1">{{ ajuntament.phoneNumber }}</span></td>
            </tr>
            </tbody>
          </table>
        </md-table-container>
        <span class="md-body-2" ng-if="!vm.filteredList.length">{{'NOT_FOUND_TOWN_HALLS' | translate}}</span>
      </md-card-content>
      <div ng-hide="vm.visibletab">       
        <md-card-content>

        <md-table-container >
          <table md-table md-row-select="false" >
            <thead md-head md-order="vm.query.order">
            <tr md-row ng-hide="!vm.filteredList.length">
              <th md-column md-order-by="name"><span class="md-title">{{'NAME' | translate}}</span></th>
              <th md-column show-gt-xs hide><span class="md-title">{{'EMAIL' | translate}}</span></th>
              <th md-column show-gt-xs hide><span class="md-title">{{'PHONE' | translate}}</span></th>
            </tr>
            </thead>
            <tbody md-body>
            <tr md-row class="pointer-cursor"
               
                ng-repeat="ajuntament in vm.filteredList = (vm.llistaAjuntamentsread | filterAccents: searchText ) | orderBy: vm.query.order">
              <td md-cell><span class="md-body-1">{{ ajuntament.name }}</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-1">{{ ajuntament.email }}</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-1">{{ ajuntament.phoneNumber }}</span></td>
            </tr>
            </tbody>
          </table>
        </md-table-container>
        <span class="md-body-2" ng-if="!vm.filteredList.length">{{'NOT_FOUND_TOWN_HALLS' | translate}}</span>
      </md-card-content>
      </div>
    </md-card>
  </div>

  <div layout-padding>
    <a ui-sref="home.ajuntaments.newAjuntament" ng-if="vm.can('TOWN_HALL', 'C')">
      <md-button class="md-fab md-accent md-fab-bottom-right">
        <md-icon md-svg-src="images/ic_add_white_48px.svg" class="s24" aria-label="add"></md-icon>
        <md-tooltip md-delay="50" md-direction="left">{{'NEW_TOWN_HALL' | translate}}</md-tooltip>
      </md-button>
    </a>
  </div>
</div>
