<table>
    <thead>
        <tr>
            <th>Id</th>
            <th> Non</th>
            <th> Cognom</th>
            <th> email</th>
            <th> Població</th>
               <th> Campionat</th>
               <th> Data</th>
                <th> Equip local</th>
                 <th> Equip Visitant</th>
             <th> Item Valorat</th>
             <th> Valor</th>
           
               
        </tr>
    </thead>
    <tbody>
        <tr ng-repeat="partit in partidos">
             <td> {{partit.id}}</td>
            <td> {{partit.nombre}}</td>
            <td> {{partit.apellido}}</td>
            <td> {{partit.email}}</td>
            <td> {{partit.poblacion}}</td>
            <td> {{partit.campionat}}</td>
              <td> {{partit.fecha}}</td>
              <td> {{partit.elocal}}</td>
                <td> {{partit.evisitant}}</td>
            <td> {{partit.item_Valorado}}</td>
             <td> {{partit.vporc}}</td>
          
             
             
    </tr>
        
    </tbody>   
</table>
