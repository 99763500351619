<div layout="row" layout="column"       layout-align="center center">
    <md-card flex-gt-md="60" flex="100">
      <div class="md-whiteframe-1dp" flex>
          <md-card-header layout-padding style="background-color:#7F8FB1;color: white">
                <md-card-header-text>
            <span class="md-body-1" >{{vm.campionat}}</span>
          </md-card-header-text>
                <md-card-header-text style="text-align:right">
            <span class="md-headline">{{vm.suspes}}</span>
          </md-card-header-text>
              </md-card-header>
          
        <md-card-header layout-padding style="background-color:#D2E0E6;color: black">
        
              <md-card-header-text>
            <span class="md-body-1">Data</span>
          </md-card-header-text>
             <md-card-header-text>
            <span class="md-body-1">Equip local</span>
          </md-card-header-text>
             <md-card-header-text>
            <span class="md-body-1">Equip visitant</span>
          </md-card-header-text>
             <md-card-header-text>
            <span class="md-body-1"></span>
          
            <span class="md-body-1"></span>
          </md-card-header-text>
           
        </md-card-header>
          
     
         
        <md-card-header layout-padding>
        
              <md-card-header-text>
            <span class="md-body-1">{{vm.data}}</span>
          </md-card-header-text>
             <md-card-header-text>
                 <span class="md-body-1"><div  ng-click="clicatlocal(1)"> {{vm.elocal}}</div></span>
          </md-card-header-text>
             <md-card-header-text>
                 <span class="md-body-1"><div  ng-click="clicatlocal(2)">{{vm.evisitant}}</div></span>
          </md-card-header-text>
             <md-card-header-text>
            <md-checkbox ng-model="vm.cc"ng-click="ccchange()">C.C.
           
            
          </md-checkbox>
          
           
              <md-checkbox ng-model="vm.suspeschk"  ng-click="ccchangesuspes()">Suspendre
            
          </md-checkbox>
          </md-card-header-text>
            
            
        </md-card-header>
          
      </div>
        
        <md-card-content>

        <md-table-container>
          <table md-table md-row-select="true" >
          
            <tbody md-body>
            <tr md-row  class="pointer-cursor">
                
              <td md-cell><span class="md-body-1">Públic</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-1"  size="5"> <input type="text" name="myDecimal" placeholder="Decimal" ng-model="vm.ppl "  style="width: 4em;text-align: right;" /></span> Punts</td>
                 <td md-cell show-gt-xs hide><span class="md-body-1"  size="5"><input type="text" name="myDecimal" placeholder="Decimal" ng-model="vm.ppv" style="width: 4em;text-align: right;"/></span> Punts</td>
       
            </tr>
              <tr md-row  class="pointer-cursor">
                
              <td md-cell><span class="md-body-1">Tutor de Grada</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-1"><input type="text" name="myDecimal" placeholder="Decimal" ng-model="vm.ptgl"  style="width: 4em;text-align: right;" /></span> Punts</td>
                 <td md-cell show-gt-xs hide><span class="md-body-1"><input type="text" name="myDecimal" placeholder="Decimal" ng-model="vm.ptgv"  style="width: 4em;text-align: right;" /></span> Punts</td>
       
            </tr>
            
              <tr md-row  class="pointer-cursor">
                
              <td md-cell><span class="md-body-1">Tècnic a tècnic contrari</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-1"><input type="text" name="myDecimal" placeholder="Decimal" ng-model="vm.pttl"  style="width: 4em;text-align: right;" /></span> Punts</td>
                 <td md-cell show-gt-xs hide><span class="md-body-1"><input type="text" name="myDecimal" placeholder="Decimal" ng-model="vm.pttv"  style="width: 4em;text-align: right;" /></span> Punts</td>
       
            </tr>
              <tr md-row  class="pointer-cursor">
                
              <td md-cell><span class="md-body-1">Tècnic a equip contrari</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-1"><input type="text" name="myDecimal" placeholder="Decimal" ng-model="vm.ptel"  style="width: 4em;text-align: right;" /></span> Punts</td>
                 <td md-cell show-gt-xs hide><span class="md-body-1"><input type="text" name="myDecimal" placeholder="Decimal" ng-model="vm.ptev"  style="width: 4em;text-align: right;"" /></span> Punts</td>
       
            </tr>
              <tr md-row  class="pointer-cursor">
                
              <td md-cell><span class="md-body-1">Tutor de joc a tècnic</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-1"><input type="text" name="myDecimal" placeholder="Decimal" ng-model="vm.pdtl"  style="width: 4em;text-align: right;" /></span> Punts</td>
                 <td md-cell show-gt-xs hide><span class="md-body-1"><input type="text" name="myDecimal" placeholder="Decimal" ng-model="vm.pdtv"  style="width: 4em;text-align: right;" /></span> Punts</td>
       
            </tr>
             <tr md-row  class="pointer-cursor">
                
              <td md-cell><span class="md-body-1">Tutor de joc a públic</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-1"><input type="text" name="myDecimal" placeholder="Decimal" ng-model="vm.pdpl"  style="width: 4em;text-align: right;" /></span> Punts</td>
                 <td md-cell show-gt-xs hide><span class="md-body-1"><input type="text" name="myDecimal" placeholder="Decimal" ng-model="vm.pdpv"  style="width: 4em;text-align: right;" /></span> Punts</td>
       
            </tr>
              <tr md-row  class="pointer-cursor">
                
              <td md-cell><span class="md-body-1">Marcador</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-1"><input type="text" name="myDecimal" placeholder="Decimal" ng-model="vm.marcadorl"  style="width: 4em;text-align: right;" /></span> Punts</td>
                 <td md-cell show-gt-xs hide><span class="md-body-1"><input type="text" name="myDecimal" placeholder="Decimal" ng-model="vm.marcadorv"  style="width: 4em;text-align: right;" /></span> Punts</td>
       
            </tr>
            <tr md-row  class="pointer-cursor">
                
              <td md-cell><span class="md-body-1">VerdPlay</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-1"><input type="text" name="myDecimal" placeholder="Decimal" ng-model="vm.pvpl"  style="width: 4em;text-align: right;"/></span> Punts</td>
                 <td md-cell show-gt-xs hide><span class="md-body-1"><input type="text" name="myDecimal" placeholder="Decimal" ng-model="vm.pvpv"  style="width: 4em;text-align: right;" /></span> Punts</td>
       
            </tr>
             <tr md-row  class="pointer-cursor">
                
              <td md-cell><span class="md-body-1">Targetes verdes</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-1"><input type="text" name="myDecimal" placeholder="Decimal" ng-model="vm.tvl" style="width: 4em;text-align: right;" /></span></td>
                 <td md-cell show-gt-xs hide><span class="md-body-1"><input type="text" name="myDecimal" placeholder="Decimal" ng-model="vm.tvv"  style="width: 4em;text-align: right;" /></span></td>
       
            </tr>
              <tr md-row  class="pointer-cursor">
                
              <td md-cell><span class="md-body-1">Puntuació marcador</span></td>
              <td md-cell show-gt-xs hide style="text-align:left"><span ng-show="true" class="md-body-1">{{vm.pl}}</span> </td>
              <td md-cell show-gt-xs hide style="text-align:left"><span ng-show="true" class="md-body-1">{{vm.pv}}</span> </td>
       
            </tr>
            
               <tr md-row  class="pointer-cursor">
                
              <td md-cell><span class="md-body-1">Ultims partits</span></td>
              
              <td md-cell show-gt-xs hide style="text-align:left"><span class="md-body-1"> <md-button class="md-warn" ng-model="boto1" ng-click="verpartido(vm.partido1)" ng-style="stboto1" style="background-color: white;color:black; min-width: 35px;min-height:  35px;">{{title1}} <md-tooltip md-direction="top">{{tooltip1}}</md-tooltip></md-button> <md-button class="md-warn" ng-model="boto2" ng-click="verpartido(vm.partido2)" ng-style="stboto2" style="background-color: white;color:black; min-width: 35px;min-height:  35px;">{{title2}}<md-tooltip md-direction="top">{{tooltip2}}</md-tooltip></md-button> <md-button class="md-warn" ng-model="boto3" ng-click="verpartido(vm.partido3)" ng-style="stboto3" style="background-color: white;color:black; min-width: 35px;min-height:  35px;">{{title3}}<md-tooltip md-direction="top">{{tooltip3}}</md-tooltip></md-button> <md-button class="md-warn" ng-model="boto4" ng-click="verpartido(vm.partido4)" ng-style="stboto4" style="background-color: white;color:black; min-width: 35px;min-height:  35px;">{{title4}}<md-tooltip md-direction="top">{{tooltip4}}</md-tooltip></md-button> <md-button class="md-warn" ng-model="boto5" ng-click="verpartido(vm.partido5)" ng-style="stboto5"  style="background-color: white;color:black;min-width: 35px;min-height:  35px;">{{title5}}<md-tooltip md-direction="top">{{tooltip5}}</md-tooltip></md-button></span> </td>
              <td md-cell show-gt-xs hide style="text-align:left"><span class="md-body-1"> <md-button class="md-warn" ng-model="boto6" ng-click="verpartido(vm.partido6)" ng-style="stboto6" style="background-color: white;color:black; min-width: 35px;min-height:  35px;">{{title6}} <md-tooltip md-direction="top">{{tooltip6}}</md-tooltip></md-button> <md-button class="md-warn" ng-model="boto7" ng-click="verpartido(vm.partido7)" ng-style="stboto7" style="background-color: white;color:black; min-width: 35px;min-height:  35px;">{{title7}}<md-tooltip md-direction="top">{{tooltip7}}</md-tooltip></md-button> <md-button class="md-warn" ng-model="boto8" ng-click="verpartido(vm.partido8)" ng-style="stboto8" style="background-color: white;color:black; min-width: 35px;min-height:  35px;">{{title8}}<md-tooltip md-direction="top">{{tooltip8}}</md-tooltip></md-button> <md-button class="md-warn" ng-model="boto9" ng-click="verpartido(vm.partido9)" ng-style="stboto9" style="background-color: white;color:black; min-width: 35px;min-height:  35px;">{{title9}}<md-tooltip md-direction="top">{{tooltip9}}</md-tooltip></md-button> <md-button class="md-warn" ng-model="boto10"ng-click="verpartido(vm.partido10)" ng-style="stboto10" style="background-color: white;color:black; min-width: 35px;min-height:  35px;">{{title10}}<md-tooltip md-direction="top">{{tooltip10}}</md-tooltip></md-button></span> </td>
       
            </tr>
            <tr md-row class="pointer-cursor">
                
                  <td md-cell colspan="3" style="width:200px;vertical-align:middle;" >   <md-button flex="35" class="md-primary md-raised" ng-click="truredescalificaciolocal()" ng-hide="vm.isDescalificatLocal">
      Treure descalificació
          </md-button>
             <md-button flex="35" class="md-primary md-raised" ng-click="truredescalificaciovisitant()"  ng-hide="vm.isDescalificatVisitant">
      Treure descalificació
          </md-button></td>
                
            </tr>
        
            </tbody>
          </table>
        </md-table-container>
       

      </md-card-content>
    </md-card>
</div>

<div layout="row" layout="column"       layout-align="center center">
    <md-card flex-gt-md="60" flex="100">
         <div class="md-whiteframe-1dp" flex>
           <md-table-container>
          <table md-table md-row-select="true" ng-if="vm.isAdmin()">
          
            <tbody md-body>
            <tr md-row  class="pointer-cursor" style="vertical-align:middle">
                
              <td md-cell style="width:450px;vertical-align:middle">
        
               
             <md-select placeholder="Selecció sanció" ng-model="sancion" ng-change="vm.changesancio(sancion.idsancion)"  style=";color: black">
      <md-option ng-value="sancio" ng-repeat="sancio in sancions"  ng-selected="{{sancio.idsancion}} == {{sancionaplicada}}">{{sancio.articulo}} - {{sancio.sancion}}</md-option>
    </md-select></td>
                    
       
        <td md-cell style="width:100px;vertical-align:middle;">   <span class="md-body-1">Equip local</span>  </td>
          <td md-cell style="width:100px;vertical-align:middle;">     <md-select placeholder="Seleccionar" ng-model="vm.sancionLocal"   style=";color: black">
      <md-option ng-value="1"   ng-selected="{{!sancionLocalBool}}">No infractor</md-option>
      <md-option ng-value="2" ng-selected="{{sancionLocalBool}}">Infractor</md-option>
    </md-select></td>
           
          <td md-cell style="width:100px;vertical-align:middle;">    <span class="md-body-1">Equip visitant</span></td>
         <td md-cell style="width:100px;vertical-align:middle;">    <md-select placeholder="Seleccionar" ng-model="vm.sancionVisit"   style=";color: black">
      <md-option ng-value="1"  ng-selected="{{!sancionVisitBool}}">No infractor</md-option>
      <md-option ng-value="2" ng-selected="{{sancionVisitBool}}">Infractor</md-option>
    </md-select></td>
                    
          
          
              <td md-cell style="width:100px;vertical-align:middle;">   <md-button flex="35" class="md-primary md-raised" ng-click="aplicarsancio()">
      Aplicar
          </md-button></td>
          <td md-cell style="width:150px;vertical-align:middle;" ng-show="vm.treure">   <md-button flex="15" class="md-primary md-raised" ng-click="treuresancio()">
      Treure
          </md-button></td>
          </tr>
           </tbody>
          </table>
      </div>
    </md-card>
</div>



<div layout="row" layout="column"       layout-align="center center">
    <md-card flex-gt-md="60" flex="100">
         <div class="md-whiteframe-1dp" flex>
          
          <md-card-header layout-padding style="background-color:#FFFFFF;color:white">
         
               
            
                  <md-button flex="15" class="md-primary md-raised" ng-click="votar2()">
      Recàlcul resultat
    </md-button>
               <md-button flex="20" class="md-primary md-raised" ng-click="mesures()">
      Mesures correctores
    </md-button>
                    
          </md-card-header>
          
          
      </div>
    </md-card>
</div>

<div layout="row" layout="column"       layout-align="center center">
    <md-card flex-gt-md="80" flex="100">
      
      <div class="md-whiteframe-1dp" flex>
          
          <md-card-header layout-padding style="background-color:#D2E0E6;color: black;align-content: flex-start">
         
                <md-card-header-text>
              <md-select placeholder="Perfil" ng-model="user" ng-change="vm.change(user.id)" md-on-open="loadUsers()" style=";color: black">
      <md-option ng-value="user" ng-repeat="user in users">{{user.name}}</md-option>
    </md-select> </md-card-header-text>
              
               <md-card-header-text ng-if="vm.espotVotar">
                  <md-button style=" max-width: 10px;" class="md-primary md-raised" ng-click="votar(vm.idpartit)">
      Vota
    </md-button>
                   
                    </md-card-header-text>
         
         
          
            <md-card-header-text >
                 
                    <md-button style=" max-width: 10px;" class="md-primary md-raised" ng-show="vm.poteliminar" ng-if="vm.isAdmin()" ng-click="eliminarvotacio(user.name)">
      Eliminar
    </md-button>
                    </md-card-header-text>
         
          </md-card-header>
          
      </div>
            
         <md-card-content>
       
        <md-table-container >
          <table md-table md-row-select="true" ng-model="vm.selected" id="table2">
            <thead md-head md-order="vm.queryOrder" md-on-reorder="vm.reorder">
            <tr md-row>
              <th md-column>Data</th>
              <th md-column>Perfil</th>
              <th md-column >Respecte a</th>
              <th md-column >Item</th>
              <th md-column >Valor</th>
              <th md-column>Nom</th>
              <th md-column >email</th>
              <th md-column >Observacions</th>
              <th md-column >{{ 'ACTIONS' | translate}}</th>  </tr>
            </thead>
            <tbody md-body>
            <tr md-row md-select="vm.select" ng-repeat="valoracio in valoracions">
              <td md-cell>{{valoracio.fecha}}</td>
              <td md-cell>{{valoracio.perfil}}</td>
              <td md-cell>{{valoracio.respecto}}</td>
              <td md-cell>{{valoracio.item_Valorado}}</td>
              <td md-cell>{{valoracio.vporc}}</td>
              <td md-cell>{{valoracio.nomusuari}}</td>
              <td md-cell>{{valoracio.email}}</td>
              <td md-cell>{{valoracio.observacions}}</td>
            
             
            </tr>
            </tbody>
          </table>
        </md-table-container>
    
      </md-card-content>
   
    </md-card>
    
</div>