<div layout="row" layout-align="space-around center">
  <md-card flex-gt-md="70" flex="100">
    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline">{{'CANCELLATIONS.NEW' | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>
      <form name="cancellationForm" novalidate>
        <div layout-align="center stretch" layout="column">
          <div layout="row" flex>
            <div flex="50" layout="column" layout-align="top none" layout-wrap
                 ng-repeat="team in [vm.localTeam, vm.visitorTeam]">
              <h1 class="md-title">{{team.columnName | translate}}</h1>
              <div layout-padding>
                <div flex layout="row">
                  <neo-input name="teamName" label="TEAM_NAME" neo-icon="title" ng-model="team.name"
                             is-disabled="true" flex no-margin="true"></neo-input>
                </div>
                <div flex layout="column" layout-gt-sm="row">
                  <neo-input name="coachName" label="COACH_NAME" neo-icon="person" ng-model="team.coachName"
                             is-disabled="true" flex="45" no-margin="true"></neo-input>
                  <neo-input name="coachPhone" label="COACH_PHONE" neo-icon="phone" ng-model="team.coachPhone"
                             is-disabled="true" flex="45" no-margin="true"></neo-input>
                </div>
                <div flex layout="column" layout-gt-sm="row">
                  <neo-input name="coachName" label="DELEGATE_NAME" neo-icon="person" ng-model="team.delegateName"
                             is-disabled="true" flex="45" no-margin="true"></neo-input>
                  <neo-input name="coachPhone" label="DELEGATE_PHONE" neo-icon="phone" ng-model="team.delegatePhone"
                             is-disabled="true" flex="45" no-margin="true"></neo-input>
                </div>
              </div>
            </div>
          </div>

          <md-divider></md-divider>

          <h1 class="md-title">{{'CANCELLATIONS.DETAILS' | translate}}</h1>
          <div layout="row" flex layout-align="space-around">
            <!-- Cancellation Type -->
            <div flex="45" layout="column">
              <md-input-container flex>
                <md-select ng-model="vm.cancellation.typeId"
                           placeholder="{{'CANCELLATIONS.CANCELLATION_TYPE' | translate}}"
                           ng-required="true" md-no-asterisk="true">
                  <md-option ng-repeat="cancellation in vm.cancellationTypes" ng-value="cancellation.id">
                    {{'CANCELLATIONS.TYPES.' + cancellation.name | translate}}
                  </md-option>
                </md-select>
              </md-input-container>
            </div>
            <!-- Details -->
            <div flex="45" layout="column">
              <md-input-container class="md-block">
                <label>{{'CANCELLATIONS.MORE_INFORMATION' | translate}}</label>
                <md-icon class="material-icons md-dark">info_outline</md-icon>
                <textarea ng-model="vm.cancellation.details" md-maxlength="500" rows="5" md-select-on-focus
                          title="more_info"></textarea>
              </md-input-container>
            </div>
          </div>
        </div>
        <div flex>
          <md-button class="md-fab md-accent md-fab-bottom-right" ng-disabled="cancellationForm.$invalid"
                     ng-click="vm.saveCancellation()">
            <md-icon class="material-icons md-light">save</md-icon>
            <md-tooltip md-delay="50" md-direction="left">{{'SAVE' | translate}}</md-tooltip>
          </md-button>
        </div>
      </form>
    </md-card-content>
  </md-card>
</div>
