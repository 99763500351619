<div layout="row" layout-align="space-around center">
  <div layout="row" layout-align="center center">
    <md-input-container>
      <label>{{'SEASON' | translate}}</label>
      <md-select ng-model="vm.selectedSeasonId" ng-change="vm.changeSeason()">
        <md-option ng-repeat="season in vm.seasons" ng-value="season.id">
          {{season.name}}
        </md-option>
      </md-select>
    </md-input-container>

    <md-input-container>
      <label>{{'PHASES.PHASE' | translate}}</label>
      <md-select ng-model="vm.selectedPhase" ng-change="vm.changeFilter()">
        <md-option ng-value="undefined"><em>{{'PHASES.ALL' | translate}}</em></md-option>
        <md-option ng-repeat="phase in vm.phases" ng-value="phase">
          {{phase}}
        </md-option>
      </md-select>
    </md-input-container>

    <md-input-container>
      <label>{{'PROGRAM_TYPE' | translate}}</label>
      <md-select ng-model="vm.selectedProgramme" ng-change="vm.changeFilter()">
        <md-option ng-value="undefined"><em>{{'ALL_PROGRAMMES' | translate}}</em></md-option>
        <md-option ng-repeat="programme in vm.programmes" ng-value="programme">
          {{programme | translate}}
        </md-option>
      </md-select>
    </md-input-container>
  </div>
</div>

<div layout="column">
  <h1 class="md-title">{{vm.myChartObject.options.title | translate}}</h1>
  <div google-chart chart="vm.myChartObject" style="width:100%;" agc-on-ready="vm.readyHandler(chartWrapper)"></div>
  <div layout="row" layout-align="end center">
    <neo-download-csv-table html-table="vm.htmlTable"
                            filename="STATISTICS.TEAMS_EXCEL_FILENAME"></neo-download-csv-table>
  </div>
</div>
