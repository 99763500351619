<div layout="column" ui-view>
  <div layout="row" layout-align="space-around center" layout-padding>
    <form ng-submit="vm.applyFilters()" name="listFilters" layout="row" flex-gt-md="70" flex="100"
          layout-align="space-around center">
      <md-autocomplete flex="40" flex-gt-md="50" md-selected-item="vm.selectedTownHall"
                       md-search-text="vm.searchText"
                       md-items="townhall in vm.townhalls | filterAccents: vm.searchText"
                       md-item-text="townhall.name"
                       md-require-match
                       md-min-length="0"
                       ng-required="true"
                       ng-disabled="vm.townhalls.length === 1"
                       md-clear-button="true"
                       md-floating-label="{{'SELECT_TOWNHALL_TO_FILTER' | translate}}" style="padding-top: 15px">
        <md-item-template>
          <span md-highlight-text="vm.searchText" md-highlight-flags="^i">{{townhall.name}}</span>
        </md-item-template>
        <md-not-found>
          {{'RESULT_NOT_FOUND' | translate}}
        </md-not-found>
      </md-autocomplete>
      <md-datepicker md-current-view="month" ng-required="true" ng-model="vm.initDate"
                     md-placeholder="{{'INI_DATE' | translate}}" md-max-date="vm.endDate"></md-datepicker>
      <md-datepicker md-current-view="month" ng-required="true" ng-model="vm.endDate"
                     md-placeholder="{{'END_DATE' | translate}}" md-min-date="vm.initDate" md-max-date="vm.today"
                     ng-disabled="vm.initDate === null"></md-datepicker>
      <md-button type="submit" ng-disabled="listFilters.$invalid" class="md-raised md-primary">{{'FILTER' |
        translate}}
      </md-button>
    </form>
  </div>
  <div layout="row" layout-align="space-around center" ng-if="vm.isFiltered">
    <md-card flex-gt-md="80" flex="100">

      <div class="md-whiteframe-1dp" flex>
        <md-card-header layout-padding>
          <md-card-header-text>
            <span class="md-headline">{{'CANCELLATIONS.MATCHES_TO_CANCEL' | translate}}</span>
          </md-card-header-text>
        </md-card-header>
      </div>
      <md-card-content>
        <span class="md-body-2" ng-hide="vm.showList">{{'CANCELLATIONS.NOT_FOUND' | translate}}</span>
        <md-table-container ng-if="vm.showList">
          <table md-table md-row-select="false" ng-model="vm.selected" md-progress="vm.promiseMatches">
            <thead md-head md-order="vm.queryOrder" md-on-reorder="vm.reorder">
            <tr md-row>
              <th md-column md-order-by="championship">{{ 'CHAMPIONSHIPS.CHAMPIONSHIP' | translate}}</th>
              <th md-column md-order-by="localTeam">{{ 'CHAMPIONSHIPS.LOCAL_TEAM' | translate}}</th>
              <th md-column md-order-by="visitorTeam">{{ 'CHAMPIONSHIPS.VISITOR_TEAM' | translate}}</th>
              <th md-column md-order-by="date">{{ 'CHAMPIONSHIPS.MATCH_DATE' | translate}}</th>
              <th md-column md-order-by="time">{{ 'CHAMPIONSHIPS.MATCH_TIME' | translate}}</th>
              <th md-column md-order-by="facility">{{ 'FACILITIES.FACILITY' | translate}}</th>
              <th md-column>{{ 'CANCELLATIONS.CANCEL' | translate}}</th>
            </tr>
            </thead>
            <tbody md-body>
            <tr md-row ng-repeat="match in vm.matches">
              <td md-cell>{{match.championship}}</td>
              <td md-cell>{{match.localTeam.name}}</td>
              <td md-cell>{{match.visitorTeam.name}}</td>
              <td md-cell>{{match.date}}</td>
              <td md-cell>{{match.time || '--:--'}}</td>
              <td md-cell>{{match.facility.name}}</td>
              <td md-cell>
                <md-button class="md-icon-button" aria-label="edit" ng-if="match.canCancel"
                           ng-click="vm.cancelMatch(match)">
                  <md-tooltip md-direction="bottom" md-delay="50">{{'CANCELLATIONS.CANCEL' | translate}}</md-tooltip>
                  <md-icon>report</md-icon>
                </md-button>
              </td>
            </tr>
            </tbody>
          </table>
        </md-table-container>
        <md-table-pagination md-limit="vm.matchesPerPage" md-page="vm.currentPage" md-total="{{vm.maxMatches}}"
                             md-label="{{vm.paginationLabel}}" md-page-select="true"
                             md-on-paginate="vm.getNewPage" ng-if="vm.showList"></md-table-pagination>
      </md-card-content>
    </md-card>
  </div>
</div>
