<div layout="column" ui-view>
  <div layout="row" layout-align="space-around center" layout-padding>
    <form ng-submit="vm.searchLicenses()" name="searchLicenses" layout="row" flex-gt-md="70" flex="100"
          layout-align="space-around center">
      <neo-input label="NAME"  ng-model="vm.searchName"></neo-input>
      <neo-input label="SURNAME"  ng-model="vm.searchSurname"></neo-input>
      <neo-input label="SECOND_SURNAME" ng-model="vm.searchSecondSurname"></neo-input>

      <md-button type="submit"  class="md-raised md-primary">{{'SEARCH' |
        translate}}
      </md-button>
    </form>
  </div>
  <div layout="row" layout-align="space-around center" ng-if="vm.hasSearched">
    <md-card flex="100">

      <div class="md-whiteframe-1dp" flex>
        <md-card-header layout-padding>
          <md-card-header-text>
            <span class="md-headline">{{'LICENSES.SEARCH_RESULTS' | translate}}</span>
          </md-card-header-text>
        </md-card-header>
      </div>
      <md-card-content>
        <span class="md-body-2" ng-if="vm.licences.length === 0">{{'NOT_FOUND_LICENSES' | translate}}</span>
        <md-table-container ng-if="vm.licences.length > 0">
          <table md-table  md-progress="vm.promiseMatches">
            <thead md-head >
            <tr md-row>
              <th md-column >{{ 'NAME' | translate}}</th>
              <th md-column >{{ 'SURNAME' | translate}}</th>
              <th md-column >{{ 'SECOND_SURNAME' | translate}}</th>
              <th md-column >{{ 'LICENSE_TYPE' | translate}}</th>
              <th md-column >{{ 'SPORT' | translate}}</th>
              <th md-column >{{ 'PROGRAM_TYPE' | translate}}</th>
              <th md-column >{{ 'CATEGORY' | translate}}</th>
              <th md-column >{{ 'ORGANISATION_NAME' | translate}}</th>
              <th md-column >{{ 'TEAM_NAME' | translate}}</th>
            </tr>
            </thead>
            <tbody md-body>
            <tr md-row ng-repeat="licence in vm.licences">
              <td md-cell>{{licence.name}}</td>
              <td md-cell>{{licence.surname1}}</td>
              <td md-cell>{{licence.surname2}}</td>
              <td md-cell>{{licence.licenseType}}</td>
              <td md-cell>{{licence.sport}}</td>
              <td md-cell>{{licence.program}}</td>
              <td md-cell>{{licence.category}}</td>
              <td md-cell>{{licence.organisationName}}</td>
              <td md-cell>{{licence.teamName}}</td>
            </tr>
            </tbody>
          </table>
        </md-table-container>
        <md-table-pagination md-limit="vm.licencesPerPage" md-page="vm.currentPage" md-total="{{vm.maxLicences}}"
                             md-label="{{vm.paginationLabel}}" md-page-select="true"
                             md-on-paginate="vm.getNewPage" ng-if="vm.licences.length > 0"></md-table-pagination>
      </md-card-content>
    </md-card>
  </div>
</div>

