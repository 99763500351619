<div layout="row" layout-align="center center" ui-view style="padding-top: 2%">
  <md-card flex-gt-md="50" flex-xs="100" flex="70">
    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline">{{'PHASES.PHASE_DATA' | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>
      <form name="phaseForm" style="min-width: 75%" ng-submit="vm.updatePhase()">
        <div layout="column" layout-align="center none" style="padding: 0 10% 0 10%">
          <md-input-container>
            <label>{{'PHASES.PHASE_NAME' | translate}}</label>
            <md-icon md-svg-src="images/ic_person_black_48px.svg"></md-icon>
            <input name="name" type="text" required ng-model="vm.phase.name" ng-disabled="!vm.edit"/>
            <div ng-messages="phaseForm.name.$error" role="alert">
              <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
            </div>
          </md-input-container>
        </div>
        <div layout="column" layout-gt-md="row" layout-align="space-around center" style="padding: 0 0 2% 0">
          <div layout="row" layout-align="start center" layout-padding>

            <md-input-container>
              <label>{{'INI_DATE' | translate}}</label>
              <md-datepicker name="iniDate" ng-model="vm.phase.iniDate" md-current-view="month"
                             ng-required="true" ng-disabled="!vm.edit"></md-datepicker>
              <div ng-messages="phaseForm.iniDate.$error" role="alert">
                <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
              </div>
            </md-input-container>
          </div>
          <div layout="row" layout-align="start center" layout-padding>

            <md-input-container>
              <label>{{'END_DATE' | translate}}</label>
              <md-datepicker name="endDate" ng-model="vm.phase.endDate" md-current-view="month"
                             ng-required="true" ng-disabled="!vm.edit"></md-datepicker>
              <div ng-messages="phaseForm.endDate.$error" role="alert">
                <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
              </div>
            </md-input-container>
          </div>
        </div>
        <div flex>
          <md-button type="submit" class="md-fab md-accent md-fab-bottom-right" ng-if="vm.edit"
                     ng-disabled="phaseForm.$invalid">
            <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
          </md-button>
        </div>
      </form>
    </md-card-content>
  </md-card>
  <neo-action-button buttons="vm.buttons" ng-if="!vm.edit"></neo-action-button>
</div>
