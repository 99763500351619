<div layout="column" layout-padding>
  <h3> {{alertText}}</h3>
  <md-divider></md-divider>
  <span> {{textContent}}</span>
</div>
<div layout="row" layout-align="end end" flex>
  <md-input-container>
    <md-button class="md-raised" ng-click="cancel()">{{ cancelText}}</md-button>
  </md-input-container>
  <md-input-container>
    <md-button class="md-warn md-raised" ng-click="delete()">{{deleteText}}</md-button>
  </md-input-container>
</div>

