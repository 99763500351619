<div layout="row" layout-align="space-around center">

  <md-card flex-gt-md="70" flex="100">
    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline">{{'NEW_TOWNHALL_UPLOAD' | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>
      <div layout-align="center center" layout="column" flex>
        <form name="ajuntamentForm" ng-submit="vm.addAjuntament()" flex>
          <div layout="column" flex>
            <h1 class="md-title">{{'NEW_TOWN_HALL_DATA' | translate}}</h1>
            <md-divider></md-divider>
            <div layout-xs="column" flex layout-gt-xs="row" layout-align="space-around center">
              <div flex-gt-xs="40" flex layout="column">
                <md-input-container>
                  <label>{{'NAME' | translate}}</label>
                  <md-icon md-svg-src="images/ic_title_black_48px.svg"></md-icon>
                  <input title="name" type="text" required ng-model="vm.ajuntament.name"/>
                  <div ng-messages="ajuntamentForm.name.$error" role="alert">
                    <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                  </div>
                </md-input-container>
                <md-input-container class="md-block">
                  <label>{{'CONTACT_EMAIL' | translate}}</label>
                  <md-icon md-svg-src="images/ic_email_black_48px.svg"></md-icon>
                  <input title="email" type="email" required ng-pattern="/^.+@.+\..+$/"
                         ng-model="vm.ajuntament.email"/>
                  <div ng-messages="ajuntamentForm.email.$error" role="alert">
                    <div ng-message-exp="['required', 'pattern']">{{'EMAIL_INVALID_FORMAT' | translate}}</div>
                  </div>
                </md-input-container>
                <md-input-container class="md-block">
                  <md-icon md-svg-src="images/ic_web_black_48px.svg"></md-icon>
                  <label>{{'WEB_PAGE' | translate}}</label>
                  <input title="web" type="text" ng-model="vm.ajuntament.web"/>
                </md-input-container>
              </div>
              <div flex-gt-xs="45" flex layout="column">
                <md-input-container class="md-block">
                  <label>{{'ADDRESS' | translate}}</label>
                  <md-icon md-svg-src="images/ic_location_black_48px.svg"></md-icon>
                  <input title="address" type="text" ng-model="vm.ajuntament.address"/>
                </md-input-container>
                <div flex layout="row">
                  <md-input-container class="md-block" flex="75">
                    <label>{{'TOWN' | translate}}</label>
                    <md-icon md-svg-src="images/ic_location_city_black_48px.svg"></md-icon>
                    <md-select ng-model="vm.ajuntament.idTown" ng-required="true">
                      <md-option ng-value="town.id" ng-repeat="town in vm.towns">{{ town.name }}
                      </md-option>
                    </md-select>
                  </md-input-container>
                  <md-input-container class="md-block" flex="25">
                    <label>{{'POSTAL_CODE' | translate}}</label>
                    <input title="zipCode" type="text" name="zipCode" ng-model="vm.ajuntament.zipCode"
                           ng-pattern="/^[0-9]{5}$/" md-maxlength="5">
                    <div ng-messages="ajuntamentForm.zipCode.$error" role="alert">
                      <div ng-message="md-maxlength">{{'MAXIMUM_CHARACTERS_POSTAL_CODE' | translate}}</div>
                    </div>
                  </md-input-container>
                </div>
                <div flex layout="row">
                  <md-input-container class="md-block">
                    <label>{{'PHONE' | translate}}</label>
                    <md-icon md-svg-src="images/ic_phone_black_48px.svg"></md-icon>
                    <input title="phoneNumber" type="tel"
                           ng-pattern="/^[+]{0,1}[0-9]+$/"
                           ng-model="vm.ajuntament.phoneNumber"/>
                    <div ng-messages="ajuntamentForm.phoneNumber.$error" role="alert">
                      <div ng-message="pattern">{{'INVALID_PHONE_NUMBER' | translate}}</div>
                    </div>
                  </md-input-container>
                  <md-input-container class="md-block">
                    <label>{{'FAX' | translate}}</label>
                    <input title="fax" type="tel"
                           ng-pattern="/^[+]{0,1}[0-9]+$/"
                           ng-model="vm.ajuntament.fax"/>
                    <div ng-messages="ajuntamentForm.fax.$error" role="alert">
                      <div ng-message="pattern">{{'INVALID_FAX_NUMBER' | translate}}</div>
                    </div>
                  </md-input-container>
                </div>
                <div flex layout="row">
                  <md-input-container class="md-block">
                    <label>Facebook</label>
                    <md-icon md-svg-src="images/if_facebook_circle_color_107175.svg" aria-label="Facebook"></md-icon>
                    <input title="facbook" type="text"
                           ng-model="vm.ajuntament.facebook"/>
                  </md-input-container>
                  <md-input-container class="md-block">
                    <label>Twitter</label>
                    <md-icon md-svg-src="images/if_twitter_circle_color_107170.svg" aria-label="Twitter"></md-icon>
                    <input title="twitter" type="text"
                           ng-model="vm.ajuntament.twitter"/>
                  </md-input-container>
                  <md-input-container class="md-block">
                    <label>Instagram</label>
                    <md-icon md-svg-src="images/if_instagram_2362135.svg" aria-label="Instagram"></md-icon>
                    <input title="instagram" type="text"
                           ng-model="vm.ajuntament.instagram"/>
                  </md-input-container>
                </div>
              </div>
            </div>
          </div>

          <div layout="column">
            <div>
              <h1 class="md-title">{{'USER_DATA' | translate}}</h1>
              <md-divider></md-divider>
              <div layout="row" layout-align="space-around left">
                <div flex="40" layout="column" layout-padding>
                  <md-input-container class="md-block">
                    <label>{{'ACCESS_EMAIL' | translate}}</label>
                    <md-icon md-svg-src="images/ic_email_black_48px.svg"></md-icon>
                    <input title="email" type="email" required ng-pattern="/^.+@.+\..+$/"
                           ng-model="vm.ajuntament.username"/>
                    <div ng-messages="ajuntamentForm.username.$error" role="alert">
                      <div ng-message-exp="['required', 'pattern']">
                        {{'EMAIL_INVALID_FORMAT' | translate}}
                      </div>
                    </div>
                  </md-input-container>
                </div>
                <div flex-gt-xs="45" flex layout="column"></div>
              </div>
            </div>
          </div>

          <div flex>
            <md-button class="md-fab md-accent md-fab-bottom-right" ng-disabled="ajuntamentForm.$invalid"
                       type="submit">
              <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
              <md-tooltip md-delay="50" md-direction="left">{{'SAVE' | translate}}</md-tooltip>
            </md-button>
          </div>

        </form>
      </div>
    </md-card-content>
  </md-card>
</div>
