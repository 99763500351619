<div layout="row" layout-align="space-around center" style="padding-top: 1%;">

  <md-card flex-gt-md="70" flex="100">
    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline" ng-if="!vm.edit">{{'REFEREES.REFEREE_PERSON_DATA' | translate}}</span>
          <span class="md-headline" ng-if="vm.edit">{{'REFEREES.MODIFY_REFEREE_PERSON_DATA' | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>
      <div layout-align="center center" layout="row" flex>

        <form name="refereeForm" ng-submit="vm.updatePerson()" flex>
          <div layout="column" layout-padding flex>
            <div layout-gt-sm="row" layout-align="space-around center">
              <div layout-gt-sm="row" flex="45">

                <neo-input name="name" label="NAME" icon="images/ic_person_black_48px.svg" is-required="true"
                           ng-model="vm.person.name" flex="45" layout-gt-sm="column" is-disabled="!vm.edit"></neo-input>

                <neo-input name="surname" label="SURNAME" icon="images/ic_person_black_48px.svg" is-required="true"
                           ng-model="vm.person.surname" flex="50"
                           layout-gt-sm="column" is-disabled="!vm.edit"></neo-input>
              </div>
              <div layout-gt-sm="row" flex="45">

                <neo-input name="secondSurname" label="SECOND_SURNAME" ng-model="vm.person.secondSurname" flex="50"
                           layout-gt-sm="column" is-disabled="!vm.edit"></neo-input>

                <md-input-container class="md-block" flex="50">
                  <label>{{'BIRTH_DATE' | translate}}</label>
                  <md-datepicker name="birthday" ng-model="vm.person.bornDate" md-current-view="year"
                                 ng-required="true" ng-disabled="!vm.edit" flex="100"
                                 md-max-date="vm.today"></md-datepicker>
                  <div ng-messages="refereeForm.birthday.$error" role="alert">
                    <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                    <div ng-message="valid">{{'DATE_INVALID_FORMAT' | translate}}</div>
                  </div>
                </md-input-container>
              </div>
            </div>

            <div layout-gt-sm="row" layout-align="space-around center">
              <div layout-gt-sm="row" flex="50">


                <md-input-container class="md-block" flex="50">
                  <label>{{'GENDER' | translate}}</label>
                  <md-icon md-svg-src="images/gender-male-female.svg"></md-icon>
                  <md-select name="gender" ng-model="vm.person.genderId" ng-required="true" ng-disabled="!vm.edit">
                    <md-option ng-repeat="gender in vm.personGenders" value="{{gender.id}}">{{gender.name |
                      translate}}
                    </md-option>
                  </md-select>
                  <div ng-messages="refereeForm.gender.$error" role="alert">
                    <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                  </div>
                </md-input-container>

                <neo-input name="nif" label="NIF/NIE" icon="images/ic_person_black_48px.svg" ng-model="vm.person.nif"
                           flex="45" layout-gt-sm="column" is-disabled="!vm.edit" md-maxlength="50"></neo-input>
              </div>

              <neo-input name="email" label="CONTACT_EMAIL" icon="images/ic_email_black_48px.svg"
                         ng-model="vm.person.email" error="EMAIL_INVALID_FORMAT"
                         pattern="^.+@.+\..+$" flex="45" layout-gt-sm="column" is-disabled="!vm.edit"></neo-input>
            </div>

            <div layout-gt-sm="row" layout-align="space-around center">

              <neo-input name="iban" label="IBAN" icon="images/ic_person_black_48px.svg" ng-model="vm.license.iban"
                         is-disabled="!vm.edit"
                         pattern="^ES[0-9]{2}[0-9]{4}[0-9]{4}[0-9]{1}[0-9]{1}[0-9]{10}$" error="IBAN_INVALID_FORMAT"
                         flex="45" layout-gt-sm="column"></neo-input>

              <neo-input name="hic" label="HEALTH_INSURANCE_CARD_NUMBER" icon="images/ic_healing_black_48px.svg"
                         ng-model="vm.person.healthInsuranceCardNumber" flex="45" layout-gt-sm="column"
                         is-disabled="!vm.edit"></neo-input>
            </div>

            <div layout-gt-sm="row" layout-align="space-around center">
              <neo-input name="address" label="ADDRESS" icon="images/ic_location_black_48px.svg"
                         ng-model="vm.person.address" flex="45" layout-gt-sm="column"
                         is-disabled="!vm.edit"></neo-input>
              <div layout-gt-sm="row" flex="45">
                <neo-input name="town" label="TOWN" icon="images/ic_location_city_black_48px.svg"
                           ng-model="vm.person.town" flex="70" layout-gt-sm="column"
                           is-disabled="!vm.edit"></neo-input>
                <neo-input name="zipCode" label="POSTAL_CODE" ng-model="vm.person.zipCode"
                           error="INVALID_POSTAL_CODE" pattern="^[0-9]{5}$"
                           flex="30" layout-gt-sm="column" is-disabled="!vm.edit"></neo-input>

              </div>
            </div>

            <div layout="row" layout-align="end center">
              <md-input-container flex="30">
                <label>{{'REFEREES.CODE' | translate}}</label>
                <md-icon>assignment_ind</md-icon>
                <input name="refereeCode" disabled ng-model="vm.license.code" name="refereeCode">
              </md-input-container>
              <!--              <neo-input name="refereeCode" label="REFEREES.CODE" ng-model="vm.license.code"
                                       flex="40" layout-gt-sm="column" is-disabled="true"></neo-input>-->
            </div>

            <h1 class="md-title">{{'ASSOCIATIONS' | translate}}</h1>
            <md-divider layout-padding></md-divider>
            <div layout-align="center-center">

            </div>
            <div layout="row" layout-align="start center">
              <div ng-if="vm.initialState.belongsToTownHall">
                <span>&nbsp;{{'REFEREES.BELONGS_TO_TONWHALL' | translate}}<b> {{vm.initialState.belongingTownHall.name}}</b></span>
              </div>
              <div ng-if="vm.initialState.belongsToEntity">
                <span>&nbsp;{{'REFEREES.BELONGS_TO_ENTITY' | translate}} <b> {{vm.initialState.belongingEntity.name}}</b></span>
              </div>
              <div ng-if="vm.initialState.belongsToCouncil">
                <span>{{'REFEREES.BELONGS_TO_COUNCIL' | translate}} <b> {{'COUNCIL' | translate}}</b></span>
              </div>
            </div>

            <div layout="row" layout-align="center" flex ng-if="vm.edit">
              <div layout="row" layout-align="space-around center" flex="50" ng-if="vm.isAdmin()">
                <md-checkbox ng-model="vm.belongsToCouncil" aria-label="council"
                             ng-change="vm.setRefereeToCouncil()">
                  {{'COUNCIL' | translate}}
                </md-checkbox>
              </div>

              <div layout="row" layout-align="space-around center" flex="50">
                <md-checkbox ng-model="vm.belongsToTownHall" aria-label="townHall"
                             ng-change="vm.setRefereeToTownhall()">
                  {{'TOWN_HALL' | translate}}
                </md-checkbox>
                <md-autocomplete layout-padding ng-disabled="!vm.isTowhallSelectable"
                                 md-selected-item="vm.selectedTownhall"
                                 md-search-text="townHallSearch" md-clear-button="true"
                                 md-items="townHall in vm.townHalls | filterAccents: townHallSearch"
                                 md-item-text="townHall.name" md-min-length="0" md-require-match
                                 ng-required="vm.isTowhallSelectable"
                                 md-floating-label="{{'TOWN_HALL' | translate}}">
                  <md-item-template>
                                <span md-highlight-text="vm.townHallSearch"
                                      md-highlight-flags="^i">{{townHall.name}}</span>
                  </md-item-template>
                  <md-not-found>
                    {{'RESULT_NOT_FOUND' | translate}}
                  </md-not-found>
                </md-autocomplete>
              </div>

              <div layout="row" layout-align="space-around center" flex="50" ng-if="!vm.isAdmin()">
                <md-checkbox ng-model="vm.belongsToEntity" aria-label="entity"
                             ng-change="vm.setRefereeToEntity()">
                  {{'ENTITY' | translate}}
                </md-checkbox>
                <md-autocomplete ng-disabled="!vm.isEntitySelectable" md-selected-item="vm.selectedEntity"
                                 md-search-text="entitySearch" md-clear-button="true"
                                 md-items="entity in vm.entities | filterAccents: entitySearch"
                                 md-item-text="entity.name" md-min-length="0" md-require-match
                                 ng-required="vm.isEntitySelectable"
                                 md-floating-label="{{'ENTITY' | translate}}">
                  <md-item-template>
                    <span md-highlight-text="vm.entitySearch" md-highlight-flags="^i">{{entity.name}}</span>
                  </md-item-template>
                  <md-not-found>
                    Not Found
                  </md-not-found>
                </md-autocomplete>
              </div>
            </div>

          </div>
          <div flex>
            <md-button class="md-fab md-accent md-fab-bottom-right"
                       ng-disabled="refereeForm.$invalid || refereeForm.$pristine" type="submit"
                       ng-click="vm.updateLicense()">
              <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
              <md-tooltip md-delay="50" md-direction="left">{{'SAVE' | translate}}</md-tooltip>
            </md-button>
          </div>
          <div flex>
            <md-button class="md-fab md-warn md-fab-bottom-right"
                       ng-if="vm.edit" ng-click="vm.disableEdit()" style="margin-bottom: 72px;">
              <md-icon md-svg-src="images/ic_cancel_white_48px.svg" class="s24" aria-label="cancel"></md-icon>
              <md-tooltip md-delay="50" md-direction="left">{{'CANCEL' | translate}}</md-tooltip>
            </md-button>
          </div>
        </form>
      </div>
    </md-card-content>
  </md-card>
</div>
<div>
  <neo-action-button buttons="vm.buttons" ng-if="vm.buttons.actions.length > 0"></neo-action-button>
</div>
