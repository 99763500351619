<div layout="column" layout-gt-sm="row">
  <div style="min-width: 75%" flex>
    <div layout-align="center center" layout="row" layout-padding>
      <div class="md-whiteframe-z2" flex-gt-md="70" flex="100">
        <form ng-cloak>
          <div layout="row" flex layout-align="space-around center">
            <div layout="column" flex layout-padding>
              <div class="md-toolbar-tools">
                <h1>{{'PRINT_CARDS.TITLE' | translate}}</h1>
                <span flex></span>
              </div>

              <div flex ng-repeat="(index, team) in vm.licensesByTeam">
                <md-divider></md-divider>
                <md-checkbox ng-model="team.selected" ng-change="vm.selectAllAssociatedLicenses(index)" layout-padding
                             aria-label="team">
                  <h3>{{team.name}}</h3>
                </md-checkbox>
                <md-grid-list class="indented-grid" md-cols-xs="1" md-cols-sm="2" md-cols-md="3" md-cols-gt-md="4"
                              md-row-height="4:1">
                  <md-grid-tile class="content-tile-grid" ng-repeat="license in team.licenses">
                    <md-checkbox ng-model="license.selected" aria-label="license">
                      <span>{{license.name}}</span><!--<br>-->
                      <span>{{license.surname}}</span>
                    </md-checkbox>
                  </md-grid-tile>
                </md-grid-list>
              </div>

              <md-divider ng-if="vm.licensesByTeam.length === 0"></md-divider>
              <span class="md-body-2" ng-if="vm.licensesByTeam.length === 0">{{'NOT_FOUND_LICENSES' | translate}}</span>
            </div>
          </div>
        </form>
        <div flex>
          <md-button class="md-fab md-accent md-fab-bottom-right" ng-click="vm.generateReport()">
            <md-icon md-svg-src="images/ic_picture_as_pdf_white_48px.svg" class="s24"
                     aria-label="generate reports"></md-icon>
            <md-tooltip md-delay="50" md-direction="left">{{'PRINT_CARDS.GENERATE' | translate}}</md-tooltip>
          </md-button>
        </div>
      </div>
    </div>
  </div>
  <neo-print-card-template id="cardsPrintPreview" ng-hide="true" cards="vm.cards"></neo-print-card-template>
</div>