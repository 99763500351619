<div layout="column" ui-view>
  <div layout="row" layout-align="space-around center" layout-padding>
    <form ng-submit="vm.applyFilters()" name="listFilters" layout="row" flex-gt-md="70" flex="100"
          layout-align="space-around center">
      <md-autocomplete flex="40" flex-gt-md="50" md-selected-item="vm.selectedTownHall"
                       md-search-text="vm.searchText"
                       md-items="townhall in vm.townhalls | filterAccents: vm.searchText"
                       md-item-text="townhall.name"
                       md-require-match
                       ng-required="true"
                       md-min-length="0"
                       ng-disabled="vm.townhalls.length === 1"
                       md-clear-button="true"
                       md-floating-label="{{'SELECT_TOWNHALL_TO_FILTER' | translate}}" style="padding-top: 15px">
        <md-item-template>
          <span md-highlight-text="vm.searchText" md-highlight-flags="^i">{{townhall.name}}</span>
        </md-item-template>
        <md-not-found>
          {{'RESULT_NOT_FOUND' | translate}}
        </md-not-found>
      </md-autocomplete>
      <md-datepicker md-current-view="month" ng-required="true" ng-model="vm.iniDate"
                     md-placeholder="{{'INI_DATE' | translate}}" md-min-date="vm.currentSeason.iniDate"
                     md-max-date="vm.endDate"></md-datepicker>
      <md-datepicker md-current-view="month" ng-required="true" ng-model="vm.endDate"
                     md-placeholder="{{'END_DATE' | translate}}" md-min-date="vm.iniDate"
                     md-max-date="vm.currentSeason.endDate"
                     ng-disabled="vm.iniDate === null"></md-datepicker>
      <md-button type="submit" ng-disabled="listFilters.$invalid" class="md-raised md-primary">{{'FILTER' |
        translate}}
      </md-button>
    </form>
  </div>
  <div layout="row" layout-align="space-around center" ng-if="vm.isFiltered">
    <md-card flex-gt-md="80" flex="100">

      <div class="md-whiteframe-1dp" flex>
        <md-card-header layout-padding>
          <md-card-header-text>
            <span class="md-headline">{{'CHAMPIONSHIPS.MATCHES' | translate}}</span>
          </md-card-header-text>
        </md-card-header>
           <md-button ng-click="exportToExcel('table2', 'Report')">
                 <md-icon md-svg-src = '../../images/notebook.svg'></md-icon>
            </md-button> 
      </div>
      <md-card-content>
        <span class="md-body-2" ng-if="vm.matches.length === 0">{{'CHAMPIONSHIPS.MATCHES_NOT_FOUND' | translate}}</span>
        <md-table-container ng-if="vm.matches.length > 0">
          <table md-table md-row-select="true" ng-model="vm.selected" md-progress="vm.promiseMatches" id="table2">
            <thead md-head md-order="vm.queryOrder" md-on-reorder="vm.reorder">
            <tr md-row>
              <th md-column md-order-by="town">{{ 'TOWN' | translate}}</th>
              <th md-column md-order-by="championship">{{ 'CHAMPIONSHIPS.CHAMPIONSHIP' | translate}}</th>
              <th md-column md-order-by="matchday">{{ 'CHAMPIONSHIPS.MATCH_DAY' | translate}}</th>
              <th md-column md-order-by="date">{{ 'CHAMPIONSHIPS.MATCH_DATE' | translate}}</th>
              <th md-column md-order-by="time">{{ 'CHAMPIONSHIPS.MATCH_TIME' | translate}}</th>
              <th md-column md-order-by="localTeam">{{ 'CHAMPIONSHIPS.LOCAL_TEAM' | translate}}</th>
              <th md-column md-order-by="visitorTeam">{{ 'CHAMPIONSHIPS.VISITOR_TEAM' | translate}}</th>
              <th md-column md-order-by="facility">{{ 'FACILITIES.FACILITY' | translate}}</th>
              <th md-column >{{ 'ACTIONS' | translate}}</th>
            </tr>
            </thead>
            <tbody md-body>
            <tr md-row md-select="vm.select" ng-repeat="match in vm.matches">
              <td md-cell>{{match.town}}</td>
              <td md-cell>{{match.championship.name}}</td>
              <td md-cell>{{match.matchDay}}</td>
              <td md-cell>{{match.date}}</td>
              <td md-cell>{{match.time}}</td>
              <td md-cell>{{match.localTeam.name || ('CALENDAR.REST' | translate)}}</td>
              <td md-cell>{{match.visitorTeam.name || ('CALENDAR.REST' | translate)}}</td>
              <td md-cell>
                <span>{{match.facility.name || '-'}}</span>
                <md-button ng-if="match.facility.name"
                           class="md-icon-button"
                           ui-sref="home.facilities.facility({facilityId: match.facility.id})">
                  <md-tooltip md-direction="right" md-delay="50">{{'FACILITIES.MAP' | translate}}</md-tooltip>
                  <md-icon>maps</md-icon>
                </md-button>
              </td>
              <td md-cell>
                <md-button class="md-icon-button" ng-if="match.championship.editableMatches"
                           ui-sref="home.championships.championship.assignMatches({championshipId: match.championship.id})">
                  <md-tooltip md-direction="right" md-delay="50">{{'EDIT' | translate}}</md-tooltip>
                  <md-icon>edit</md-icon>
                </md-button>
              </td>
            </tr>
            </tbody>
          </table>
        </md-table-container>
        <md-table-pagination md-limit="vm.matchesPerPage" md-page="vm.currentPage" md-total="{{vm.maxMatches}}"
                             md-label="{{vm.paginationLabel}}" md-page-select="true"
                             md-on-paginate="vm.getNewPage" ng-if="vm.matches.length > 0"></md-table-pagination>
      </md-card-content>
    </md-card>
  </div>
</div>

