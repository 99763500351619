<div layout="column" layout-gt-sm="row">
  <div layout="column" style="height: 100%" layout-align="space-between" flex-gt-sm="33">
    <div ui-view="image" flex="50"></div>
    <div ui-view="people" flex="50"></div>
  </div>
  <div layout="row" layout-padding flex layout-align="center center">
    <div ui-view="text" style="min-width: 75%" flex></div>
  </div>
  <neo-action-button buttons="vm.buttons"></neo-action-button>
</div>


