<div layout="row" layout-align="space-around center" flex>
  <md-card flex="90">
    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline">{{"CHAMPIONSHIPS.CLASSIFICATION" | translate }}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>
      <div flex>
        <h1 class="md-title">{{'CHAMPIONSHIPS.CHAMPIONSHIP' | translate}}</h1>
        <md-divider></md-divider>
        <div layout="row" layout-align="space-around none" layout-padding layout-wrap>
          <!-- NAME -->
          <neo-input name="name" label="CHAMPIONSHIPS.NAME" icon="images/ic_title_black_48px.svg"
                     ng-model="vm.championship.name" flex="25" is-disabled="true"></neo-input>
          <!-- CATEGORY -->
          <neo-input name="category" label="CATEGORY" icon="images/ic_folder_black_48px.svg"
                     ng-model="vm.category" flex="25" is-disabled="true"></neo-input>
          <!-- SPORT -->
          <neo-input name="sport" label="SPORT" icon="images/football.svg"
                     ng-model="vm.sport" flex="25" is-disabled="true"></neo-input>
          <!-- ROUNDS -->
          <neo-input name="gender" label="GENDER" icon="images/gender-male-female.svg"
                     ng-model="vm.gender" flex="25" is-disabled="true"></neo-input>
        </div>
      </div>
      <md-table-pagination md-limit="vm.matchesPerPage" md-page="vm.currentMatch" md-total="{{vm.maxMatches}}"
                           md-label="{{vm.paginationLabel}}" md-page-select="true"
                           md-on-paginate="vm.getNewPage"></md-table-pagination>
      <h3 ng-if="vm.classification.length === 0">{{'CHAMPIONSHIPS.CLASSIFICATION_NOT_PLAYED_YET' |
        translate}}</h3>
      <md-table-container ng-if="vm.classification.length !== 0">
        <table md-table md-progress="vm.promiseClassification">
          <thead md-head>
          <tr md-row>
            <th ng-repeat="header in vm.classificationHeaders" md-column>
              <div layout="column" layout-align="center center">
                <span class="md-title">{{header| translate}}</span>
              </div>
            </th>
          </tr>
          </thead>
          <tbody md-body>
          <tr md-row ng-repeat="team in vm.classification">
            <td md-cell class="md-body-1">{{team.teamName}}</td>
            <td md-cell class="md-body-1">
              <div layout="column" layout-align="center center">{{team.points}}</div>
            </td>
            <td md-cell class="md-body-1">
              <div layout="column" layout-align="center center">{{team.playedGames}}</div>
            </td>
            <td md-cell class="md-body-1">
              <div layout="column" layout-align="center center">{{team.wins}}</div>
            </td>
            <td md-cell class="md-body-1">
              <div layout="column" layout-align="center center">{{team.playedGames - team.wins - team.loses}}</div>
            </td>
            <td md-cell class="md-body-1">
              <div layout="column" layout-align="center center">{{team.loses}}</div>
            </td>
            <td md-cell class="md-body-1">
              <div layout="column" layout-align="center center">{{team.scoreFor}}</div>
            </td>
            <td md-cell class="md-body-1">
              <div layout="column" layout-align="center center">{{team.scoreAgainst}}</div>
            </td>
            <td md-cell class="md-body-1">
              <div layout="column" layout-align="center center">{{team.penalties}}</div>
            </td>
          </tr>
          </tbody>
        </table>
      </md-table-container>
      <md-divider></md-divider>
      <md-table-container style="padding-top: 20px;">
        <table md-table md-progress="vm.promiseMatches">
          <thead md-head>
          <tr md-row>
            <th ng-repeat="header in vm.matchHeaders" md-column>
              <span class="md-title">{{header | translate}}</span>
            </th>
          </tr>
          </thead>
          <tbody md-body>
          <tr md-row md-select="vm.select" ng-repeat="match in vm.matchDay.matches">
            <td md-cell class="md-body-1">{{match.localTeam}}</td>
            <td md-cell class="md-body-1">{{match.localScore}}</td>
            <td md-cell class="md-body-1">{{match.visitorScore}}</td>
            <td md-cell class="md-body-1">{{match.visitorTeam}}</td>
          </tr>
          </tbody>
        </table>
      </md-table-container>
    </md-card-content>
  </md-card>
</div>
