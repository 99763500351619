<div layout="row" layout-padding layout-align="space-around center" flex>
  <md-card class="md-whiteframe-z2" flex-gt-md="100" flex="100">
    <md-card-header layout-padding class="md-whiteframe-1dp">
      <md-card-header-text>
        <span class="md-headline">{{ 'CALENDAR.TITLE' | translate}}:&nbsp;&nbsp;{{vm.championship.name}}</span>
      </md-card-header-text>
    </md-card-header>
    <md-card-content layout="column" layout-align="space-around stretch">

      <div flex>
       
        <md-divider></md-divider>
        <div layout="row" layout-align="space-around none" layout-padding layout-wrap>
          <!-- NAME -->
          <neo-input name="name" label="CHAMPIONSHIPS.NAME" icon="images/ic_title_black_48px.svg"
                     ng-model="vm.championship.name" flex="25" is-disabled="true"></neo-input>
          <!-- CATEGORY -->
          <neo-input name="category" label="CATEGORY" icon="images/ic_folder_black_48px.svg"
                     ng-model="vm.category" flex="25" is-disabled="true"></neo-input>
          <!-- SPORT -->
          <neo-input name="sport" label="SPORT" icon="images/football.svg"
                     ng-model="vm.sport" flex="25" is-disabled="true"></neo-input>
          <!-- ROUNDS -->
          <neo-input name="gender" label="GENDER" icon="images/gender-male-female.svg"
                     ng-model="vm.gender" flex="25" is-disabled="true"></neo-input>
        </div>
      </div>

      <div flex id="matches">
        <h1 class="md-title">{{'CALENDAR.MATCHES' | translate}}</h1>
        <md-divider></md-divider>


        <div layout-xs="column" layout-sm="column" layout-gt-sm="row" layout-wrap layout-padding>

          <div layout="row" layout-align="space-around center" flex="60">
            <div layout="row">
              <span class="md-body-2"> {{'POSTPONEMENTS.POSTPONED_MATCH' | translate }}</span>
              <div
                style=" margin-left: 10px; border: 1px solid rgba(0, 0, 0, 0);  border-radius: 2px; height: 20px; width: 20px;"
                md-colors="::{background : {{'\'grey\''}}}"></div>
            </div>

            <div layout="row">
              <span class="md-body-2">{{'CANCELLATIONS.SUSPENDED_MATCH' | translate}}</span>
              <div
                style=" margin-left: 10px; border: 1px solid rgba(0, 0, 0, 0);  border-radius: 2px; height: 20px; width: 20px;"
                md-colors="::{background :  {{'\'warn-A100\''}}}"></div>
            </div>
          </div>

          <div class="matchday-container" ng-repeat="matchDay in vm.matchDays" flex="50">
            <md-list layout="column" flex>
              <div class="match-header" md-colors="{background: 'primary'}" layout-padding flex>
                <span class="md-title" ng-if="!matchDay.sportMatchDay">{{'CALENDAR.MATCH_DAY' | translate}} {{matchDay.number}}</span>
                <span class="md-title" ng-if="matchDay.sportMatchDay">{{'CALENDAR.SPORT_MATCH_DAY' | translate}}</span>
              </div>
              <md-list-item ng-if="matchDay.sportMatchDay">
                <span>{{matchDay.date}}</span>
              </md-list-item>
              <md-list-item class="match-container" ng-repeat="match in matchDay.matches" layout="row"
                            layout-align="space-around center"
                            layout-wrap md-colors="::{background: {{ vm.postponedMatch(match) ? '\'grey\'' :
                            (vm.matchHasUnresolvedCancellation(match) ? '\'warn-A100\'' : ($odd ? '\'primary-50\'': '\'background\''))}}} ">
                <span flex="20">{{match.date}} {{match.time || '--:--'}}</span>
                <span flex="25">{{match.team1.teamInfo.name || ('CALENDAR.REST' | translate)}}</span>
                <span flex="10" layout="row" layout-align="space-around center" layout-wrap>
                  <span flex="40">{{match.localScore || ' '}}</span>
                  <span flex="20"> - </span>
                  <span flex="40">{{match.visitorScore || ' '}}</span>
                </span>
                <span flex="25">{{match.team2.teamInfo.name || ('CALENDAR.REST' | translate)}}</span>
                <span flex="20">{{match.facility.name || ('CALENDAR.NOT_FACILITY_ASSIGNED' | translate)}}</span>
              </md-list-item>
            </md-list>
          </div>
        </div>
      </div>

      <div ng-if="!vm.isEmpty(vm.facilities)" flex id="facilities">
        <h1 class="md-title">{{'CALENDAR.FACILITIES' | translate}}</h1>
        <md-divider></md-divider>
        <md-list layout="column" layout-wrap layout-padding>
          <md-list-item layout="row" layout-align="space-around center" layout-wrap
                        md-colors="{background: 'primary'}" class="facility-container">

            <span flex class="md-title">{{'CALENDAR.FACILITY_MAP' | translate}}</span>
            <span flex class="md-title">{{'CALENDAR.FACILITY_NAME' | translate}}</span>
            <span flex class="md-title">{{'CALENDAR.FACILITY_ADDRESS' | translate}}</span>
            <span flex class="md-title">{{'CALENDAR.FACILITY_TOWN' | translate}}</span>
            <span flex class="md-title">{{'CALENDAR.FACILITY_ZIP_CODE' | translate}}</span>
          </md-list-item>

          <md-list-item class="facility-container" ng-repeat="facility in vm.facilities" layout="row"
                        layout-align="space-around center"
                        layout-wrap md-colors="::{background: {{ $odd ? '\'primary-50\'': '\'background\''}}}">
            <span flex>
              <md-button class="md-primary"
                         ng-href="https://maps.google.com/?q=@{{facility.latitude}},{{facility.longitude}}"
                         target="_blank">
              <md-tooltip md-direction="right" md-delay="50">{{'FACILITIES.MAP' | translate}}</md-tooltip>
                <md-icon>place</md-icon>
              </md-button>
            </span>
            <span flex>{{facility.name}}</span>
            <span flex>{{facility.address}}</span>
            <span flex>{{facility.townName}}</span>
            <span flex>{{facility.zipCode}}</span>
          </md-list-item>
        </md-list>
      </div>

      <div flex id="teams">
        <h1 class="md-title">{{'CALENDAR.TEAMS' | translate}}</h1>
        <md-divider></md-divider>
        <md-list layout="column" layout-wrap layout-padding>
          <md-list-item layout="row" layout-align="space-around center" layout-wrap
                        md-colors="{background: 'primary'}" class="team-container">

            <span flex class="md-title">{{'CALENDAR.TEAM_NAME' | translate}}</span>
            <span flex class="md-title">{{'CALENDAR.ENTITY_NAME' | translate}}</span>
            <span flex class="md-title">{{'CALENDAR.ENTITY_PHONE' | translate}}</span>
            <span flex class="md-title">{{'CALENDAR.CONTACT_NAME' | translate}}</span>
            <span flex class="md-title">{{'CALENDAR.CONTACT_PHONE' | translate}}</span>
            <span flex class="md-title">{{'CALENDAR.TEAM_SHIRT_COLOR' | translate}}</span>
            <span flex class="md-title">{{'CALENDAR.TEAM_PANTS_COLOR' | translate}}</span>
          </md-list-item>

          <md-list-item class="team-container" ng-repeat="team in vm.teams" layout="row"
                        layout-align="space-around center"
                        layout-wrap md-colors="::{background: {{ $odd ? '\'primary-50\'': '\'background\''}}}">
            <span flex>{{team.teamInfo.name}}</span>
            <span flex>{{team.publicEntityModel.name}}</span>
            <span flex>{{team.publicEntityModel.phoneNumber}}</span>
            <span flex>{{team.teamInfo.contactName}}</span>
            <span flex>{{team.teamInfo.phoneNumber}}</span>
            <span flex>{{team.teamInfo.shirtColor}}</span>
            <span flex>{{team.teamInfo.pantsColor}}</span>
          </md-list-item>
        </md-list>
      </div>

    </md-card-content>
  </md-card>

  <neo-action-button buttons="vm.buttons"></neo-action-button>

</div>
