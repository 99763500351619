<div layout="row" layout-padding layout-align="space-around center" flex>
    <md-card class="md-whiteframe-z2" flex-gt-md="70" flex="100">
        <md-card-header layout-padding class="md-whiteframe-1dp">
            <md-card-header-text>
                <span class="md-headline">{{ 'FACILITIES.NEW_DATA' | translate}}</span>
            </md-card-header-text>
        </md-card-header>
        <md-card-content layout="row" layout-gt-md="column" layout-align="space-around stretch">
            <form name="facilityForm" ng-submit="vm.submitFacility()">
                <div flex layout="row" layout-align="space-around stretch">
                    <!-- TOWN HALL -->
                    <div layout="column" flex="45">
                        <md-autocomplete layout-padding ng-disabled="vm.townHalls.length === 1"
                                         md-selected-item="vm.facility.townHall"
                                         md-search-text="townHallSearch" md-clear-button="true"
                                         md-items="townHall in vm.townHalls | filterAccents: townHallSearch"
                                         md-item-text="townHall.name" md-min-length="0" md-require-match
                                         ng-required="true"
                                         md-floating-label="{{'TOWN_HALL' | translate}}">
                            <md-item-template>
                                <span md-highlight-text="vm.townHallSearch"
                                      md-highlight-flags="^i">{{townHall.name}}</span>
                            </md-item-template>
                            <md-not-found>
                                {{'RESULT_NOT_FOUND' | translate}}
                            </md-not-found>
                        </md-autocomplete>
                        <!-- NAME -->
                        <md-input-container>
                            <label>{{'NAME' | translate}}</label>
                            <md-icon>title</md-icon>
                            <input name="name" type="text" ng-model="vm.facility.name" required>
                            <div ng-messages="facilityForm.name.$error" role="alert">
                                <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                                <!--<div ng-message="pattern">{{error | translate}}</div>-->
                            </div>
                        </md-input-container>
                    </div>
                    <div layout="column" flex="45" layout-align="space-around stretch" style="padding-top: 1%">
                        <md-input-container>
                            <label>{{'ADDRESS' | translate}}</label>
                            <md-icon>location_on</md-icon>
                            <input name="address" type="text" ng-model="vm.facility.address" required>
                            <div ng-messages="facilityForm.address.$error" role="alert">
                                <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                                <div ng-message="pattern">{{error | translate}}</div>
                            </div>
                        </md-input-container>

                        <div layout="row" flex="45" layout-align="space-around scratch" style="padding-top: 1%">
                          <md-input-container class="md-block">
                            <label>{{'TOWN' | translate}}</label>
                            <md-icon>location_on</md-icon>
                            <input name="town" type="text" ng-model="vm.facility.townName" required>
                            <div ng-messages="facilityForm.townName.$error" role="alert">
                              <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                            </div>
                          </md-input-container>
                            <md-input-container class="md-block" flex="20">
                                <label>{{'POSTAL_CODE' | translate}}</label>
                                <input name="zipCode" type="text" ng-model="vm.facility.zipCode"
                                       md-maxlength="5"
                                       ng-pattern="/^[0-9]{5}$/">
                                <div ng-messages="facilityForm.zipCode.$error" role="alert">
                                    <div ng-message="pattern">{{error | translate}}</div>
                                    <div ng-message="md-maxlength">{{'MAXIMUM_CHARACTERS_POSTAL_CODE' | translate}}
                                    </div>
                                </div>
                            </md-input-container>
                            <div layout="row" style="padding: 2% 0 6% 0">
                                <md-button class="md-raised md-primary" ng-click="vm.searchLocation()">Cerca</md-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div flex id="map_canvas">
                    <ui-gmap-google-map center="vm.map.center" zoom="vm.map.zoom" options="vm.map.options"
                                        data-tap-disabled="true" events="vm.map.events">
                        <ui-gmap-markers models="vm.map.markers" coords="'self'">
                        </ui-gmap-markers>
                    </ui-gmap-google-map>
                </div>
                <div flex>
                    <md-button class="md-fab md-accent md-fab-bottom-right" ng-disabled="facilityForm.$invalid"
                               type="submit">
                        <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
                        <md-tooltip md-delay="50" md-direction="left">{{'SAVE' | translate}}</md-tooltip>
                    </md-button>
                </div>
            </form>
        </md-card-content>
    </md-card>
</div>
