<div layout="row" layout-align="space-around center">

  <md-card flex-gt-md="70" flex="100">
    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline">{{'NEW_CHAMPIONSHIP_UPLOAD' | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>
      <div layout-align="center center" layout="row">
        <div flex="100">
          <form name="championshipForm" ng-submit="vm.saveChampionship()">
            <div layout="column" layout-padding>
              <div layout-padding>
                <h1 class="md-title">{{'CHAMPIONSHIPS.NEW' | translate}}</h1>
                <md-divider></md-divider>
                <div layout-gt-sm="row" layout-align="space-around none" flex >
                  <!-- NAME -->
                  <neo-input name="name" label="CHAMPIONSHIPS.NAME" icon="images/ic_title_black_48px.svg"
                             is-required="true"
                             ng-model="vm.championship.name" flex="45" layout-gt-sm="column"></neo-input>
                  <!-- PHASE -->
                  <md-input-container class="md-block" flex="45">
                    <label>{{'CHAMPIONSHIPS.PHASE' | translate}}</label>
                    <md-select name="phase" ng-model="vm.championship.phaseId" ng-required="true">
                      <md-option ng-repeat="phase in vm.phases" value="{{phase.id}}">
                        {{phase.name | translate }}
                      </md-option>
                    </md-select>
                    <div ng-messages="championshipForm.phase.$error" role="alert">
                      <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                    </div>
                  </md-input-container>
                </div>
                <div layout-gt-sm="row" layout-align="space-around center" flex>
                  <!-- PROGRAMME TYPE -->
                  <md-input-container class="md-block" flex="45">
                    <label>{{'PROGRAM_TYPE' | translate}}</label>
                    <md-select name="programme" ng-model="vm.championship.programmeId" ng-required="true"
                               ng-disabled="true">
                      <md-option ng-repeat="programme in vm.programmes" value="{{programme.id}}">
                        {{programme.name | translate }}
                      </md-option>
                    </md-select>
                    <div ng-messages="championshipForm.programme.$error" role="alert">
                      <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                    </div>
                  </md-input-container>
                  <!-- CHAMPIONSHIP TYPE -->
                  <md-input-container class="md-block" flex="45">
                    <label>{{'CHAMPIONSHIPS.TYPE' | translate}}</label>
                    <md-select name="type" ng-model="vm.championship.typeId" ng-required="true" ng-disabled="true">
                      <md-option ng-repeat="type in vm.championshipTypes" value="{{type.id}}">
                        {{type.name | translate}}
                      </md-option>
                    </md-select>
                    <div ng-messages="championshipForm.type.$error" role="alert">
                      <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                    </div>
                  </md-input-container>
                </div>
                <div layout-gt-sm="row" layout-align="space-around center" flex>
                  <!-- SPORT -->
                  <md-input-container class="md-block" flex="45">
                    <label>{{'SPORT' | translate}}</label>
                    <md-icon md-svg-src="images/football.svg"></md-icon>
                    <md-select name="sport" ng-model="vm.championship.sportId" ng-required="true">
                      <md-option ng-repeat="sport in vm.sports" value="{{sport.id}}">
                        {{'SPORTS.' + sport.name | translate }}
                      </md-option>
                    </md-select>
                    <div ng-messages="championshipForm.sport.$error" role="alert">
                      <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                    </div>
                  </md-input-container>
                  <div layout-gt-sm="row" layout-align="space-between center" flex="45">
                    <!-- JVP -->
                    <md-checkbox ng-model="vm.championship.jvp" aria-label="verd-play">
                      {{'CHAMPIONSHIPS.VPLAY' | translate}}
                    </md-checkbox>
                    <!-- ROUNDS -->
                    <neo-input name="rounds" label="CHAMPIONSHIPS.ROUNDS" is-required="true"
                               ng-model="vm.championship.rounds"
                               error="CHAMPIONSHIPS.MAX_ROUNDS" layout-gt-sm="column"
                               icon="images/ic_repeat_black_48px.svg"
                               pattern="^([0-9])$"></neo-input>
                     <md-checkbox ng-model="vm.championship.invisible" aria-label="invisible">
                      {{'CHAMPIONSHIPS.INVISIBLE' | translate}}
                    </md-checkbox>
                  </div>
                </div>
                <div layout-gt-sm="row" layout-align="space-around center" flex>
                  <div layout-gt-sm="row" flex="45">
                    <!-- CATEGORY -->
                    <md-input-container class="md-block" flex="50">
                      <label>{{'CATEGORY' | translate}}</label>
                      <md-icon md-svg-src="images/ic_folder_black_48px.svg"></md-icon>
                      <md-select name="category" ng-model="vm.championship.categoryId" required>
                        <md-option ng-repeat="category in vm.categories" value="{{category.id}}">
                          {{'CATEGORIES.'+category.name | translate}}
                        </md-option>
                      </md-select>
                      <div ng-messages="championshipForm.category.$error" role="alert">
                        <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                      </div>
                    </md-input-container>
                    <!-- GENDER -->
                    <md-input-container class="md-block" flex="50">
                      <label>{{'GENDER' | translate}}</label>
                      <md-icon md-svg-src="images/gender-male-female.svg"></md-icon>
                      <md-select name="gender" ng-model="vm.championship.genderId" ng-required="true">
                        <md-option ng-repeat="gender in vm.genders" value="{{gender.id}}">{{gender.name | translate}}
                        </md-option>
                      </md-select>
                      <div ng-messages="championshipForm.gender.$error" role="alert">
                        <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                      </div>
                    </md-input-container>
                  </div>
                  <!-- SCORES -->
                  <div layout-gt-sm="row" flex="45">
                    <neo-input name="victory_points" label="CHAMPIONSHIPS.SCORE.VICTORY"
                               is-required="!vm.championship.jvp"
                               ng-model="vm.championship.winPoints" flex layout-gt-sm="column"
                               error="CHAMPIONSHIPS.SCORE.ERROR"
                               is-disabled="vm.championship.jvp" pattern="^([0-9]+)$"
                               ng-show="!vm.championship.jvp"></neo-input>
                    <neo-input name="tie_points" label="CHAMPIONSHIPS.SCORE.TIE" is-required="!vm.championship.jvp"
                               ng-model="vm.championship.drawPoints" flex layout-gt-sm="column"
                               error="CHAMPIONSHIPS.SCORE.ERROR"
                               is-disabled="vm.championship.jvp" pattern="^([0-9]+)$"
                               ng-show="!vm.championship.jvp"></neo-input>
                    <neo-input name="loss_points" label="CHAMPIONSHIPS.SCORE.LOSS" is-required="!vm.championship.jvp"
                               ng-model="vm.championship.lostPoints" flex layout-gt-sm="column"
                               error="CHAMPIONSHIPS.SCORE.ERROR"
                               is-disabled="vm.championship.jvp" pattern="^([0-9]+)$"
                               ng-show="!vm.championship.jvp"></neo-input>
                  </div>
                </div>

                <div layout-gt-sm="row" layout-align="center center" flex>
                  <!-- EDITABLE MATCHES -->
                  <md-switch ng-model="vm.championship.editableMatches" aria-label="editable-matches"
                             ng-if="vm.isAdmin()">
                    {{'CHAMPIONSHIPS.EDITABLE_MATCHES' | translate}}
                  </md-switch>
                </div>

              </div>
            </div>
            <div flex>
              <md-button class="md-fab md-accent md-fab-bottom-right" ng-disabled="championshipForm.$invalid"
                         type="submit">
                <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
                <md-tooltip md-delay="50" md-direction="left">{{'SAVE' | translate}}</md-tooltip>
              </md-button>
            </div>
          </form>
        </div>
      </div>
    </md-card-content>
  </md-card>
</div>
