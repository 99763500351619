<div layout="row" layout-align="space-around center">
  <md-card flex-sm="90" flex-lg="50" flex-md="65">
    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline">{{'CHANGE_EMAIL' | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>
      <form name="changeUsernameForm" ng-submit="vm.changeUsername()">
        <div layout="column" layout-align="center" layout-padding>
          <div layout="column" layout-align="start stretch">
            <md-input-container>
              <label>{{'PASSWORD' | translate}}</label>
              <md-icon md-svg-src="images/ic_lock_black_48px.svg"></md-icon>
              <input type="password" name="password" ng-model="vm.changeUsernameModel.password" ng-required="true"
                     ng-pattern="/^.{4,}?/">
              <div ng-messages="changeUsernameForm.password.$error" role="alert">
                <div ng-message="pattern">{{'PASSWORD_INVALID_FORMAT' | translate}}</div>
              </div>
            </md-input-container>

            <md-input-container>
              <label>{{'NEW_EMAIL' | translate}}</label>
              <md-icon md-svg-src="images/ic_lock_black_48px.svg"></md-icon>
              <input type="email" name="newUsername" ng-model="vm.changeUsernameModel.newUsername" ng-required="true"
                     ng-pattern="/^.+@.+\..+$/">
              <div ng-messages="changeUsernameForm.newUsername.$error" role="alert">
                <div ng-message-exp="['required', 'pattern']">
                  {{'EMAIL_INVALID_FORMAT' | translate}}
                </div>
              </div>
            </md-input-container>

            <md-input-container>
              <label>{{'EMAIL_REPEAT' | translate}}</label>
              <md-icon md-svg-src="images/ic_lock_black_48px.svg"></md-icon>
              <input type="email" name="newUsername2" ng-model="vm.username2" ng-required="true"
              neo-match="vm.changeUsernameModel.newUsername">
              <div ng-messages="changeUsernameForm.newUsername2.$error" role="alert">
                <div ng-message="neoMatch">{{'EMAIL_INVALID_MATCH' | translate}}</div>
              </div>
            </md-input-container>
          </div>
          <div layout="column" flex layout-align="center end">
            <md-button class="md-raised md-primary" ng-disabled="changeUsernameForm.$invalid"
                       type="submit">{{'SEND' | translate}}
            </md-button>
          </div>
        </div>
      </form>
    </md-card-content>
  </md-card>
</div>
