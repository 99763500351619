<div layout="row" layout-align="space-around center">

  <md-card flex-gt-md="70" flex="100">
    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline">{{'REFEREES.NEW' | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>
      <div layout-align="center center" layout="row" flex>
          <form name="refereeForm" ng-submit="vm.saveReferee()" flex>
            <div layout="column" layout-padding flex>
              <h1 class="md-title">{{'ASSOCIATIONS' | translate}}</h1>
              <md-divider></md-divider>
              <div layout="row" layout-align="center" flex>
                <div layout="row" layout-align="space-around center" flex="50" ng-if="vm.isAdmin()">
                  <md-checkbox ng-model="vm.belongsToCouncil" aria-label="council"
                               ng-change="vm.setRefereeToCouncil()">
                    {{'COUNCIL' | translate}}
                  </md-checkbox>
                </div>

                <div layout="row" layout-align="space-around center" flex="50">
                  <md-checkbox ng-model="vm.belongsToTownHall" aria-label="townHall"
                               ng-change="vm.setRefereeToTownhall()">
                    {{'TOWN_HALL' | translate}}
                  </md-checkbox>
                  <md-autocomplete layout-padding ng-disabled="!vm.isTowhallSelectable"
                                   md-selected-item="vm.selectedTownhall"
                                   md-search-text="townHallSearch" md-clear-button="true"
                                   md-items="townHall in vm.townHalls | filterAccents: townHallSearch"
                                   md-item-text="townHall.name" md-min-length="0" md-require-match
                                   ng-required="vm.isTowhallSelectable"
                                   md-floating-label="{{'TOWN_HALL' | translate}}">
                    <md-item-template>
                                <span md-highlight-text="vm.townHallSearch"
                                      md-highlight-flags="^i">{{townHall.name}}</span>
                    </md-item-template>
                    <md-not-found>
                      {{'RESULT_NOT_FOUND' | translate}}
                    </md-not-found>
                  </md-autocomplete>
                </div>

                <div layout="row" layout-align="space-around center" flex="50" ng-if="!vm.isAdmin()">
                  <md-checkbox ng-model="vm.belongsToEntity" aria-label="entity"
                               ng-change="vm.setRefereeToEntity()">
                    {{'ENTITY' | translate}}
                  </md-checkbox>
                  <md-autocomplete ng-disabled="!vm.isEntitySelectable" md-selected-item="vm.selectedEntity"
                                   md-search-text="entitySearch" md-clear-button="true"
                                   md-items="entity in vm.entities | filterAccents: entitySearch"
                                   md-item-text="entity.name" md-min-length="0" md-require-match
                                   ng-required="vm.isEntitySelectable"
                                   md-floating-label="{{'ENTITY' | translate}}">
                    <md-item-template>
                      <span md-highlight-text="vm.entitySearch" md-highlight-flags="^i">{{entity.name}}</span>
                    </md-item-template>
                    <md-not-found>
                      Not Found
                    </md-not-found>
                  </md-autocomplete>
                </div>
              </div>

              <h1 class="md-title">{{'PERSONAL_DATA' | translate}}</h1>
              <md-divider></md-divider>
              <div layout-gt-sm="row" layout-align="space-around center">
                <div layout-gt-sm="row" flex="45">
                  <neo-input name="name" label="NAME" icon="images/ic_person_black_48px.svg" is-required="true"
                             ng-model="vm.person.name" flex="50" layout-gt-sm="column"></neo-input>
                  <neo-input name="surname" label="SURNAME" icon="images/ic_person_black_48px.svg" is-required="true"
                             ng-model="vm.person.surname" flex="45"
                             layout-gt-sm="column"></neo-input>
                </div>
                <div layout-gt-sm="row" flex="45">

                  <neo-input name="secondSurname" label="SECOND_SURNAME" ng-model="vm.person.secondSurname" flex="50"
                             layout-gt-sm="column"></neo-input>

                  <md-input-container class="md-block" flex="50">
                    <label>{{'BIRTH_DATE' | translate}}</label>
                    <md-datepicker name="birthday" ng-model="vm.person.bornDate" md-current-view="year"
                                   ng-required="true" md-max-date="vm.today"></md-datepicker>
                    <div ng-messages="refereeForm.birthday.$error" role="alert">
                      <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                      <div ng-message="valid">{{'DATE_INVALID_FORMAT' | translate}}</div>
                    </div>
                  </md-input-container>
                </div>
              </div>

              <div layout-gt-sm="row" layout-align="space-around center">
                <div layout-gt-sm="row" flex="50">


                  <md-input-container class="md-block" flex="50">
                    <label>{{'GENDER' | translate}}</label>
                    <md-icon md-svg-src="images/gender-male-female.svg"></md-icon>
                    <md-select name="gender" ng-model="vm.person.genderId" ng-required="true">
                      <md-option ng-repeat="gender in vm.personGenders" value="{{gender.id}}">{{gender.name |
                        translate}}
                      </md-option>
                    </md-select>
                    <div ng-messages="refereeForm.gender.$error" role="alert">
                      <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                    </div>
                  </md-input-container>

                  <neo-input name="nif" label="NIF/NIE" icon="images/ic_person_black_48px.svg" ng-model="vm.person.nif"
                             flex="45" layout-gt-sm="column" md-maxlength="50"></neo-input>
                </div>

                <neo-input name="email" label="CONTACT_EMAIL" icon="images/ic_email_black_48px.svg"
                           ng-model="vm.person.email" error="EMAIL_INVALID_FORMAT"
                           pattern="^.+@.+\..+$" flex="45" layout-gt-sm="column"></neo-input>
              </div>

              <div layout-gt-sm="row" layout-align="space-around center">

                <neo-input name="iban" label="IBAN" icon="images/ic_person_black_48px.svg" ng-model="vm.person.iban"
                           pattern="^ES[0-9]{2}[0-9]{4}[0-9]{4}[0-9]{1}[0-9]{1}[0-9]{10}$" error="IBAN_INVALID_FORMAT" flex="45" layout-gt-sm="column"></neo-input>


                <neo-input name="hic" label="HEALTH_INSURANCE_CARD_NUMBER" icon="images/ic_healing_black_48px.svg"
                           ng-model="vm.person.healthInsuranceCardNumber" flex="45" layout-gt-sm="column"></neo-input>
              </div>

              <div layout-gt-sm="row" layout-align="space-around center">
                <neo-input name="address" label="ADDRESS" icon="images/ic_location_black_48px.svg"
                           ng-model="vm.person.address" flex="45" layout-gt-sm="column"></neo-input>
                <div layout-gt-sm="row" flex="45">
                  <neo-input name="town" label="TOWN" icon="images/ic_location_city_black_48px.svg"
                             ng-model="vm.person.town" flex="70" layout-gt-sm="column"></neo-input>
                  <neo-input name="zipCode" label="POSTAL_CODE" ng-model="vm.person.zipCode"
                             error="INVALID_POSTAL_CODE" pattern="^[0-9]{5}$"
                             flex="30" layout-gt-sm="column"></neo-input>
                </div>
              </div>
              <div flex layout="row" layout-align="center center" layout-padding>
                <md-input-container>
                  <md-checkbox ng-model="vm.lopd" name="lopd" aria-label="LODP" ng-required="true">
                     <a target="_blank" href="http://cebllob.org/lopd.pdf">Autorització de tractament de dades de caràcter personal i Acord de comunicació de dades entre clubs/entitats/centres i el Consell Esportiu del Baix Llobregat</a>
                  </md-checkbox>
                  <div ng-messages="refereeForm.lopd.$error" role="alert">
                    <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                  </div>
                </md-input-container>
              </div>
            </div>
            <div flex>
              <md-button class="md-fab md-accent md-fab-bottom-right" ng-disabled="refereeForm.$invalid" type="submit">
                <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
                <md-tooltip md-delay="50" md-direction="left">{{'SAVE' | translate}}</md-tooltip>
              </md-button>
            </div>
          </form>
        </div>
    </md-card-content>
  </md-card>
</div>
