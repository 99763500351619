<div layout="row" layout-padding layout-align="space-around center" flex>
  <md-card class="md-whiteframe-z2" flex-gt-md="100" flex="100">
    <md-card-header layout-padding class="md-whiteframe-1dp">
      <md-card-header-text>
        <span class="md-headline">{{ 'CALENDAR.TITLE' | translate}}</span>
      </md-card-header-text>
    </md-card-header>
       
    <md-card-content layout="column" layout-align="space-around stretch">
  <md-table-container>
      <table md-table md-row-select="true"   id="table2">   
      <div flex>
        <h1 class="md-title">{{'CALENDAR.CHAMPIONSHIP' | translate}}</h1>
        <md-divider></md-divider>
        <div layout="row" layout-align="space-around none" layout-padding layout-wrap>
          <!-- NAME -->
            <td md-cell> {{vm.championship.name}}</td>
          <!-- CATEGORY -->
            <td md-cell> {{vm.category}}</td>
          <!-- SPORT -->
            <td md-cell> {{vm.sport}}</td>
          <!-- ROUNDS -->
           <td md-cell>  {{vm.gender}} </td>
        </div>
      </div>

      <div flex id="matches">
        <h1 class="md-title">{{'CALENDAR.MATCHES' | translate}}</h1>
        <md-divider></md-divider>


        <div layout-xs="column" layout-sm="column" layout-gt-sm="row" layout-wrap layout-padding>
            </table>
  </md-table-container>
        
   <md-table-container>
      <table md-table md-row-select="true"   id="table2">    
          <tbody  ng-repeat="matchDay in vm.matchDays" flex="50">
       
           <td md-cell>    <div class="match-header" md-colors="{background: 'primary'}" layout-padding flex>
                <span class="md-title" ng-if="!matchDay.sportMatchDay">{{'CALENDAR.MATCH_DAY' | translate}} {{matchDay.number}}</span>
                <span class="md-title" ng-if="matchDay.sportMatchDay">{{'CALENDAR.SPORT_MATCH_DAY' | translate}}</span>
               </div></td>
           <td md-cell>    <md-list-item ng-if="matchDay.sportMatchDay">
                <span>{{matchDay.date}}</span>
          </md-list-item></td>
                  
              <tr md-row class="match-container" ng-repeat="match in matchDay.matches" layout="row"
                            layout-align="space-around center"
                            layout-wrap md-colors="::{background: {{ vm.postponedMatch(match) ? '\'grey\'' :
                            (vm.matchHasUnresolvedCancellation(match) ? '\'warn-A100\'' : ($odd ? '\'primary-50\'': '\'background\''))}}} ">
                <td md-cell> <span flex="20">{{match.date}} {{match.time || '--:--'}}</span></td>
                <td md-cell> <span flex="25">{{match.team1.teamInfo.name || ('CALENDAR.REST' | translate)}}</span></td>
               <td md-cell>  <span flex="10" layout="row" layout-align="space-around center" layout-wrap>
                  <span flex="40">{{match.localScore || ' '}}</span>
                  <span flex="20"> - </span>
                  <span flex="40">{{match.visitorScore || ' '}}</span>
                </span></td>
                <td md-cell> <span flex="25">{{match.team2.teamInfo.name || ('CALENDAR.REST' | translate)}}</span></td>
              <td md-cell>   <span flex="20">{{match.facility.name || ('CALENDAR.NOT_FACILITY_ASSIGNED' | translate)}}</span></td>
              </tr>  
      </tbody>
</table>
                   </md-table-container>
          </div>
        </div>
      </div>

      <div ng-if="!vm.isEmpty(vm.facilities)" flex id="facilities">
        <h1 class="md-title">{{'CALENDAR.FACILITIES' | translate}}</h1>
        <md-divider></md-divider>
         <md-table-container>
        <table layout="column" layout-wrap layout-padding>
          <tr layout="row" class="match-container" >
            <td md-cell> <span flex class="md-title">INSTAL·LACIO</span></td>
            <td md-cell> <span flex class="md-title">ADRERÇA</span></td>
            
            
            
            <td md-cell>  <span flex class="md-title">POBLACIÓ</span></td>
            <td md-cell>  <span flex class="md-title">CODI POSTAL</span></td>
          </tr>

          <tr class="facility-container" ng-repeat="facility in vm.facilities" layout="row"
                        layout-align="space-around center"
                        layout-wrap md-colors="::{background: {{ $odd ? '\'primary-50\'': '\'background\''}}}">
            
            <td md-cell> <span flex>{{facility.name}}</span></td>
           <td md-cell>  <span flex>{{facility.address}}</span></td>
          <td md-cell>   <span flex>{{facility.town.name}}</span></td>
           <td md-cell>  <span flex>{{facility.zipCode}}</span></td>
          </tr>
        </table>
              </md-table-container>
      </div>

      <div flex id="teams">
        <h1 class="md-title">{{'CALENDAR.TEAMS' | translate}}</h1>
        <md-divider></md-divider>
        <md-list layout="column" layout-wrap layout-padding>
          <md-list-item layout="row" layout-align="space-around center" layout-wrap
                        md-colors="{background: 'primary'}" class="team-container">

            <span flex class="md-title">{{'CALENDAR.TEAM_NAME' | translate}}</span>
            <span flex class="md-title">{{'CALENDAR.ENTITY_NAME' | translate}}</span>
            <span flex class="md-title">{{'CALENDAR.ENTITY_PHONE' | translate}}</span>
            <span flex class="md-title">{{'CALENDAR.CONTACT_NAME' | translate}}</span>
            <span flex class="md-title">{{'CALENDAR.CONTACT_PHONE' | translate}}</span>
            <span flex class="md-title">{{'CALENDAR.TEAM_SHIRT_COLOR' | translate}}</span>
            <span flex class="md-title">{{'CALENDAR.TEAM_PANTS_COLOR' | translate}}</span>
          </md-list-item>

          <md-list-item class="team-container" ng-repeat="team in vm.teams" layout="row"
                        layout-align="space-around center"
                        layout-wrap md-colors="::{background: {{ $odd ? '\'primary-50\'': '\'background\''}}}">
            <span flex>{{team.teamInfo.name}}</span>
            <span flex>{{team.publicEntityModel.name}}</span>
            <span flex>{{team.publicEntityModel.phoneNumber}}</span>
            <span flex>{{team.teamInfo.contactName}}</span>
            <span flex>{{team.teamInfo.phoneNumber}}</span>
            <span flex>{{team.teamInfo.shirtColor}}</span>
            <span flex>{{team.teamInfo.pantsColor}}</span>
          </md-list-item>
        </md-list>
      </div>

    </md-card-content>
  </md-card>

  <neo-action-button buttons="vm.buttons"></neo-action-button>

</div>
