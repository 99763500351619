<form novalidate name="editForm" ng-submit="vm.updateAjuntament()">
  <div class="md-whiteframe-1dp">
    <div layout="row" layout-xs="column" layout-align-gt-xs="space-around center" layout-padding>
      <div flex-gt-xs="40" flex layout="column" layout-padding>

        <md-input-container>
          <md-icon md-svg-src="images/ic_person_black_48px.svg"></md-icon>
          <label>{{'NAME' | translate}}</label>
          <input name="name" title="name" type="text" required ng-model="vm.ajuntament.name" ng-disabled="!vm.edit"/>
          <div ng-messages="editForm.name.$error" role="alert">
            <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
          </div>
        </md-input-container>

        <md-input-container class="md-block">
          <md-icon md-svg-src="images/ic_email_black_48px.svg"></md-icon>
          <label>{{'EMAIL' | translate}}</label>
          <input name="email" type="email" required ng-pattern="/^.+@.+\..+$/"
                 ng-model="vm.ajuntament.email" ng-disabled="!vm.edit"/>
          <div ng-messages="editForm.email.$error" role="alert">
            <div ng-message-exp="['required', 'pattern']">{{'EMAIL_INVALID_FORMAT' | translate}}</div>
          </div>
        </md-input-container>


        <md-input-container class="md-block">
          <md-icon md-svg-src="images/ic_web_black_48px.svg"></md-icon>
          <label>{{'WEB' | translate}}</label>
          <input name="web" type="text" ng-model="vm.ajuntament.web" ng-disabled="!vm.edit"/>
        </md-input-container>
      </div>

      <div flex-gt-xs="45" layout="column">
        <md-input-container class="md-block">
          <label>{{'ADDRESS' | translate}}</label>
          <md-icon md-svg-src="images/ic_location_black_48px.svg"></md-icon>
          <input name="address" type="text" ng-model="vm.ajuntament.address" ng-disabled="!vm.edit" required/>
          <div ng-messages="editForm.address.$error" role="alert">
            <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
          </div>
        </md-input-container>

        <div flex layout="row">
          <md-input-container class="md-block" flex="75">
            <label>{{'TOWN' | translate}}</label>
            <md-icon md-svg-src="images/ic_location_city_black_48px.svg"></md-icon>
            <md-select ng-model="vm.ajuntament.idTown" ng-disabled="!vm.edit" required name="towns">
              <md-option ng-value="town.id" ng-repeat="town in vm.towns">{{ town.name }}</md-option>
            </md-select>
            <div ng-messages="editForm.towns.$error" role="alert">
              <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
            </div>
          </md-input-container>
          <md-input-container class="md-block" flex>
            <label>{{'POSTAL_CODE' | translate}}</label>
            <input type="text" name="zipCode" ng-model="vm.ajuntament.zipCode"
                   ng-pattern="/^[0-9]{5}$/" md-maxlength="5" ng-disabled="!vm.edit" required/>
            <div ng-messages="editForm.zipCode.$error" role="alert">
              <div ng-message="md-maxlength">{{'MAXIMUM_CHARACTERS_POSTAL_CODE' | translate}}</div>
              <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
            </div>
          </md-input-container>
        </div>
        <div flex layout="row">
          <md-input-container class="md-block">
            <label>{{'PHONE' | translate}}</label>
            <md-icon md-svg-src="images/ic_phone_black_48px.svg"></md-icon>
            <input name="phoneNumber" type="tel"
                   ng-pattern="/^[+]{0,1}[0-9]+$/"
                   ng-model="vm.ajuntament.phoneNumber" ng-disabled="!vm.edit"/>
            <div ng-messages="editForm.phoneNumber.$error" role="alert">
              <div ng-message="pattern">{{'INVALID_PHONE_NUMBER' | translate}}</div>
            </div>
          </md-input-container>
          <md-input-container class="md-block">
            <label>{{'FAX' | translate}}</label>
            <input name="fax" type="tel"
                   ng-pattern="/^[+]{0,1}[0-9]+$/"
                   ng-model="vm.ajuntament.fax" ng-disabled="!vm.edit"/>
            <div ng-messages="ajuntamentForm.fax.$error" role="alert">
              <div ng-message="pattern">{{'INVALID_FAX_NUMBER' | translate}}</div>
            </div>
          </md-input-container>
        </div>

        <!-- Xarxes socials informació -->
        <div flex layout="row" ng-if="!vm.edit">
          <md-button ng-if="vm.ajuntament.facebook" class="md-flat" aria-label="Facebook"
                     href="https://www.facebook.com/{{vm.ajuntament.facebook}}" target="_blank">
            <md-icon md-svg-src="images/if_facebook_circle_color_107175.svg" aria-label="Facebook"></md-icon>
          </md-button>
          <md-button ng-if="vm.ajuntament.twitter" class="md-flat" aria-label="Twitter"
                     href="https://twitter.com/{{vm.ajuntament.twitter}}" target="_blank">
            <md-icon md-svg-src="images/if_twitter_circle_color_107170.svg" aria-label="Twitter"></md-icon>
          </md-button>
          <md-button ng-if="vm.ajuntament.instagram" class="md-flat" aria-label="Instagram"
                     href="https://www.instagram.com/{{vm.ajuntament.instagram}}" target="_blank">
            <md-icon md-svg-src="images/if_instagram_2362135.svg" aria-label="Instagram"></md-icon>
          </md-button>
        </div>

        <!-- Xarxes socials edició -->
        <div flex layout="row" ng-if="vm.edit">
          <md-input-container class="md-block">
            <label>Facebook</label>
            <md-icon md-svg-src="images/if_facebook_circle_color_107175.svg" aria-label="Facebook"></md-icon>
            <input name="facbook" type="text"
                   ng-model="vm.ajuntament.facebook"/>
          </md-input-container>
          <md-input-container class="md-block">
            <label>Twitter</label>
            <md-icon md-svg-src="images/if_twitter_circle_color_107170.svg" aria-label="Twitter"></md-icon>
            <input name="twitter" type="text"
                   ng-model="vm.ajuntament.twitter"/>
          </md-input-container>
          <md-input-container class="md-block">
            <label>Instagram</label>
            <md-icon md-svg-src="images/if_instagram_2362135.svg" aria-label="Instagram"></md-icon>
            <input name="instagram" type="text"
                   ng-model="vm.ajuntament.instagram"/>
          </md-input-container>
        </div>

      </div>
    </div>
  </div>
  <md-button type="submit" class="md-fab md-accent md-fab-bottom-right" ng-if="vm.edit"
             ng-disabled="editForm.$invalid">
    <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
  </md-button>
</form>

