<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <style>

* {
	border: 0;
	box-sizing: content-box;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	font-style: inherit;
	font-weight: inherit;
	line-height: inherit;
	list-style: none;
	margin: 0;
	padding: 0;
	text-decoration: none;
	vertical-align: top;
}

/* content editable */

*[contenteditable] { border-radius: 0.25em; min-width: 1em; outline: 0; }

*[contenteditable] { cursor: pointer; }

*[contenteditable]:hover, *[contenteditable]:focus, td:hover *[contenteditable], td:focus *[contenteditable], img.hover { background: #DEF; box-shadow: 0 0 1em 0.5em #DEF; }

span[contenteditable] { display: inline-block; }

/* heading */

h1 { font: bold 100% sans-serif; letter-spacing: 0.5em; text-align: center; text-transform: uppercase; }

/* table */

table { font-size: 75%; table-layout: fixed; width: 100%; }
table { border-collapse: separate; border-spacing: 2px; }
th, td { border-width: 1px; padding: 0.5em; position: relative; text-align: left; }
th, td { border-radius: 0.25em; border-style: solid; }
th { background: #EEE; border-color: #BBB; }
td { border-color: #DDD; }

/* page */

html { font: 16px/1 'Open Sans', sans-serif; overflow: auto; padding: 0.5in; }
html { background: #999; cursor: default; }

body { box-sizing: border-box; height: 11in; margin: 0 auto; overflow: hidden; padding: 0.5in; width: 8.5in; }
body { background: #FFF; border-radius: 1px; box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5); }

/* header */

header { margin: 0 0 3em; }
header:after { clear: both; content: ""; display: table; }

header h1 { background: #000; border-radius: 0.25em; color: #FFF; margin: 0 0 1em; padding: 0.5em 0; }
header address { float: left; font-size: 75%; font-style: normal; line-height: 1.25; margin: 0 1em 1em 0; }
header address p { margin: 0 0 0.25em; }
header span, header img { display: block; float: right; }
header span { margin: 0 0 1em 1em; max-height: 25%; max-width: 60%; position: relative; }
header img { max-height: 100%; max-width: 100%; }
header input { cursor: pointer; -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; height: 100%; left: 0; opacity: 0; position: absolute; top: 0; width: 100%; }

/* article */

article, article address, table.meta, table.inventory { margin: 0 0 3em; }
article:after { clear: both; content: ""; display: table; }
article h1 { clip: rect(0 0 0 0); position: absolute; }

article address { float: left; font-size: 100%; font-weight: normal; }

/* table meta & balance */

table.meta, table.balance { float: right; width: 36%; }
table.meta:after, table.balance:after { clear: both; content: ""; display: table; }

/* table meta */

table.meta th { width: 60%; }
table.meta td { width: 40%; }

/* table items */

table.inventory { clear: both; width: 100%; }
/*"table.inventory th { font-weight: bold; text-align: center; }"+*/

table.inventory th:nth-child(1) { text-align:  center;width: 64%;font-weight: bold;  }
table.inventory th:nth-child(2) { text-align:  center; width: 12%; font-weight: bold; }
table.inventory th:nth-child(3) { text-align:  center; width: 12%;font-weight: bold;  }
table.inventory th:nth-child(4) { text-align:  center; width: 12%;font-weight: bold;  }


table.inventory td:nth-child(1) { width: 64%; }
table.inventory td:nth-child(2) { text-align: right; width: 12%; }
table.inventory td:nth-child(3) { text-align: right; width: 12%; }
table.inventory td:nth-child(4) { text-align: right; width: 12%; }

/* table balance */

table.balance th, table.balance td { width: 50%; }
table.balance td { text-align: right; }

/* aside */

aside h1 { border: none; border-width: 0 0 1px; margin: 0 0 1em; }
aside h1 { border-color: #999; border-bottom-style: solid; }

/* javascript */

.add, .cut
{
	border-width: 1px;
	display: block;
	font-size: .8rem;
	padding: 0.25em 0.5em;	
	float: left;
	text-align: center;
	width: 0.6em;
}

.add, .cut
{
	background: #9AF;
	box-shadow: 0 1px 2px rgba(0,0,0,0.2);
	background-image: -moz-linear-gradient(#00ADEE 5%, #0078A5 100%);
	background-image: -webkit-linear-gradient(#00ADEE 5%, #0078A5 100%);
	border-radius: 0.5em;
	border-color: #0076A3;
	color: #FFF;
	cursor: pointer;
	font-weight: bold;
	text-shadow: 0 -1px 2px rgba(0,0,0,0.333);
}

.add { margin: -2.5em 0 0; }

.add:hover { background: #00ADEE; }

.cut { opacity: 0; position: absolute; top: 0; left: -1.5em; }
.cut { -webkit-transition: opacity 100ms ease-in; }

tr:hover .cut { opacity: 1; }

@media print {
	* { -webkit-print-color-adjust: exact; }
	html { background: none; padding: 0; }
	body { box-shadow: none; margin: 0; }
	span:empty { display: none; }
	.add, .cut { display: none; }
}

@page { margin: 0; }

</style>
</head>

<body>
    <div id="divaprint" ng-show="false">
    <header>
			<h1>Factura</h1>
                        <p><u>Dades de l'emissor</u></p>
                        <br>
			<address contenteditable>
                            
				<p>persona2.name persona2.surname1 persona2.surname2</p>
				<p>persona2.dni</p>
				<p>persona2.adress</p>
                                <p>persona2.poblacio persona2.cp</p>
			</address>
			<span></span>
		</header>
    	<article>
			   <p><u>Dades del preceptor</u></p>
                        <br>
			<address contenteditable>
				<p>rece2.nombre
                                    <br>rece2.direccion
                                    <br>rece2.direccion2
                                <br>rece2.nif
                                <br>rece2.telf</p>
			</address>
			<table class="meta">
				<tr>
					<th><span contenteditable>Factura número</span></th>
					<td><span contenteditable>invoice.numinvoice</span></td>
				</tr>
				<tr>
					<th><span contenteditable>Data</span></th>
					<td><span contenteditable>invoice.data</span></td>
				/</tr>
				<tr>
					<th><span contenteditable>Total</span></th>
					<td><span>invoice.total</span><span id="prefix" contenteditable> €</span></td>
				</tr>
			</table>
			<table class="inventory">
				<thead>
					<tr>
						<th><span contenteditable>Acció</span></th>
						
						<th><span contenteditable>Partit</span></th>
						<th><span contenteditable>€/Partit</span></th>
						<th><span contenteditable>Total</span></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td><a class="cut">-</a><span contenteditable>linea.concepte0</span></td>
						
                                                <td><span contenteditable>linea.partits0</span></td>
						<td><span contenteditable>linea.preu0</span><span data-prefix> €</span></td>
						<td><span>linea.total0</span><span data-prefix> €</span></td>
					</tr>
				</tbody>
			</table>
			<a class="add">+</a>
			<table class="balance">
				<tr>
					<th><span contenteditable>Subtotal</span></th>
					<td><span>invoice.base</span><span data-prefix> €</span></td>
				</tr>
				<tr>
					<th><span contenteditable>I.R.P.F. -15%</span></th>
					<td><span contenteditable>invoice.irpf</span><span data-prefix> €</span></td>
				</tr>
				<tr>
					<th><span contenteditable>TOTAL</span></th>
					<td><span>invoice.total</span><span data-prefix> €</span></td>
				</tr>
			</table>
		</article>
    
    	<aside>
			<h1><span contenteditable>Forma de pagament</span></h1>
			<div contenteditable>
				<p>Tranferència Bancària: persona2.iban</p>
			</div>
		</aside>
        </div>

<br><br><br><br><br><br><br><br>
<p style="font-size:60%;">
    D'acord amb el que estableix la Llei Orgànica 3/2018 LOPD-GDD i la Normativa Europea 2016/679, RGPD l'informem que les dades personals recollides en aquest document seran incorporades a un tractament informatitzat sota la responsabilitat de Consell Esportiu del Baix Llobregat. Pot exercir els seus drets d'accés, rectificació, supressió, oposició, limitació i portabilitat a: Parc Torreblanca, Ctra. Nacional 340 km. 1249, 08980, Barcelona.
</p>
</body>
</html>
