<div layout="row" layout-align="space-around center">
  <form ng-submit="vm.applyFilters()" name="listFilters" layout="row" flex-gt-md="70" flex="100"
        layout-align="space-around center">
    <md-autocomplete flex="50" ng-repeat="filter in vm.filters"
                     md-selected-item="filter.selectedItem"
                     md-search-text="filter.searchText"
                     md-items="option in filter.options | filterAccents: filter.searchText"
                     md-item-text="option[filter.showField]"
                     md-min-length="0"
                     md-require-match
                     ng-required="filter.required"
                     ng-disabled="filter.options.length === 1 || vm.isDisabledFilter(filter.dependsOn)"
                     md-clear-button="true"
                     md-floating-label="{{filter.label | translate}}">
      <md-item-template>
        <span md-highlight-text="filter.searchText" md-highlight-flags="^i">{{option[filter.showField]}}</span>
      </md-item-template>
      <md-not-found>
        {{'RESULT_NOT_FOUND' | translate}}
      </md-not-found>
    </md-autocomplete>
    <md-button type="submit" ng-disabled="vm.isDisabledFilterButton || listFilters.$invalid"
               ng-if="vm.filters && vm.filterFunction"
               class="md-raised md-primary">Filtrar
    </md-button>
  </form>
</div>

<div layout="row" layout-align="space-around center" ng-if="!vm.showList" flex>
  <h4>{{"YOU_NEED_TO_FILTER"| translate}}</h4>
</div>


<md-content layout="column" flex-gt-md="30" flex="70" style="margin: auto" ng-hide="!vm.values" ng-if="vm.showList">
  <md-input-container>
    <md-icon md-svg-src="images/ic_search_black_48px.svg" class="s24" aria-label="search"></md-icon>
    <input ng-model="vm.searchText" placeholder="Buscar">
  </md-input-container>
</md-content>


<div layout="row" layout-align="space-around center" ng-if="vm.showList">

  <md-card flex-gt-md="{{vm.cardWidth}}" flex="100">

    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline">{{vm.title | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>

      <md-table-container>
        <table md-table md-row-select="true" ng-model="vm.selected">
          <thead md-head md-order="vm.defaultOrderBy">
          <tr md-row ng-show="vm.filteredListHasSomeItem()">
            <th ng-repeat="header in vm.headers" md-column mdDesc="{{header.orderDesc}}"
                md-order-by="{{header.canOrder ? (header.orderBy || header.id) : undefined}}">
              <span class="md-title">{{header.name | translate}}</span>
            </th>
          </tr>
          </thead>
          <tbody md-body>
          <tr md-row md-select="vm.select" ng-style="{'cursor' : vm.cursor}" ui-sref="{{vm.getStateLink(value)}}"
              ng-repeat="value in vm.filteredValues = (vm.values | filterAccents: vm.searchText) | orderBy: vm.defaultOrderBy">
            <td md-cell ng-repeat="header in vm.headers"><span class="md-body-1">{{value[header.id] | translate}}</span></td>
          </tr>
          </tbody>
        </table>
      </md-table-container>
      <span class="md-body-2" ng-hide="vm.filteredListHasSomeItem()">{{vm.notFoundMessage | translate}}</span>

    </md-card-content>

  </md-card>
</div>
