<div layout="column" ui-view>
 
  <div layout="row" layout-align="space-around center">
    <md-card flex-gt-md="80" flex="100">

      <div class="md-whiteframe-1dp" flex>
        <md-card-header layout-padding>
          <md-card-header-text>
            <span class="md-headline">SANCIONS</span>
          </md-card-header-text>
        </md-card-header>
           <md-button ng-click="exportToExcel('table2', 'Report')">
                 <md-icon md-svg-src = '../../images/notebook.svg'></md-icon>
            </md-button> 
          
      </div>
      <md-card-content>
        <span class="md-body-2" ng-if="vm.sancions.length === 0">{{'CHAMPIONSHIPS.MATCHES_NOT_FOUND' | translate}}</span>
        <md-table-container ng-if="vm.sancions.length > 0">
          <table md-table md-row-select="true" ng-model="vm.selected"  id="table2">
            <thead md-head >
            <tr md-row>
              <th md-column md-order-by="idEquipo">idEquip</th>
              <th md-column md-order-by="Equipo">Equip</th>
              <th md-column md-order-by="Campeonato">Campionat</th>
              <th md-column md-order-by="sancionAplicada">Sanció aplicada</th>
              <th md-column md-order-by="sancion">Sanció</th>
              <th md-column md-order-by="articulo">Article</th>
              <th md-column md-order-by="descalificado">Descalificat</th>
              <th md-column md-order-by="descalificacionAplicada">Descalificació aplicada</th>
               <th md-column md-order-by="sanciondes">Sanció descalificacio</th>
                 <th md-column md-order-by="insercion">Data inserció</th>
                    <th md-column md-order-by="idPartido">idPartit</th>
                       <th md-column md-order-by="fecha_hora">Data partit</th>
                          <th md-column md-order-by="local">Equip local</th>
                 <th md-column md-order-by="visitant">Equip visitant</th>
                 <th md-column md-order-by="poblacio">Població</th>
            </tr>
            </thead>
            <tbody md-body>
            <tr md-row md-select="vm.select" ng-repeat="sancio in vm.sancions">
              <td md-cell>{{sancio.idEquipo}}</td>
              <td md-cell>{{sancio.equipo}}</td>
              <td md-cell>{{sancio.campeonato}}</td>
              <td md-cell>{{sancio.sancionAplicada}}</td>
              <td md-cell>{{sancio.sancion}}.</td>
              <td md-cell>{{sancio.articulo}}</td>
              <td md-cell>{{sancio.descalificaddo}}</td>
             
               <td md-cell>{{sancio.descalificacionAplicada}}</td>
                <td md-cell>{{sancio.sancionDescalificado}}</td>
                  <td md-cell>{{sancio.insercion}}</td>
                   <td md-cell>{{sancio.idPartido}}</td>
                    <td md-cell>{{sancio.fecha_hora}}</td>
                     <td md-cell>{{sancio.local}}</td>
                    <td md-cell>{{sancio.visitant}}</td>
                     <td md-cell>{{sancio.poblacio}}</td>
            </tr>
            </tbody>
          </table>
        </md-table-container>
      
      </md-card-content>
    </md-card>
  </div>
</div>

