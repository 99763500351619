<div layout="row" layout-align="space-around center">

  <md-card flex-gt-md="70" flex="100">
    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline">{{'NEW_ENTITY_UPLOAD' | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>
      <div layout-align="center center" layout="column">
        <form name="entitatForm" ng-submit="vm.addEntitat()">
          <div layout="column">
            <div>
              <h1 class="md-title">{{'SELECT_TOWNHALL_AND_TYPE' | translate}}</h1>
              <md-divider></md-divider>
              <div layout="row" layout-align="space-around center" layout-padding>
                <md-input-container class="md-block" flex="40">
                  <label>{{'TOWN_HALL' | translate}}</label>
                  <md-icon md-svg-src="images/ic_location_city_black_48px.svg"></md-icon>
                  <md-select name="ajuntament" ng-model="vm.entitat.townHall" ng-required="true"
                             ng-disabled="vm.onyOneTownHall">
                    <md-option ng-value="townHall" ng-repeat="townHall in vm.townHalls">{{ townHall.name }}
                    </md-option>
                  </md-select>
                  <div ng-messages="entitatForm.ajuntament.$error" role="alert">
                    <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                  </div>
                </md-input-container>
                <md-input-container class="md-block" flex="40">
                  <label>{{'ENTITY_TYPE' | translate}}</label>
                  <md-select name="entityType" ng-model="vm.entitat.typeId" ng-required="true">
                    <md-option ng-value="type.id" ng-repeat="type in vm.entityTypes">{{ 'ENTITY_TYPES.' + type.name |
                      translate }}
                    </md-option>
                  </md-select>
                  <div ng-messages="entitatForm.entityType.$error" role="alert">
                    <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                  </div>
                </md-input-container>
              </div>
              <h1 class="md-title">{{'NEW_ENTITY_DATA' | translate}}</h1>
              <md-divider></md-divider>
              <div layout-xs="column" layout-gt-xs="row" layout-align="space-around center">

                <div flex="40" layout="column">
                  <md-input-container>
                    <label>{{'NAME' | translate}}</label>
                    <md-icon md-svg-src="images/ic_title_black_48px.svg"></md-icon>
                    <input title="name" type="text" required ng-model="vm.entitat.name"/>
                    <div ng-messages="entitatForm.name.$error" role="alert">
                      <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                    </div>
                  </md-input-container>
                  <md-input-container class="md-block">
                    <label>{{'EMAIL' | translate}}</label>
                    <md-icon md-svg-src="images/ic_email_black_48px.svg"></md-icon>
                    <input name="email" type="email" required ng-pattern="/^.+@.+\..+$/"
                           ng-model="vm.entitat.email"/>
                    <div ng-messages="entitatForm.email.$error" role="alert">
                      <div ng-message-exp="['required', 'pattern']">
                        {{'EMAIL_INVALID_FORMAT' | translate}}
                      </div>
                    </div>
                  </md-input-container>
                  <div flex layout="row">
                    <md-input-container class="md-block">
                      <label>CCE</label>
                      <input name="cce" type="text" ng-model="vm.entitat.cce"/>
                    </md-input-container>
                    <md-input-container>
                      <label>NIF</label>
                      <input name="nif" type="text"
                             ng-pattern="/^(\d{8})([A-Z])$|^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$|^[KLMXYZ]\d{7,8}[A-Z]$/"
                             ng-model="vm.entitat.nif"/>
                      <div ng-messages="entitatForm.nif.$error" role="alert">
                        <div ng-message="pattern">{{'INVALID_NID' | translate}}</div>
                      </div>
                    </md-input-container>
                  </div>
                      <div flex layout="row">
                        <md-input-container class="md-block">
                      <label>{{'NAME_CONTACT' | translate}}</label>
                      <md-icon md-svg-src="images/ic_person_black_48px.svg"></md-icon>
                      <input name="personName" type="text"

                             ng-model="vm.entitat.pname1"/>

                    </md-input-container>
                  </div>
                   <div flex layout="row">
                        <md-input-container class="md-block">
                      <md-icon md-svg-src="images/ic_web_black_48px.svg"></md-icon>
                      <label>{{'WEB_PAGE' | translate}}</label>
                      <input name="web" type="text" ng-model="vm.entitat.web"/>
                    </md-input-container>
                  </div>
                </div>
                <div flex-gt-xs="45" flex layout="column">
                  <md-input-container class="md-block">
                    <label>{{'ADDRESS' | translate}}</label>
                    <md-icon md-svg-src="images/ic_location_black_48px.svg"></md-icon>
                    <input name="address" type="text" ng-model="vm.entitat.address"/>
                  </md-input-container>
                  <div flex layout="row">
                    <md-input-container class="md-block" flex="75">
                      <label>{{'TOWN' | translate}}</label>
                      <md-icon md-svg-src="images/ic_location_city_black_48px.svg"></md-icon>
                        <md-select ng-model="vm.entitat.town" required>
                        <md-option ng-value="town.id" ng-repeat="town in vm.towns">{{ town.name }}
                        </md-option>
                      </md-select>
                    </md-input-container>
                    <md-input-container class="md-block" flex="25">
                      <label>{{'POSTAL_CODE' | translate}}</label>
                      <input name="zipCode" type="text" name="zipCode" ng-model="vm.entitat.zipCode"
                             ng-pattern="/^[0-9]{5}$/" md-maxlength="5">
                      <div ng-messages="entitatForm.zipCode.$error" role="alert">
                        <div ng-message="md-maxlength">{{'MAXIMUM_CHARACTERS_POSTAL_CODE' | translate}}</div>
                      </div>
                    </md-input-container>
                  </div>
                  <div flex layout="row">
                    <md-input-container class="md-block">
                      <label>{{'MOBILE_PHONE' | translate}}</label>
                      <input name="mobileNumber" type="tel"
                             ng-pattern="/^[+]{0,1}[0-9]+$/"
                             ng-model="vm.entitat.mobileNumber"/>
                      <div ng-messages="entitatForm.mobileNumber.$error" role="alert">
                        <div ng-message="pattern">{{'INVALID_PHONE_NUMBER' | translate}}</div>
                      </div>
                    </md-input-container>
                    <md-input-container class="md-block">
                      <label>{{'FAX' | translate}}</label>
                      <input name="fax" type="tel"
                             ng-pattern="/^[+]{0,1}[0-9]+$/"
                             ng-model="vm.entitat.fax"/>
                      <div ng-messages="entitatForm.fax.$error" role="alert">
                        <div ng-message="pattern">{{'INVALID_FAX_NUMBER' | translate}}</div>
                      </div>
                    </md-input-container>
                  </div>
                      <div flex layout="row">

                    <md-input-container class="md-block">
                      <label>{{'PHONE' | translate}}</label>
                      <md-icon md-svg-src="images/ic_phone_black_48px.svg"></md-icon>
                      <input name="phoneNumber" type="tel"
                             ng-pattern="/^[+]{0,1}[0-9]+$/"
                             ng-model="vm.entitat.phoneNumber"/>
                      <div ng-messages="entitatForm.phoneNumber.$error" role="alert">
                        <div ng-message="pattern">{{'INVALID_PHONE_NUMBER' | translate}}</div>
                      </div>
                    </md-input-container>
                       </div>
                  <div flex layout="row">
                    <md-input-container class="md-block">
                      <label>Facebook</label>
                      <md-icon md-svg-src="images/if_facebook_circle_color_107175.svg" aria-label="Facebook"></md-icon>
                      <input name="facebook" type="text"
                             ng-model="vm.entitat.facebook"/>
                    </md-input-container>
                    <md-input-container class="md-block">
                      <label>Twitter</label>
                      <md-icon md-svg-src="images/if_twitter_circle_color_107170.svg" aria-label="Twitter"></md-icon>
                      <input name="twitter" type="text"
                             ng-model="vm.entitat.twitter"/>
                    </md-input-container>
                    <md-input-container class="md-block">
                      <label>Instagram</label>
                      <md-icon md-svg-src="images/if_instagram_2362135.svg" aria-label="Instagram"></md-icon>
                      <input name="instagram" type="text"
                             ng-model="vm.entitat.instagram"/>
                    </md-input-container>
                  </div>


                </div>
              </div>
            </div>
          </div>

          <div layout="column" layout-padding>
            <div>
              <h1 class="md-title">{{'USER_DATA' | translate}}</h1>
              <md-divider></md-divider>
              <div layout="row" layout-align="space-around left">
                <div flex="40" layout="column" layout-padding>
                  <md-input-container class="md-block">
                    <label>{{'ACCESS_EMAIL' | translate}}</label>
                    <md-icon md-svg-src="images/ic_email_black_48px.svg"></md-icon>
                    <input name="username" type="email" required ng-pattern="/^.+@.+\..+$/"
                           ng-model="vm.entitat.username"/>
                    <div ng-messages="entitatForm.username.$error" role="alert">
                      <div ng-message-exp="['required', 'pattern']">
                        {{'EMAIL_INVALID_FORMAT' | translate}}
                      </div>
                    </div>
                  </md-input-container>
                </div>
                <div flex-gt-xs="45" flex layout="column"></div>
              </div>
            </div>
          </div>
          <div flex>
            <md-button class="md-fab md-accent md-fab-bottom-right" ng-disabled="entitatForm.$invalid"
                       type="submit">
              <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
              <md-tooltip md-delay="50" md-direction="left">{{'SAVE' | translate}}</md-tooltip>
            </md-button>
          </div>
        </form>
      </div>
    </md-card-content>
  </md-card>
</div>
