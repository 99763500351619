<div layout="row" layout-align="space-around center">
  <md-card flex-sm="90" flex-lg="50" flex-md="65">
    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline">{{vm.cardTitle | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>
      <form name="restorePasswordForm" ng-submit="vm.sendPassword()">
        <div layout="column" layout-align="center stretch" class="horizontal-padding" flex-gt-md="70"
             flex-offset-gt-md="15">
          <md-input-container>
            <md-icon md-svg-src="images/ic_lock_black_48px.svg"></md-icon>
            <label>{{'PASSWORD' | translate}}</label>
            <input type="password" name="password" ng-model="vm.pwd" ng-required="true" ng-pattern="/^.{4,}?/">
            <div ng-messages="restorePasswordForm.password.$error" role="alert">
              <div ng-message="pattern">
                {{'PASSWORD_INVALID_FORMAT' | translate}}
              </div>
            </div>
          </md-input-container>

          <md-input-container>
            <md-icon md-svg-src="images/ic_lock_black_48px.svg"></md-icon>
            <label>{{'REPEAT_PASSWORD' | translate}}</label>
            <input type="password" name="password2" ng-model="vm.pwd2" ng-required="true" ng-pattern="vm.pwd">
            <div ng-messages="restorePasswordForm.password2.$error" role="alert">
              <div ng-message="pattern">
                {{'PASSWORD_INVALID_MATCH' | translate}}
              </div>
            </div>
          </md-input-container>

        </div>
        <div layout="column" layout-align="center end" flex>
          <md-button class="md-raised md-primary" ng-disabled="restorePasswordForm.$invalid" type="submit">
            <md-icon md-svg-icon="images/ic_exit_white_48px.svg"></md-icon>
            {{'REGISTER' | translate}}
          </md-button>
        </div>
      </form>
    </md-card-content>
  </md-card>
</div>
