
     <md-card>
      <md-card-content>
      <div layout-align="center center" layout="row" flex>

        <form name="refereeForm" ng-submit="vm.updatePerson()" flex>
          <div layout="column" layout-padding flex>
            <div layout-gt-sm="row" layout-align="space-around center">
              <div layout-gt-sm="row" flex="45">

                <neo-input name="name" label="NAME" icon="images/ic_person_black_48px.svg" 
                           ng-model="vm.person.name" flex="45" layout-gt-sm="column" is-disabled="true"></neo-input>

                <neo-input name="surname" label="SURNAME" icon="images/ic_person_black_48px.svg" 
                           ng-model="vm.person.surname" flex="50"
                           layout-gt-sm="column" is-disabled="!vm.edit"></neo-input>
              </div>
              <div layout-gt-sm="row" flex="45">

                <neo-input name="secondSurname" label="SECOND_SURNAME" ng-model="vm.person.secondSurname" flex="50"
                           layout-gt-sm="column" is-disabled="!vm.edit"></neo-input>

                <md-input-container class="md-block" flex="50">
                  <label>{{'BIRTH_DATE' | translate}}</label>
                  <md-datepicker name="birthday" ng-model="vm.person.bornDate" md-current-view="year"
                                 ng-disabled="!vm.edit" flex="100"
                                 md-max-date="vm.today"></md-datepicker>
                  <div ng-messages="refereeForm.birthday.$error" role="alert">
                    <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                    <div ng-message="valid">{{'DATE_INVALID_FORMAT' | translate}}</div>
                  </div>
                </md-input-container>
              </div>
            </div>
              
                </div>
        </form>
      </div>
    </md-card-content>
  </md-card>

              
              
   <div layout="row" layout-align="space-around center" ng-if="true">

  <md-card flex-gt-md="80" flex="100">

    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline">{{vm.title | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>

      <md-table-container>
        <table md-table md-row-select="true" ng-model="vm.selected">
          <thead md-head md-order="vm.defaultOrderBy">
          <tr md-row ng-show="true">
            <th ng-repeat="header in vm.headers" md-column>
              <span class="md-body-1">{{header.name | translate}}</span>
            </th>
          </tr>
          </thead>
          <tbody md-body>
          <tr md-row md-select="vm.select" ng-style="{'cursor' : vm.cursor} " ui-sref="home.line({licenceId: vm.refId, lineId:value.id})"
              ng-repeat="value in vm.filteredValues = (vm.values | filterAccents: vm.searchText) | orderBy: vm.defaultOrderBy">
            <td md-cell ng-repeat="header in vm.headers"><span class="md-body-1">{{value[header.id] | translate}}</span></td>
          </tr>
          </tbody>

        </table>
      </md-table-container>
      <span class="md-body-2" ng-hide="vm.filteredListHasSomeItem()">{{vm.notFoundMessage | translate}}</span>

    </md-card-content>

  </md-card>
       <neo-action-button buttons="vm.buttons" ng-if="vm.buttons.actions.length > 0"></neo-action-button>
</div>


