<div layout="row" layout="column"       layout-align="center center">
    <md-card flex-gt-md="60" flex="100">
      <div class="md-whiteframe-1dp" flex>
          <md-card-header layout-padding style="background-color:#7F8FB1;color: white">
                <md-card-header-text>
            <span class="md-body-1" >{{vm.campionat}}</span>
          </md-card-header-text>
                <md-card-header-text style="text-align:right">
            <span class="md-headline">{{vm.suspes}}</span>
          </md-card-header-text>
              </md-card-header>
          
        <md-card-header layout-padding style="background-color:#D2E0E6;color: black">
        
              <md-card-header-text>
            <span class="md-body-1">Data</span>
          </md-card-header-text>
             <md-card-header-text>
            <span class="md-body-1">Equip local</span>
          </md-card-header-text>
             <md-card-header-text>
            <span class="md-body-1">Equip visitant</span>
          </md-card-header-text>
             <md-card-header-text>
            <span class="md-body-1">C.C.</span>
          </md-card-header-text>
           
        </md-card-header>
          
     
         
        <md-card-header layout-padding>
        
              <md-card-header-text>
            <span class="md-body-1">{{vm.data}}</span>
          </md-card-header-text>
             <md-card-header-text>
            <span class="md-body-1">{{vm.elocal}}</span>
          </md-card-header-text>
             <md-card-header-text>
            <span class="md-body-1">{{vm.evisitant}}</span>
          </md-card-header-text>
             <md-card-header-text>
            <md-checkbox ng-model="vm.cc" aria-label="Checkbox 1">
            
          </md-checkbox>
          </md-card-header-text>
            
        </md-card-header>
            <md-card-header layout-padding>
                 <md-card-header-text>
            <span class="md-body-1">{{perfilnom}}</span>
          </md-card-header-text>
                <md-card-header-text>
            <span class="md-body-1">{{equipnom}}</span>
          </md-card-header-text>
                      <md-card-header-text>
            <span class="md-body-1"></span>
          </md-card-header-text>
            </md-card-header>
          <md-card-content>
  <md-table-container>
      <table md-table md-row-select="true" >
          <tr md-row md-select="vm.select">
                <td md-cell>{{item1.iditem}}</td>
               <td md-cell>{{item1.nom}}</td>
              <td md-cell>{{item1.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select1"  ng-init="select1 =vm.init1" style=";color: black"  >
                      <option  ng-repeat="opcion1 in opcions1"  >{{opcion1.name}}<option>
                  </select></td>
          </tr>
            <tr md-row md-select="vm.select">
                <td md-cell>{{item2.iditem}}</td>
               <td md-cell>{{item2.nom}}</td>
              <td md-cell>{{item2.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select2"  ng-init="select2 =vm.init2" style=";color: black"  >
                      <option  ng-repeat="opcion2 in opcions2" >{{opcion2.name}}<option>
                  </select></td>
          </tr>
          
          
           <tr md-row md-select="vm.select">
                <td md-cell>{{item3.iditem}}</td>
               <td md-cell>{{item3.nom}}</td>
              <td md-cell>{{item3.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select3" ng-init="select3 =vm.init3" style=";color: black"  >
                      <option  ng-repeat="opcion3 in opcions3" >{{opcion3.name}}<option>
                  </select></td>
          </tr>
          
      </table>
  </md-table-container>
       
          </md-card-content>
          
      </div>
    </md-card>
    </div>
<md-button type="submit" class="md-fab md-accent md-fab-bottom-right"  ng-click="vm.click()"
             ng-disabled="editForm.$invalid">
    <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
  </md-button>