<div layout="column">
  <div layout="row" layout-align="space-around center" layout-padding>
    <form name="listFilters" layout="row" flex-gt-md="70" flex="100" layout-align="space-around center">
      <md-autocomplete flex="40" flex-gt-md="50" md-selected-item="vm.selectedTownHall"
                       md-search-text="vm.searchText"
                       md-items="townhall in vm.townhalls | filterAccents: vm.searchText"
                       md-item-text="townhall.name"
                       md-require-match
                       md-min-length="0"
                       ng-required="true"
                       ng-disabled="vm.townhalls.length === 1"
                       md-clear-button="true"
                       md-floating-label="{{'SELECT_TOWNHALL_TO_FILTER' | translate}}" style="padding-top: 15px">
        <md-item-template>
          <span md-highlight-text="vm.searchText" md-highlight-flags="^i">{{townhall.name}}</span>
        </md-item-template>
        <md-not-found>
          {{'RESULT_NOT_FOUND' | translate}}
        </md-not-found>
      </md-autocomplete>
      <md-datepicker md-current-view="month" ng-required="true" ng-model="vm.initDate"
                     md-placeholder="{{'INI_DATE' | translate}}" md-max-date="vm.endDate"></md-datepicker>
      <md-datepicker md-current-view="month" ng-required="true" ng-model="vm.endDate"
                     md-placeholder="{{'END_DATE' | translate}}" md-min-date="vm.initDate"
                     ng-disabled="vm.initDate === null"></md-datepicker>
      <md-button ng-disabled="listFilters.$invalid" class="md-raised md-primary" ng-click="vm.applyFilters()">
        {{'FILTER' | translate}}
      </md-button>
    </form>
  </div>
  <div layout="row" layout-align="space-around center" ng-show="vm.isFiltered">
    <md-card flex-gt-md="95" flex="100">

      <div class="md-whiteframe-1dp" flex>
        <md-card-header layout-padding>
          <md-card-header-text>
            <span class="md-headline">{{'POSTPONEMENTS.POSTPONED_MATCHES' | translate}}</span>
          </md-card-header-text>
        </md-card-header>
      </div>
      <md-card-content>
        <span class="md-body-2" ng-hide="vm.showList">{{'POSTPONEMENTS.NOT_FOUND_POSTPONEMENTS' | translate}}</span>
        <md-table-container ng-show="vm.showList">
          <table md-table md-row-select="false" ng-model="vm.selected" md-progress="vm.promiseMatches">
            <thead md-head md-order="vm.queryOrder" md-on-reorder="vm.reorder">
            <tr md-row>
              <th md-column md-order-by="date">{{ 'POSTPONEMENTS.MATCH_DATE' | translate}}</th>
              <th md-column md-order-by="time">{{ 'POSTPONEMENTS.MATCH_TIME' | translate}}</th>
              <th md-column md-order-by="postponement.date">{{ 'POSTPONEMENTS.MATCH_REQUESTED_DATE' | translate}}</th>
              <th md-column md-order-by="postponement.time">{{ 'POSTPONEMENTS.MATCH_REQUESTED_TIME' | translate}}</th>
              <th md-column md-order-by="championship">{{ 'CHAMPIONSHIPS.CHAMPIONSHIP' | translate}}</th>
              <th md-column md-order-by="localTeam">{{ 'CHAMPIONSHIPS.LOCAL_TEAM' | translate}}</th>
              <th md-column md-order-by="visitorTeam">{{ 'CHAMPIONSHIPS.VISITOR_TEAM' | translate}}</th>
              <th md-column md-order-by="postponement.state">{{ 'POSTPONEMENTS.STATE' | translate}}</th>
              <th md-column>
                <div flex layout="column" layout-align="center center">{{ 'ACTIONS' | translate}}</div>
              </th>
            </tr>
            </thead>
            <tbody md-body>
            <tr md-row ng-repeat="match in vm.matches">
              <td md-cell>{{match.date}}</td>
              <td md-cell>{{match.time || "--:--"}}</td>
              <td md-cell>{{match.lastPostponement.date}}</td>
              <td md-cell>{{match.lastPostponement.time || "--:--"}}</td>
              <td md-cell>{{match.championship}}</td>
              <td md-cell>{{match.localTeam.name}}</td>
              <td md-cell>{{match.visitorTeam.name}}</td>
              <td md-cell>{{'POSTPONEMENTS.STATES.' + match.lastPostponement.state | translate}}</td>
              <td md-cell>
                <div flex layout="row" layout-align="space-around center">
                  <md-button class="md-icon-button" ng-if="vm.showEditButton(match)" aria-label="edit"
                             ng-if="vm.showEditButton(match)"
                             ng-click="vm.editPostponement(match)">
                    <md-tooltip md-direction="left" md-delay="50">{{'EDIT' | translate}}</md-tooltip>
                    <md-icon>mode_edit</md-icon>
                  </md-button>
                  <md-button class="md-icon-button" ng-if="vm.showAcceptAndRejectButton(match)" aria-label="accept"
                             ng-click="vm.acceptPostponement(match)">
                    <md-tooltip md-direction="down" md-delay="50">{{'POSTPONEMENTS.ACCEPT' | translate}}</md-tooltip>
                    <md-icon>check_circle</md-icon>
                  </md-button>
                  <md-button class="md-icon-button" ng-if="vm.showAcceptAndRejectButton(match)"
                             aria-label="reject" ng-click="vm.rejectPostponement(match)">
                    <md-tooltip md-direction="right" md-delay="50">{{'POSTPONEMENTS.REJECT' | translate}}</md-tooltip>
                    <md-icon>cancel</md-icon>
                  </md-button>
                  <md-button class="md-icon-button" ng-if="vm.showCreateButton(match)"
                             ui-sref="home.postponements.new({matchId : match.id})"
                             aria-label="new">
                    <md-tooltip md-direction="right" md-delay="50">{{'POSTPONEMENTS.NEW' | translate}}</md-tooltip>
                    <md-icon>add_box</md-icon>
                  </md-button>
                  <span ng-if="vm.showResolveSwitch(match)">
                    <md-tooltip md-direction="right" md-delay="50">{{'POSTPONEMENTS.RESOLVE' | translate}}</md-tooltip>
                    <md-switch ng-model="match.lastPostponement.resolved"
                               ng-change="vm.changeResolutionPostponement(match)">
                  </md-switch>
                  </span>
                  <span ng-if="vm.showExpiredPostponement(match)" md-colors="{color:'warn'}"><i>{{ 'POSTPONEMENTS.EXPIRED' | translate}}</i></span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </md-table-container>
        <md-table-pagination md-limit="vm.matchesPerPage" md-page="vm.currentPage" md-total="{{vm.maxMatches}}"
                             md-label="{{vm.paginationLabel}}" md-page-select="true"
                             md-on-paginate="vm.getNewPage" ng-if="vm.showList"></md-table-pagination>
      </md-card-content>
    </md-card>

  </div>

</div>
