<div layout="row" layout-align="space-around center">
  <md-card flex-gt-md="70" flex="100">
    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline">{{'POSTPONEMENTS.EDIT' | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>
      <form name="postponementForm" novalidate>
        <div layout-align="center stretch" layout="column">
          <div layout="row" flex>
            <div flex="50" layout="column" layout-align="top none" layout-wrap
                 ng-repeat="team in [vm.localTeam, vm.visitorTeam]">
              <h1 class="md-title">{{team.columnName | translate}}</h1>
              <div layout-padding>
                <div flex layout="row">
                  <neo-input name="teamName" label="TEAM_NAME" neo-icon="title" ng-model="team.name"
                             is-disabled="true" flex no-margin="true"></neo-input>
                </div>
                <div flex layout="column" layout-gt-sm="row">
                  <neo-input name="coachName" label="COACH_NAME" neo-icon="person" ng-model="team.coachName"
                             is-disabled="true" flex="45" no-margin="true"></neo-input>
                  <neo-input name="coachPhone" label="COACH_PHONE" neo-icon="phone" ng-model="team.coachPhone"
                             is-disabled="true" flex="45" no-margin="true"></neo-input>
                </div>
                <div flex layout="column" layout-gt-sm="row">
                  <neo-input name="coachName" label="DELEGATE_NAME" neo-icon="person" ng-model="team.delegateName"
                             is-disabled="true" flex="45" no-margin="true"></neo-input>
                  <neo-input name="coachPhone" label="DELEGATE_PHONE" neo-icon="phone" ng-model="team.delegatePhone"
                             is-disabled="true" flex="45" no-margin="true"></neo-input>
                </div>
                <div flex layout="column" layout-gt-sm="row">
                  <neo-input name="entityName" label="ENTITIES.NAME" ng-model="team.entityName"
                             is-disabled="true" flex="45" no-margin="true"></neo-input>
                  <neo-input name="townHallName" label="TOWNHALLS.NAME" ng-model="team.townHallName"
                             is-disabled="true" flex="45" no-margin="true"></neo-input>
                </div>
              </div>
            </div>
          </div>

          <md-divider></md-divider>

          <h1 class="md-title">{{'POSTPONEMENTS.DETAILS' | translate}}</h1>
          <div layout="row" flex layout-align="space-around">
            <div flex="45" layout="column" layout-align="space-between">
              <!-- Date & recovery dates -->
              <div layout="row">
                <md-input-container flex="45">
                  <md-datepicker name="suggestedDate" ng-model="vm.postponement.date" ng-required="true"
                                 md-placeholder="{{'POSTPONEMENTS.DATE' | translate}}"></md-datepicker>
                  <div ng-messages="postponementForm.suggestedDate.$error" role="alert">
                    <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                  </div>
                </md-input-container>
                <md-input-container class="md-block" flex>
                  <label>{{'POSTPONEMENTS.RECOVERY_DATES' | translate}}</label>
                  <md-select ng-model="vm.selectedRecoveryDate"
                             placeholder="{{'POSTPONEMENTS.RECOVERY_DATES' | translate}}"
                             ng-required="false">
                    <md-option ng-repeat="aSuggestedDate in vm.suggestedDates" ng-value="aSuggestedDate">
                      {{aSuggestedDate}}
                    </md-option>
                  </md-select>
                </md-input-container>
              </div>
              <!-- Time & Facility -->
              <div layout="row" flex>
                <md-input-container flex="45">
                  <label>{{'POSTPONEMENTS.TIME' | translate}}</label>
                  <md-icon class="material-icons md-dark">access_time</md-icon>
                  <input name="time" type="time" ng-disabled="!vm.canSetTime"
                         ng-model="vm.postponement.time" ng-required="vm.canSetTime"/>
                  <div ng-messages="postponementForm.time.$error" role="alert">
                    <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                  </div>
                </md-input-container>
                <md-input-container class="md-block" flex>
                  <label>{{'POSTPONEMENTS.NEW_FACILITY' | translate}}</label>
                  <md-select name="facility" ng-model="vm.postponement.facilityId" ng-disabled="!vm.canSetFacility"
                             aria-label="facility" ng-required="vm.canSetFacility">
                    <md-option ng-repeat="facility in vm.facilities" value="{{facility.id}}">
                      {{facility.name | translate }}
                    </md-option>
                  </md-select>
                  <div ng-messages="postponementForm.facility.$error" role="alert">
                    <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                  </div>
                </md-input-container>
              </div>
            </div>
            <div flex="45" layout="column">
              <!-- Details -->
              <md-input-container class="md-block">
                <label>{{'POSTPONEMENTS.MORE_INFORMATION' | translate}}</label>
                <md-icon class="material-icons md-dark">info_outline</md-icon>
                <textarea ng-model="vm.postponement.details" md-maxlength="500" rows="5" md-select-on-focus></textarea>
              </md-input-container>
            </div>
          </div>
        </div>
        <div flex>
          <md-button class="md-fab md-warn md-fab-bottom-right" ng-click="vm.cancelEdition()"
                     style="margin-bottom: 72px;">
            <md-icon md-svg-src="images/ic_cancel_white_48px.svg" class="s24" aria-label="cancel"></md-icon>
            <md-tooltip md-delay="50" md-direction="left">{{'CANCEL' | translate}}</md-tooltip>
          </md-button>
        </div>
        <div flex>
          <md-button class="md-fab md-accent md-fab-bottom-right" ng-disabled="postponementForm.$invalid"
                     ng-click="vm.updatePostponement()">
            <md-icon class="material-icons md-light">save</md-icon>
            <md-tooltip md-delay="50" md-direction="left">{{'SAVE' | translate}}</md-tooltip>
          </md-button>
        </div>
      </form>
    </md-card-content>
  </md-card>
</div>
