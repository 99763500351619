<form novalidate name="editForm" ng-submit="vm.updateEntitat()">
  <div class="md-whiteframe-1dp">
    <div layout="row" layout-align="space-around center">
      <div flex="40" layout="column" layout-padding>

        <md-input-container>
          <label>{{'NAME' | translate}}</label>
          <md-icon md-svg-src="images/ic_title_black_48px.svg"></md-icon>
          <input name="name" title="name" type="text" required ng-model="vm.entitat.name" ng-disabled="!vm.edit"/>
          <div ng-messages="editForm.name.$error" role="alert">
            <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
          </div>
        </md-input-container>

        <md-input-container class="md-block">
          <label>{{'EMAIL' | translate}}</label>
          <md-icon md-svg-src="images/ic_email_black_48px.svg"></md-icon>
          <input title="email" type="email" required ng-pattern="/^.+@.+\..+$/"
                 ng-model="vm.entitat.email" ng-disabled="!vm.edit"/>
          <div ng-messages="editForm.email.$error" role="alert">
            <div ng-message-exp="['required', 'pattern']">
              {{'EMAIL_INVALID_FORMAT' | translate}}
            </div>
          </div>
        </md-input-container>

        <div flex layout="row">
          <md-input-container class="md-block">
            <label>CCE</label>
            <input title="cce" type="text" ng-model="vm.entitat.cce" ng-disabled="!vm.edit"/>
          </md-input-container>
          <md-input-container>
            <label>NIF/NIE</label>
            <input title="nif" type="text"
                   ng-pattern="/^(\d{8})([A-Z])$|^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$|^[KLMXYZ]\d{7,8}[A-Z]$/"
                   ng-model="vm.entitat.nif" ng-disabled="!vm.edit" required/>
            <div ng-messages="editForm.nif.$error" role="alert">
              <div ng-message="pattern">{{'INVALID_NID' | translate}}</div>
              <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
            </div>
          </md-input-container>
        </div>

        <md-input-container class="md-block">
          <md-icon md-svg-src="images/ic_person_black_48px.svg"></md-icon>
          <label>{{'NAME_CONTACT' | translate}}</label>
          <input title="pcontact" type="text" ng-model="vm.entitat.pname1" ng-disabled="!vm.edit"/>
        </md-input-container>

        <md-input-container class="md-block">
          <md-icon md-svg-src="images/ic_web_black_48px.svg"></md-icon>
          <label>{{'WEB_PAGE' | translate}}</label>
          <input title="web" type="text" ng-model="vm.entitat.web" ng-disabled="!vm.edit"/>
        </md-input-container>
      </div>

      <div flex="45" layout="column" layout-padding>
        <md-input-container>
          <label>{{'ENTITY_TYPE' | translate}}</label>
          <md-select name="entityType" ng-model="vm.entitat.typeId" ng-required="true" ng-disabled="!vm.edit">
            <md-option ng-value="type.id" ng-repeat="type in vm.entityTypes">{{ 'ENTITY_TYPES.' + type.name | translate
              }}
            </md-option>
          </md-select>
          <div ng-messages="entitatForm.entityType.$error" role="alert">
            <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
          </div>
        </md-input-container>

        <md-input-container class="md-block">
          <label>{{'ADDRESS' | translate}}</label>
          <md-icon md-svg-src="images/ic_location_black_48px.svg"></md-icon>
          <input title="address" type="text" ng-model="vm.entitat.address" ng-disabled="!vm.edit" required/>
          <div ng-messages="editForm.address.$error" role="alert">
            <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
          </div>
        </md-input-container>

        <div flex layout="row">
          <md-input-container class="md-block" flex="75">
            <label>{{'TOWN' | translate}}</label>
            <md-icon md-svg-src="images/ic_location_city_black_48px.svg"></md-icon>
            <md-select ng-model="vm.entitat.town" ng-disabled="!vm.edit" required>
              <md-option ng-value="town.id" ng-repeat="town in vm.towns">{{ town.name }}</md-option>
            </md-select>
            <div ng-messages="editForm.towns.$error" role="alert">
              <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
            </div>
          </md-input-container>

          <md-input-container class="md-block" flex>
            <label>{{'POSTAL_CODE' | translate}}</label>
            <input title="zipCode" type="text" name="zipCode" ng-model="vm.entitat.zipCode"
                   ng-pattern="/^[0-9]{5}$/" md-maxlength="5" ng-disabled="!vm.edit" required/>
            <div ng-messages="editForm.zipCode.$error" role="alert">
              <div ng-message="md-maxlength">{{'MAXIMUM_CHARACTERS_POSTAL_CODE' | translate}}</div>
              <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
            </div>
          </md-input-container>
        </div>

        <div flex layout="row">


          <md-input-container class="md-block">
            <label>{{'PHONE' | translate}}</label>
            <md-icon md-svg-src="images/ic_phone_black_48px.svg"></md-icon>
            <input title="phoneNumber" type="tel"
                   ng-pattern="/^[+]{0,1}[0-9]+$/"
                   ng-model="vm.entitat.phoneNumber" ng-disabled="!vm.edit"/>
            <div ng-messages="editForm.phoneNumber.$error" role="alert">
              <div ng-message="pattern">{{'INVALID_PHONE_NUMBER' | translate}}</div>
            </div>
          </md-input-container>


          <md-input-container class="md-block">
            <label>{{'FAX' | translate}}</label>
            <input title="fax" type="tel"
                   ng-pattern="/^[+]{0,1}[0-9]+$/"
                   ng-model="vm.entitat.fax" ng-disabled="!vm.edit"/>
            <div ng-messages="editForm.fax.$error" role="alert">
              <div ng-message="pattern">{{'INVALID_FAX_NUMBER' | translate}}</div>
            </div>
          </md-input-container>

        </div>

        <div flex layout="row">
          <md-input-container class="md-block">
            <label>{{'MOBILE_PHONE' | translate}}</label>
            <input title="mobileNumber" type="tel"
                   ng-pattern="/^[+]{0,1}[0-9]+$/"
                   ng-model="vm.entitat.mobileNumber" ng-disabled="!vm.edit"/>
            <div ng-messages="editForm.mobileNumber.$error" role="alert">
              <div ng-message="pattern">{{'INVALID_MOBILE_PHONE_NUMBER' | translate}}</div>
            </div>
          </md-input-container>
        </div>

        <!-- Xarxes socials informació -->
        <div flex layout="row" ng-if="!vm.edit">
          <md-button ng-if="vm.entitat.facebook" class="md-flat" aria-label="Facebook"
                     href="https://www.facebook.com/{{vm.entitat.facebook}}" target="_blank">
            <md-icon md-svg-src="images/if_facebook_circle_color_107175.svg" aria-label="Facebook"></md-icon>
          </md-button>
          <md-button ng-if="vm.entitat.twitter" class="md-flat" aria-label="Twitter"
                     href="https://twitter.com/{{vm.entitat.twitter}}" target="_blank">
            <md-icon md-svg-src="images/if_twitter_circle_color_107170.svg" aria-label="Twitter"></md-icon>
          </md-button>
          <md-button ng-if="vm.entitat.instagram" class="md-flat" aria-label="Instagram"
                     href="https://www.instagram.com/{{vm.entitat.instagram}}" target="_blank">
            <md-icon md-svg-src="images/if_instagram_2362135.svg" aria-label="Instagram"></md-icon>
          </md-button>
        </div>

        <!-- Xarxes socials edició -->
        <div flex layout="row" ng-if="vm.edit">
          <md-input-container class="md-block">
            <label>Facebook</label>
            <md-icon md-svg-src="images/if_facebook_circle_color_107175.svg" aria-label="Facebook"></md-icon>
            <input title="facbook" type="text"
                   ng-model="vm.entitat.facebook"/>
          </md-input-container>
          <md-input-container class="md-block">
            <label>Twitter</label>
            <md-icon md-svg-src="images/if_twitter_circle_color_107170.svg" aria-label="Twitter"></md-icon>
            <input title="twitter" type="text"
                   ng-model="vm.entitat.twitter"/>
          </md-input-container>
          <md-input-container class="md-block">
            <label>Instagram</label>
            <md-icon md-svg-src="images/if_instagram_2362135.svg" aria-label="Instagram"></md-icon>
            <input title="instagram" type="text"
                   ng-model="vm.entitat.instagram"/>
          </md-input-container>
        </div>

      </div>
    </div>
  </div>
  <div flex>
    <md-button type="submit" class="md-fab md-accent md-fab-bottom-right" ng-if="vm.edit"
               ng-disabled="editForm.$invalid">
      <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
        <md-tooltip md-delay="50" md-direction="left">{{'SAVE' | translate}}</md-tooltip>
    </md-button>
  </div>
  <div flex>
    <md-button class="md-fab md-warn md-fab-bottom-right"
               ng-if="vm.edit" ng-click="vm.disableEdit()" style="margin-bottom: 72px;">
      <md-icon md-svg-src="images/ic_cancel_white_48px.svg" class="s24" aria-label="cancel"></md-icon>
      <md-tooltip md-delay="50" md-direction="left">{{'CANCEL' | translate}}</md-tooltip>
    </md-button>
  </div>
</form>

