<!DOCTYPE html>
<html lang='en' data-ng-app='neo'>
<head>
  <title>Programa NEO</title>
  <meta charset="utf-8">
  <meta name='viewport' content='width=device-width, minimum-scale=1.0, initial-scale=1.0, user-scalable=yes'>
  <!-- inject:head:js -->
  <!-- endinject -->
  <!-- inject:html -->
  <!-- endinject -->
  <!-- bower:css -->
  <!-- endbower -->
  <!-- inject:css -->
  <!-- endinject -->
  <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons">
  <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic">
</head>
<body>

<div layout-fill ui-view></div>
<div id="neoLoader" layout-fill layout="column" ng-show="changingState || httpCallWaiting">
  <div flex layout="column" layout-align="center center">
    <md-progress-circular md-mode="indeterminate"></md-progress-circular>
  </div>
</div>

<!-- TODO: Hack to fix exceptions caused by latest version of angular-google-maps -->
<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBgzZNxB7Kz6NrLVFLYVsOMRoGBgxS7h10"></script>
<!-- bower:js -->
<!-- endbower -->
<!-- inject:js -->
<!-- endinject -->
</body>
</html>
