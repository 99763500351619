<div ui-view>
  <form ng-submit="vm.getInscriptions()" name="townHallFilter">
    <div layout="row" layout-align="center center">

      <md-autocomplete flex="33"
                       md-selected-item="vm.selectedTownHall"
                       md-search-text="vm.townHallSearch"
                       md-items="townHall in vm.myTownHalls | filterAccents: vm.townHallSearch"
                       md-item-text="townHall.name"
                       md-min-length="0"
                       md-require-match
                       ng-required="true"
                       ng-disabled="vm.onlyOneTownHall"
                       md-clear-button="true"
                       md-floating-label="{{ 'Selecciona un ajuntament' | translate}}">
        <md-item-template>
          <span md-highlight-text="vm.townHallSearch" md-highlight-flags="^i">{{ townHall.name }}</span>
        </md-item-template>
        <md-not-found>
          {{'RESULT_NOT_FOUND' | translate}}
        </md-not-found>
      </md-autocomplete>
      <md-button type="submit" ng-if="!vm.onlyOneTownHall" class="md-raised md-primary">Filtrar</md-button>
    </div>
  </form>
  <form name="phaseInscriptionForm" ng-submit="vm.submitInscription()" flex="100">
    <div layout="row" layout-align="space-around center" flex="100">
      <md-card flex="100">
        <div class="md-whiteframe-1dp" flex>
          <md-card-header layout-padding>
            <md-card-header-text>
              <span class="md-headline">{{ 'PHASES.INSCRIPTION' | translate}}</span>
            </md-card-header-text>
          </md-card-header>
        </div>
        <md-card-content>

          <md-table-container ng-if="vm.inscriptions.length>0">
            <table md-table md-row-select="false" ng-model="vm.selected">
              <thead md-head md-order="vm.order">
              <tr md-row ng-hide="!vm.inscriptions.length">
                <th md-column md-order-by="teamNameOrder"><span
                  class="md-title"> {{ 'TEAM' | translate }} </span></th>
                <th md-column md-order-by="sport"><span
                  class="md-title"> {{ 'SPORT' | translate }} </span>
                </th>
                <th md-column mdDesc md-order-by="categoryOrder"><span
                  class="md-title"> {{ 'CATEGORY' | translate }} </span></th>
                <th md-column md-order-by="gender"><span
                  class="md-title"> {{ 'GENDER' | translate }} </span></th>
                <th ng-repeat="phase in vm.inscriptions[0].phases" md-column>
                  <div layout="column" layout-align="center center">
                    <span class="md-title">{{ phase.name }}</span>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody md-body>
              <tr md-row class="pointer-cursor"
                  ng-repeat="inscription in vm.inscriptions">
                <td md-cell><span class="md-body-1"> {{ inscription.teamName | translate }} </span>
                </td>
                <td md-cell><span class="md-body-1"> {{ inscription.teamSport | translate }} </span>
                </td>
                <td md-cell><span class="md-body-1"> {{ inscription.teamCategory | translate }} </span>
                </td>
                <td md-cell><span class="md-body-1"> {{ inscription.teamGender | translate }} </span>
                </td>
                <td md-cell layout-wrap ng-repeat="phase in inscription.phases">
                  <div layout="column" layout-align="center center">
                    <md-checkbox ng-model="phase.inscribed" ng-click="vm.hasChangedModel()"
                                 aria-label="inscription"></md-checkbox>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </md-table-container>
          <span class="md-body-2"
                ng-if="vm.inscriptions.length<1 && !vm.isSelectedTownhallUndefined">{{ 'RESULT_NOT_FOUND' | translate}}</span>
          <span class="md-body-3" ng-if="vm.isSelectedTownhallUndefined">{{ 'SELECT_TOWNHALL_TO_FILTER' | translate}}</span>
        </md-card-content>
      </md-card>
    </div>
    <div flex>
      <md-button class="md-fab md-accent md-fab-bottom-right" type="submit" ng-disabled="vm.disableButtons">
        <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
        <md-tooltip md-delay="50" md-direction="left">{{'SAVE' | translate}}</md-tooltip>
      </md-button>
    </div>
    <div flex>
      <md-button class="md-fab md-warn md-fab-bottom-right" ng-click="vm.discardChanges()" style="margin-bottom: 72px;" ng-disabled="vm.disableButtons">
        <md-icon md-svg-src="images/ic_cancel_white_48px.svg" class="s24" aria-label="cancel"></md-icon>
        <md-tooltip md-delay="50" md-direction="left">{{'CANCEL' | translate}}</md-tooltip>
      </md-button>
    </div>
  </form>
</div>
