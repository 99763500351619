<md-menu-bar id="navigation-bar">
  <md-menu ng-repeat="item in vm.items | filter:vm.hasPermissionMenu">
    <md-button ng-click="vm.performAction(item, $mdMenu)" ng-class="{'item-selected': item.selected}">
      {{item.text | translate}}
    </md-button>
    <md-menu-content width="2" class="navbar-subitems-container">
      <md-menu-item class="navbar-subitems" ng-repeat="child in item.children | filter:vm.hasPermission">
        <md-button aria-label="submenu-option" ng-click="vm.performAction(child, $mdMenu)">
          {{child.text | translate}}
        </md-button>
      </md-menu-item>
    </md-menu-content>
  </md-menu>
  <md-divider></md-divider>
</md-menu-bar>
