<div layout="row" layout-align="space-around center">
  <md-card flex-sm="90" flex-lg="50" flex-md="65">
    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline">{{'CHANGE_PASSWORD' | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>
      <form name="changePasswordForm" ng-submit="vm.changePassword()">
        <div layout="column" layout-align="center" layout-padding>
          <div layout="column" layout-align="start stretch">
            <md-input-container>
              <label>{{'OLD_PASSWORD' | translate}}</label>
              <md-icon md-svg-src="images/ic_lock_black_48px.svg"></md-icon>
              <input type="password" name="oldPassword" ng-model="vm.changePasswordModel.oldPassword" ng-required="true"
                     ng-pattern="/^.{4,}?/">
              <div ng-messages="changePasswordForm.oldPassword.$error" role="alert">
                <div ng-message="pattern">{{'PASSWORD_INVALID_FORMAT' | translate}}</div>
              </div>
            </md-input-container>

            <md-input-container>
              <label>{{'NEW_PASSWORD' | translate}}</label>
              <md-icon md-svg-src="images/ic_lock_black_48px.svg"></md-icon>
              <input type="password" name="newPassword" ng-model="vm.changePasswordModel.newPassword" ng-required="true"
                     ng-pattern="/^.{4,}?/">
              <div ng-messages="changePasswordForm.newPassword.$error" role="alert">
                <div ng-message="pattern">{{'PASSWORD_INVALID_FORMAT' | translate}}</div>
              </div>
            </md-input-container>

            <md-input-container>
              <label>{{'REPEAT_NEW_PASSWORD' | translate}}</label>
              <md-icon md-svg-src="images/ic_lock_black_48px.svg"></md-icon>
              <input type="password" name="newPassword2" ng-model="vm.newpwd2" ng-required="true"
                     neo-match="vm.changePasswordModel.newPassword">
              <div ng-messages="changePasswordForm.newPassword.$error" role="alert">
                <div ng-message="neoMatch">{{'PASSWORD_INVALID_MATCH' | translate}}</div>
              </div>
            </md-input-container>
          </div>
          <div layout="column" flex layout-align="center end">
            <md-button class="md-raised md-primary" ng-disabled="changePasswordForm.$invalid"
                       type="submit">{{'SEND' | translate}}
            </md-button>
          </div>
        </div>
      </form>
    </md-card-content>
  </md-card>
</div>
