<div layout="row" layout="column"       layout-align="center center">
    <md-card flex-gt-md="60" flex="100">
      <div class="md-whiteframe-1dp" flex>
          <md-card-header layout-padding style="background-color:#7F8FB1;color: white">
                <md-card-header-text>
            <span class="md-body-1" >{{vm.campionat}}</span>
          </md-card-header-text>
                <md-card-header-text style="text-align:right">
            <span class="md-headline">{{vm.suspes}}</span>
          </md-card-header-text>
              </md-card-header>
          
        <md-card-header layout-padding style="background-color:#D2E0E6;color: black">
        
              <md-card-header-text>
            <span class="md-body-1">Data</span>
          </md-card-header-text>
             <md-card-header-text>
            <span class="md-body-1">Equip local</span>
          </md-card-header-text>
             <md-card-header-text>
            <span class="md-body-1">Equip visitant</span>
          </md-card-header-text>
             <md-card-header-text>
            <span class="md-body-1">C.C.</span>
          </md-card-header-text>
           
        </md-card-header>
          
     
         
        <md-card-header layout-padding>
        
              <md-card-header-text>
            <span class="md-body-1">{{vm.data}}</span>
          </md-card-header-text>
             <md-card-header-text>
            <span class="md-body-1">{{vm.elocal}}</span>
          </md-card-header-text>
             <md-card-header-text>
            <span class="md-body-1">{{vm.evisitant}}</span>
          </md-card-header-text>
             <md-card-header-text>
            <md-checkbox ng-model="vm.cc" aria-label="Checkbox 1">
            
          </md-checkbox>
          </md-card-header-text>
            
        </md-card-header>
            <md-card-header layout-padding>
                 <md-card-header-text>
            <span class="md-body-1">{{perfilnom}}</span>
          </md-card-header-text>
                <md-card-header-text>
            <span class="md-body-1">{{equipnom}}</span>
          </md-card-header-text>
                      <md-card-header-text>
            <span class="md-body-1"></span>
          </md-card-header-text>
            </md-card-header>
          <md-card-content>
  <md-table-container>
      <table md-table md-row-select="true" >
          <tr md-row md-select="vm.select" ng-show="false">
                <td md-cell>{{item35.iditem}}</td>
               <td md-cell>{{item35.nom}}</td>
              <td md-cell>{{item35.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select35"  ng-init="select35 =vm.init35" style=";color: black"  >
                      <option  ng-repeat="opcion35 in opcions35"  >{{opcion35.name}}</option>
                  </select></td>
          </tr>
            <tr md-row md-select="vm.select"  ng-show="false">
                <td md-cell>{{item36.iditem}}</td>
               <td md-cell>{{item36.nom}}</td>
              <td md-cell>{{item36.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select36"  ng-init="select36 =vm.init36" style=";color: black"  >
                      <option  ng-repeat="opcion36 in opcions36" >{{opcion36.name}}</option>
                  </select></td>
          </tr>
           <tr md-row md-select="vm.select"  ng-show="vm.show">
                <td md-cell>{{item8.iditem}}</td>
               <td md-cell>{{item8.nom}}</td>
              <td md-cell>{{item8.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select8"  ng-init="select8 =vm.init8" style=";color: black"  >
                      <option  ng-repeat="opcion8 in opcions8" >{{opcion8.name}}<option>
                  </select></td>
          </tr>
           <tr md-row md-select="vm.select"  ng-show="vm.show">
                <td md-cell>{{item9.iditem}}</td>
               <td md-cell>{{item9.nom}}</td>
              <td md-cell>{{item9.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select9"  ng-init="select9 =vm.init9" style=";color: black"  >
                      <option  ng-repeat="opcion9 in opcions9">{{opcion9.name}}<option>
                  </select></td>
          </tr>
           <tr md-row md-select="vm.select">
                <td md-cell>{{item17.iditem}}</td>
               <td md-cell>{{item17.nom}}</td>
              <td md-cell>{{item17.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select17" ng-init="select17 =vm.init17" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions17" >{{opcion.name}}<option>
                  </select></td>
          </tr>
           <tr md-row md-select="vm.select">
                <td md-cell>{{item18.iditem}}</td>
               <td md-cell>{{item18.nom}}</td>
              <td md-cell>{{item18.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select18" ng-init="select18 =vm.init18" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions18" >{{opcion.name}}<option>
                  </select></td>
          </tr>
          <tr md-row md-select="vm.select" >
                <td md-cell>{{item19.iditem}}</td>
               <td md-cell>{{item19.nom}}</td>
              <td md-cell>{{item19.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select19" ng-init="select19 =vm.init19" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions19" >{{opcion.name}}<option>
                  </select></td>
          </tr>
          
           <tr md-row md-select="vm.select">
                <td md-cell>{{item20.iditem}}</td>
               <td md-cell>{{item20.nom}}</td>
              <td md-cell>{{item20.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select20" ng-init="select20=vm.init20" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions20">{{opcion.name}}<option>
                  </select></td>
          </tr>
          <tr md-row md-select="vm.select">
                <td md-cell>{{item21.iditem}}</td>
               <td md-cell>{{item21.nom}}</td>
              <td md-cell>{{item21.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select21" ng-init="select21=vm.init21" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions21" >{{opcion.name}}<option>
                  </select></td>
          </tr>
          <tr md-row md-select="vm.select">
                <td md-cell>{{item22.iditem}}</td>
               <td md-cell>{{item22.nom}}</td>
              <td md-cell>{{item22.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select22" ng-init="select22=vm.init22" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions22" >{{opcion.name}}<option>
                  </select></td>
          </tr>
          <tr md-row md-select="vm.select">
                <td md-cell>{{item23.iditem}}</td>
               <td md-cell>{{item23.nom}}</td>
              <td md-cell>{{item23.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select23" ng-init="select23=vm.init23" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions23" >{{opcion.name}}<option>
                  </select></td>
          </tr>
          <tr md-row md-select="vm.select">
                <td md-cell>{{item24.iditem}}</td>
               <td md-cell>{{item24.nom}}</td>
              <td md-cell>{{item24.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select24" ng-init="select24=vm.init24" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions24" >{{opcion.name}}<option>
                  </select></td>
          </tr>
          <tr md-row md-select="vm.select">
                <td md-cell>{{item25.iditem}}</td>
               <td md-cell>{{item25.nom}}</td>
              <td md-cell>{{item25.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select25" ng-init="select25=vm.init25" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions25">{{opcion.name}}<option>
                  </select></td>
          </tr>
          <tr md-row md-select="vm.select">
                <td md-cell>{{item26.iditem}}</td>
               <td md-cell>{{item26.nom}}</td>
              <td md-cell>{{item26.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select26" ng-init="select26=vm.init26" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions26">{{opcion.name}}<option>
                  </select></td>
          </tr>
          <tr md-row md-select="vm.select">
                <td md-cell>{{item27.iditem}}</td>
               <td md-cell>{{item27.nom}}</td>
              <td md-cell>{{item27.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select27" ng-init="select27=vm.init27" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions27" >{{opcion.name}}<option>
                  </select></td>
          </tr>
          <tr md-row md-select="vm.select">
                <td md-cell>{{item28.iditem}}</td>
               <td md-cell>{{item28.nom}}</td>
              <td md-cell>{{item28.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select28" ng-init="select28=vm.init28" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions28" >{{opcion.name}}<option>
                  </select></td>
          </tr>
       <!--   <tr md-row md-select="vm.select">
                <td md-cell>{{item29.iditem}}</td>
               <td md-cell>{{item29.nom}}</td>
              <td md-cell>{{item29.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select29"  ng-init="select29=vm.init29"  style=";color: black"  >
                      <option  ng-repeat="opcion in opcions29" >{{opcion.name}}<option>
                  </select></td>
          </tr>
          <tr md-row md-select="vm.select">
                <td md-cell>{{item30.iditem}}</td>
               <td md-cell>{{item30.nom}}</td>
              <td md-cell>{{item30.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select30"  ng-init="select30=vm.init30"  style=";color: black"  >
                      <option  ng-repeat="opcion in opcions30" >{{opcion.name}}<option>
                  </select></td>
          </tr>
           <tr md-row md-select="vm.select">
                <td md-cell>{{item31.iditem}}</td>
               <td md-cell>{{item31.nom}}</td>
              <td md-cell>{{item31.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select31"  ng-init="select31=vm.init31" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions31">{{opcion.name}}<option>
                  </select></td>
          </tr>
         --> 

                   <tr md-row md-select="vm.select">
                <td md-cell>{{item37.iditem}}</td>
               <td md-cell>{{item37.nom}}</td>
              <td md-cell>{{item37.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select37"  ng-init="select37=vm.init37" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions37">{{opcion.name}}<option>
                  </select></td>
          </tr>
            <tr md-row md-select="vm.select">
                <td md-cell>{{item43.iditem}}</td>
               <td md-cell>{{item43.nom}}</td>
              <td md-cell>{{item43.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select43"  ng-init="select43=vm.init43" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions43" >{{opcion.name}}<option>
                  </select></td>
          </tr>
          <!--
             <tr md-row md-select="vm.select">
                <td md-cell>{{item38.iditem}}</td>
               <td md-cell>{{item38.nom}}</td>
              <td md-cell>{{item38.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select38"  ng-init="select38=vm.init38" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions38" >{{opcion.name}}<option>
                  </select></td>
          </tr>
             <tr md-row md-select="vm.select">
                <td md-cell>{{item39.iditem}}</td>
               <td md-cell>{{item39.nom}}</td>
              <td md-cell>{{item39.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select39"  ng-init="select39=vm.init39" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions39" >{{opcion.name}}<option>
                  </select></td>
          </tr>
             <tr md-row md-select="vm.select">
                <td md-cell>{{item40.iditem}}</td>
               <td md-cell>{{item40.nom}}</td>
              <td md-cell>{{item40.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select40"  ng-init="select40=vm.init40" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions40" >{{opcion.name}}<option>
                  </select></td>
          </tr>
          -->
             <tr md-row md-select="vm.select">
                <td md-cell>{{item41.iditem}}</td>
               <td md-cell>{{item41.nom}}</td>
              <td md-cell>{{item41.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select41"  ng-init="select41=vm.init41" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions41" >{{opcion.name}}<option>
                  </select></td>
          </tr>
             <tr md-row md-select="vm.select">
                <td md-cell>{{item42.iditem}}</td>
               <td md-cell>{{item42.nom}}</td>
              <td md-cell>{{item42.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select42"  ng-init="select42=vm.init42" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions42" >{{opcion.name}}<option>
                  </select></td>
          </tr>
             <tr md-row md-select="vm.select">
                <td md-cell></td>
               <td md-cell>Marcador local</td>
              <td md-cell>{{vm.marcadorlocal}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select60"  ng-init="select60=vm.init60" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions60" >{{opcion.name}}<option>
                  </select></td>
          </tr>
          <tr md-row md-select="vm.select">
                <td md-cell></td>
               <td md-cell>Marcador Visitant</td>
              <td md-cell>{{vm.marcadorvisitant}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select61"  ng-init="select61=vm.init61" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions61" >{{opcion.name}}<option>
                  </select></td>
          </tr>
          
           <tr md-row>
                     <td md-cell>Observacions</td>
              <td md-cell>Partit</td>
              <td md-cell><input type="text" ng-model="observ"  value="{{observacions}}"></td>
           </tr>
      </table>
  </md-table-container>
       
          </md-card-content>
          
      </div>
    </md-card>
    </div>
<md-button type="submit" class="md-fab md-accent md-fab-bottom-right"  ng-click="vm.click()"
             ng-disabled="editForm.$invalid">
    <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
  </md-button>