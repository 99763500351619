<div layout="row" layout="column"       layout-align="center center">
    <md-card flex-gt-md="60" flex="100">
      <div class="md-whiteframe-1dp" flex>
          <md-card-header layout-padding style="background-color:#7F8FB1;color: white">
                <md-card-header-text>
            <span class="md-body-1" >{{vm.campionat}}</span>
          </md-card-header-text>
                <md-card-header-text style="text-align:right">
            <span class="md-headline">{{vm.suspes}}</span>
          </md-card-header-text>
              </md-card-header>
          
        <md-card-header layout-padding style="background-color:#D2E0E6;color: black">
        
              <md-card-header-text>
            <span class="md-body-1">Data</span>
          </md-card-header-text>
             <md-card-header-text>
            <span class="md-body-1">Equip local</span>
          </md-card-header-text>
             <md-card-header-text>
            <span class="md-body-1">Equip visitant</span>
          </md-card-header-text>
             <md-card-header-text>
            <span class="md-body-1">C.C.</span>
          </md-card-header-text>
           
        </md-card-header>
          
     
         
        <md-card-header layout-padding>
        
              <md-card-header-text>
            <span class="md-body-1">{{vm.data}}</span>
          </md-card-header-text>
             <md-card-header-text>
            <span class="md-body-1">{{vm.elocal}}</span>
          </md-card-header-text>
             <md-card-header-text>
            <span class="md-body-1">{{vm.evisitant}}</span>
          </md-card-header-text>
             <md-card-header-text>
            <md-checkbox ng-model="vm.cc" aria-label="Checkbox 1">
            
          </md-checkbox>
          </md-card-header-text>
            
        </md-card-header>
            <md-card-header layout-padding>
                 <md-card-header-text>
            <span class="md-body-1">{{perfilnom}}</span>
          </md-card-header-text>
                <md-card-header-text>
            <span class="md-body-1">{{equipnom}}</span>
          </md-card-header-text>
                      <md-card-header-text>
            <span class="md-body-1"></span>
          </md-card-header-text>
            </md-card-header>
          <md-card-content>
  <md-table-container>
      <table md-table md-row-select="true" >
          <tr md-row md-select="vm.select">
                <td md-cell>{{item10.iditem}}</td>
               <td md-cell>{{item10.nom}}</td>
              <td md-cell>{{item10.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select10"  ng-init="select10 =vm.init10" style=";color: black"  >
                      <option  ng-repeat="opcion10 in opcions10"  >{{opcion10.name}}<option>
                  </select></td>
          </tr>
            <tr md-row md-select="vm.select">
                <td md-cell>{{item11.iditem}}</td>
               <td md-cell>{{item11.nom}}</td>
              <td md-cell>{{item11.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select11"  ng-init="select11 =vm.init11" style=";color: black"  >
                      <option  ng-repeat="opcion11 in opcions11" >{{opcion11.name}}<option>
                  </select></td>
          </tr>
          
          
           <tr md-row md-select="vm.select">
                <td md-cell>{{item12.iditem}}</td>
               <td md-cell>{{item12.nom}}</td>
              <td md-cell>{{item12.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select12" ng-init="select12 =vm.init12" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions12" >{{opcion.name}}<option>
                  </select></td>
          </tr>
           <tr md-row md-select="vm.select">
                <td md-cell>{{item13.iditem}}</td>
               <td md-cell>{{item13.nom}}</td>
              <td md-cell>{{item13.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select13" ng-init="select13 =vm.init13" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions13" >{{opcion.name}}<option>
                  </select></td>
          </tr>
          <tr md-row md-select="vm.select" >
                <td md-cell>{{item14.iditem}}</td>
               <td md-cell>{{item14.nom}}</td>
              <td md-cell>{{item14.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select14" ng-init="select14 =vm.init14" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions14" >{{opcion.name}}<option>
                  </select></td>
          </tr>
          
           <tr md-row md-select="vm.select">
                <td md-cell>{{item15.iditem}}</td>
               <td md-cell>{{item15.nom}}</td>
              <td md-cell>{{item15.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select15" ng-init="select15=vm.init15" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions15">{{opcion.name}}<option>
                  </select></td>
          </tr>
          <tr md-row md-select="vm.select">
                <td md-cell>{{item16.iditem}}</td>
               <td md-cell>{{item16.nom}}</td>
              <td md-cell>{{item16.respetoa}}</td>
              <td md-cell><select placeholder="Valoració" ng-model="select16" ng-init="select16=vm.init16" style=";color: black"  >
                      <option  ng-repeat="opcion in opcions16" >{{opcion.name}}<option>
                  </select></td>
          </tr>
        
        
            
         
         
      </table>
  </md-table-container>
       
          </md-card-content>
          
      </div>
    </md-card>
    </div>
<md-button type="submit" class="md-fab md-accent md-fab-bottom-right"  ng-click="vm.click()"
             ng-disabled="editForm.$invalid">
    <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
  </md-button>