 <md-card>
      <md-card-content>
      <div layout-align="center center" layout="row" flex>

        <form name="refereeForm" ng-submit="vm.updatePerson()" flex>
          <div layout="column" layout-padding flex>
            <div layout-gt-sm="row" layout-align="space-around center">
              <div layout-gt-sm="row" flex="45">

                <neo-input name="name" label="NAME" icon="images/ic_person_black_48px.svg" 
                           ng-model="vm.person.name" flex="45" layout-gt-sm="column" is-disabled="true"></neo-input>

                <neo-input name="surname" label="SURNAME" icon="images/ic_person_black_48px.svg" 
                           ng-model="vm.person.surname" flex="50"
                           layout-gt-sm="column" is-disabled="!vm.edit"></neo-input>
              </div>
              <div layout-gt-sm="row" flex="45">

                <neo-input name="secondSurname" label="SECOND_SURNAME" ng-model="vm.person.secondSurname" flex="50"
                           layout-gt-sm="column" is-disabled="!vm.edit"></neo-input>

                <md-input-container class="md-block" flex="50">
                  <label>{{'BIRTH_DATE' | translate}}</label>
                  <md-datepicker name="birthday" ng-model="vm.person.bornDate" md-current-view="year"
                                 ng-disabled="!vm.edit" flex="100"
                                 md-max-date="vm.today"></md-datepicker>
                  <div ng-messages="refereeForm.birthday.$error" role="alert">
                    <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                    <div ng-message="valid">{{'DATE_INVALID_FORMAT' | translate}}</div>
                  </div>
                </md-input-container>
              </div>
            </div>
              
                </div>
        </form>
      </div>
    </md-card-content>
  </md-card>
<div layout="row" layout-align="space-around center">
 <md-card  flex-gt-md="100" flex="100">
      <md-card-content>
      <div layout-align="center center" layout="row" flex>

        <form name="refereeForm" ng-submit="vm.createLinea()" flex>
          <div layout="column" layout-padding flex>
            <div layout-gt-sm="row" layout-align="space-around center">
                
                 <div layout-gt-sm="row" layout-align="space-between center" flex="45">
                  
                    <neo-input name="Num. Factura" label="Num. Factura" is-required="false"
                               ng-model="numinvoice"
                               layout-gt-sm="column"
                               icon="images/ic_repeat_black_48px.svg"  is-disabled="true"
                               ></neo-input>
                  </div>
                 <md-input-container class="md-block" flex="50">
                  <label>Data</label>
                  <md-datepicker name="birthday" ng-model="fecha" md-current-view="day"
                                 ng-disabled="false" flex="100"
                                 md-max-date="vm.today"></md-datepicker>
                  <div ng-messages="refereeForm.birthday.$error" role="alert">
                    <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                    <div ng-message="valid">{{'DATE_INVALID_FORMAT' | translate}}</div>
                  </div>
                </md-input-container>
                
              
                
                  <div layout-gt-sm="row" layout-align="space-between center" flex="45">
                  
                    <neo-input name="esport" label="Esport" is-required="true"
                               ng-model="esport"
                               layout-gt-sm="column"
                               icon="images/ic_repeat_black_48px.svg"
                              ></neo-input>
                  </div>
                
             <div layout-gt-sm="row" layout-align="space-between center" flex="45">
                  
                    <neo-input name="rounds" label="Num. partits" is-required="true"
                               ng-model="numpartits"
                               layout-gt-sm="column"
                               icon="images/ic_repeat_black_48px.svg"
                               ></neo-input>
                  </div>
                
                 <div layout-gt-sm="row" layout-align="space-between center" flex="45">
                  
                    <neo-input name="preu" label="Preu" is-required="true"
                               ng-model="preu"
                               layout-gt-sm="column"
                               icon="images/ic_repeat_black_48px.svg"
                               ></neo-input>
                  </div>
                  <md-checkbox type="checkbox" name="sinirpf" label="Sin IRPF" ng-required="false"
                                 ng-model="sinirpf" flex="40" > Sin_IRPF </md-checkbox>
                
                  <md-checkbox type="checkbox" name="sintexto" label="Sin Texto" ng-required="false"
                                 ng-model="sintexto" flex="40" > Sin_Texto </md-checkbox>
                 <md-select placeholder="Selecciar receptor" ng-model="selectreceptor" style=";color: black">
      <md-option ng-value="recept" ng-repeat="recept in receptores"  ng-selected="{{recept.idReceptor}} == {{sel}}">{{recept.nombre}}</md-option>
    </md-select>
                 <div layout-gt-sm="row" layout-align="space-between center" flex="15">
                <md-button  ng-click="vm.esborrar()" >
            <md-icon md-svg-src="images/baseline-delete-24px.svg" class="s24"
                     aria-label="Esborrar linea"></md-icon>
            <md-tooltip md-delay="50" md-direction="left">Esborrar linea</md-tooltip>
                </md-button></div>
            </div>
              
                </div>
             <div flex>
              <md-button class="md-fab md-accent md-fab-bottom-right" ng-disabled="refereeForm.$invalid" type="submit">
                <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
                <md-tooltip md-delay="50" md-direction="left">{{'SAVE' | translate}}</md-tooltip>
              </md-button>
            </div>
        </form>
      </div>
    </md-card-content>
  </md-card>

</div>     
    