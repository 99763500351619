<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
</head>
<style>
  body {
    -webkit-print-color-adjust: exact;
  }

  .print-team-container span {
    font-size: 0.75em;
  }

  .print-team-container p {
    line-height: 90%;
  }

  .print-team-info-container {
    width: 50%;
    float: left;
  }

  .print-team-table {
    width: 100%;
  }

  .print-team-header {
    margin-top: 25.4mm;
    background-color: #15478C;
    color: white;
  }

  .print-team-odd-rows {
    background-color: #AED6F1;
  }

  .print-team-table-title {
    text-align: left;
  }

  .print-team-container > .page-logo {
    margin: 0 auto;
  }

  .print-team-container > .page-logo > img {
    width: 15%;
  }

  .print-team-container > .page-logo > img:nth-child(n+2) {
    max-width: 15%;
    margin-left: 5%;
  }

  @page {
    margin: 25.4mm 13.2mm 19mm 19mm;
  }

  @media print {
    tr {
      page-break-inside: avoid;
    }
  }
</style>
<body>
<div layout-padding class="print-team-container">

  <div class="page-logo">
    <img src="images/cebllob-imprenta_transparente.gif">
    <img src="images/ucec.jpg">
    <img src="images/gencat_esport.jpg">
    <img src="images/ccbl.png">
    <img src="images/juga-verd-play.png">
  </div>

  <h1>Full d'inscripció a la competició dels Jocs Esportius Escolars de Catalunya</h1>

  <div>
    <div class="print-team-info-container">
      <p>{{'ENTITY' | translate}}: {{vm.team.entityName}}</p>
      <p>{{'TEAM' | translate}}: {{vm.team.teamName}}</p>
      <p>{{'SPORT' | translate}}: {{vm.team.sportName | translate}}</p>
      <p>{{'CATEGORY' | translate}}: {{vm.team.categoryName | translate}}</p>
      <p>{{'PROGRAM_TYPE' | translate}}: {{vm.team.programName | translate}}</p>
      <p>{{'PRINT_DATE' | translate}}: {{vm.currentDate}}</p>
    </div>
    <div class="print-team-info-container">
      <p>{{'CONTACT_PERSON' | translate}}: {{vm.team.coachName}}</p>
      <p>{{'EMAIL' | translate}}: {{vm.team.coachEmail}}</p>
      <p>{{'PHONE' | translate}}: {{vm.team.coachPhone}}</p>
      <p>{{'COLOR_SHIRT' | translate}}: {{vm.team.shirtColor}}</p>
      <p>{{'COLOR_PANTS' | translate}}: {{vm.team.pantsColor }}</p>
    </div>
  </div>

  <md-table-container>
    <table md-table md-row-select="false" ng-model="vm.selected" class="print-team-table">
      <thead md-head>
      <tr md-row class="print-team-header">
        <th ng-repeat="header in vm.headers" md-column class="print-team-table-title">
          <span class="md-title">{{header | translate}}</span>
        </th>
      </tr>
      </thead>
      <tbody md-body>
      <tr md-row ng-repeat="(index, person) in vm.team.persons | orderBy: ['licenseType', 'category', 'licenseCode']"
          ng-class="{'print-team-odd-rows': index%2 === 1}">
        <td md-cell><span class="md-body-1">{{person.licenseCode}}</span></td>
        <td md-cell><span class="md-body-1">{{person.name}}</span></td>
        <td md-cell><span class="md-body-1">{{person.surnames}}</span></td>
        <td md-cell><span class="md-body-1">{{person.nif}}</span></td>
        <td md-cell><span class="md-body-1">{{person.licenseType | translate}}</span></td>
        <td md-cell><span class="md-body-1">{{person.category | translate}}</span></td>
        <td md-cell><span class="md-body-1">{{person.bornDate}}</span></td>
      </tr>
      </tbody>
    </table>
  </md-table-container>
</div>
</body>
</html>
