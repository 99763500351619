<md-dialog flex="60">
    <form ng-cloak>
        <md-toolbar class="md-accent md-raised">
            <div class="md-toolbar-tools">
                <h2>{{'PEOPLE.IMPORT_ATHLETES' | translate}}</h2>
                <span flex></span>
            </div>
        </md-toolbar>
        <md-list ng-if="athletesToImport.length === 0">
            <md-list-item>
                <div class="md-list-item-text">
                    <span>{{notFoundMessage | translate}}</span>
                </div>
            </md-list-item>
            <md-dialog-actions layout="row">
                <md-button class="md-warn md-raised" ng-click="cancel()" flex="none">
                    {{'CANCEL' | translate}}
                </md-button>
            </md-dialog-actions>
        </md-list>
        <md-list ng-if="athletesToImport.length !== 0">
            <md-list-item class="md-2-line" ng-repeat="athlete in athletesToImport">
                <md-checkbox ng-click="listToImport(athlete, importList)"></md-checkbox>
                <div class="md-list-item-text">
                    <h3>{{athlete.name}} {{athlete.surname1}} {{athlete.surname2}} - {{athlete.bornDate}}</h3>
                </div>
            </md-list-item>

            <md-dialog-actions layout="row">
                <md-button class="md-warn md-raised" ng-click="cancel()" flex="none">
                    {{'CANCEL' | translate}}
                </md-button>
                <md-button class="md-accent md-raised" ng-click="importPeople()" flex="none">
                    {{'IMPORT' | translate}}
                </md-button>
            </md-dialog-actions>
        </md-list>
    </form>
</md-dialog>
