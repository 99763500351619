<div layout-fill layout="column">
  <header>
    <neo-toolbar logout="vm.logout()"></neo-toolbar>
  </header>

  <main class="md-padding" layout="column" flex style=" overflow-y: auto;">
    <div ui-view flex></div>
  </main>
  <footer>
    <div class="md-whiteframe-2dp" style="width: 100%" layout="row" layout-align="center center">
      <div layout="row" hide-sm hide-xs flex="70" flex-md="100" layout-align="space-around center">
        <div layout="row">
          <md-icon md-svg-src="images/ic_phone_black_48px.svg"></md-icon>
          <p class="md-caption"> +34 936 852 039</p>
        </div>
        <div layout="row">
          <md-icon md-svg-src="images/ic_email_black_48px.svg"></md-icon>
          <p class="md-caption"> cebllob@cebllob.cat</p>
        </div>
        <div layout="row">
          <md-icon md-svg-src="images/ic_location_black_48px.svg"></md-icon>
          <p class="md-caption"> Carretera N - 340 Km. 1249 Parc de Torreblanca - Sant Feliu de Llobregat -
            08980</p>
        </div>
      </div>
    </div>
  </footer>

</div>
