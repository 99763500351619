<div layout="row" layout-align="space-around center">
    
     <style type="text/css">
        .fileUpload {
    position: relative;
    overflow: hidden;
    margin: 10px;
}
.fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}
        </style>
        
 
  
  <md-card flex-gt-md="70" flex="100">
    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline">{{'NEW_PERSON_UPLOAD' | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>
         <div   width="200" height="200"  class="dropzone" file-dropzone="[image/png, image/jpeg, image/gif, image/jpg]"
                  file="image" file-name="imageFileName" data-max-file-size="3" >
   
      <img id="im" ng-src={{image}} width="200" height="200" alt="Arrossegar aqui foto" style="width: 200px;height: 200px;line-height: 200px;text-align: center;padding:1px;border:1px solid #021a40;" />
       <form action>
       <div class="fileUpload btn btn-primary" >
      
    <input type="file" fileread="image" >
    </div>
  </form>
    </div>
  
      <div layout-align="center center" layout="row" flex>

        <form name="personForm" ng-submit="vm.savePerson()" flex>
          <div layout="column" layout-padding flex>
              <h1 class="md-title">{{'ASSOCIATIONS' | translate}}</h1>
              <md-divider></md-divider>

              <div layout-gt-sm="row" layout-align="space-around">
                <md-autocomplete flex="35" ng-disabled="vm.entities.length === 1" md-selected-item="vm.person.entity"
                                 md-search-text="entitySearch" md-clear-button="true"
                                 md-items="entity in vm.entities | filterAccents: entitySearch"
                                 md-item-text="entity.name" md-min-length="0" md-require-match ng-required="true"
                                 md-floating-label="{{'ENTITY' | translate}}">
                  <md-item-template>
                    <span md-highlight-text="vm.entitySearch" md-highlight-flags="^i">{{entity.name}}</span>
                  </md-item-template>
                  <md-not-found>
                    {{'RESULT_NOT_FOUND' | translate}}
                  </md-not-found>
                </md-autocomplete>
                <md-autocomplete flex="35" md-selected-item="vm.person.team" md-search-text="teamSearch"
                                 md-items="team in vm.entityTeams | filterAccents: teamSearch" md-item-text="team.name"
                                 md-min-length="0" md-require-match ng-required="true"
                                 ng-disabled="!vm.person.entity" md-clear-button="true"
                                 md-floating-label="{{'TEAM' | translate}}">
                  <md-item-template>
                    <span md-highlight-text="vm.teamSearch" md-highlight-flags="^i">{{team.name}}</span>
                  </md-item-template>
                </md-autocomplete>

                <md-input-container class="md-block" flex="20">
                  <label>{{'LICENSE_TYPE' | translate}}</label>
                  <md-select name="licenseType" ng-model="vm.person.licenseType" ng-required="true">
                    <md-option ng-repeat="license in vm.licenseTeamTypes" value="{{license.id}}">
                      {{'PERSON_TYPES.'+license.name | translate}}
                    </md-option>
                  </md-select>
                  <div ng-messages="personForm.licenseType.$error" role="alert">
                    <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                  </div>
                </md-input-container>
              </div>

              <h1 class="md-title">{{'PERSONAL_DATA' | translate}}</h1>
              <md-divider></md-divider>
              <div layout-gt-sm="row" layout-align="space-around center">
                <neo-input name="name" label="NAME" icon="images/ic_person_black_48px.svg" is-required="true"
                           ng-model="vm.person.name" flex="45" layout-gt-sm="column"></neo-input>
                <div layout-gt-sm="row" flex="45">
                  <neo-input name="surname" label="SURNAME" icon="images/ic_person_black_48px.svg" is-required="true"
                             ng-model="vm.person.surname" flex="50"
                             layout-gt-sm="column"></neo-input>
                  <neo-input name="secondSurname" label="SECOND_SURNAME" ng-model="vm.person.secondSurname" flex="50"
                             layout-gt-sm="column"></neo-input>
                </div>
              </div>

              <div layout-gt-sm="row" layout-align="space-around center">
                <div layout-gt-sm="row" flex="50">
                  <md-input-container class="md-block" flex="50">
                    <label>{{'BIRTH_DATE' | translate}}</label>
                    <md-datepicker name="birthday" ng-model="vm.person.birthday" md-current-view="year"
                                   ng-required="true"></md-datepicker>
                    <div ng-messages="personForm.birthday.$error" role="alert">
                      <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                      <div ng-message="valid">{{'DATE_INVALID_FORMAT' | translate}}</div>
                    </div>
                  </md-input-container>

                  <md-input-container class="md-block" flex="50">
                    <label>{{'GENDER' | translate}}</label>
                    <md-icon md-svg-src="images/gender-male-female.svg"></md-icon>
                    <md-select name="gender" ng-model="vm.person.genderId" ng-required="true">
                      <md-option ng-repeat="gender in vm.personGenders" value="{{gender.id}}">{{gender.name | translate}}
                      </md-option>
                    </md-select>
                    <div ng-messages="personForm.gender.$error" role="alert">
                      <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                    </div>
                  </md-input-container>
                </div>

                <neo-input name="email" label="CONTACT_EMAIL" icon="images/ic_email_black_48px.svg"
                           ng-model="vm.person.email" error="EMAIL_INVALID_FORMAT"
                           pattern="^.+@.+\..+$" flex="45" layout-gt-sm="column"></neo-input>
              </div>

              <div layout-gt-sm="row" layout-align="space-around center">
                <neo-input name="nif" label="NIF/NIE" icon="images/ic_person_black_48px.svg" ng-model="vm.person.nif"
                           error="INVALID_NID" flex="45" layout-gt-sm="column"></neo-input>
                <neo-input name="hic" label="HEALTH_INSURANCE_CARD_NUMBER" icon="images/ic_healing_black_48px.svg"
                           ng-model="vm.person.healthInsuranceCard" flex="45" layout-gt-sm="column"></neo-input>
              </div>

              <div layout-gt-sm="row" layout-align="space-around center">
                <neo-input name="address" label="ADDRESS" icon="images/ic_location_black_48px.svg"
                           ng-model="vm.person.address" flex="45" layout-gt-sm="column"></neo-input>
                <div layout-gt-sm="row" flex="45">
                  <neo-input name="town" label="TOWN" icon="images/ic_location_city_black_48px.svg"
                             ng-model="vm.person.town" flex="70" layout-gt-sm="column"></neo-input>
                  <neo-input name="zipCode" label="POSTAL_CODE" ng-model="vm.person.zipCode"
                             error="INVALID_POSTAL_CODE" pattern="^[0-9]{5}$"
                             flex="30" layout-gt-sm="column"></neo-input>
                </div>
              </div>

            <div flex layout="row" layout-align="center center" layout-padding>
              <md-input-container>
                <md-checkbox ng-model="vm.lodp" name="lopd" aria-label="LODP" ng-required="true">
                   <a target="_blank" href="http://cebllob.org/lopd.pdf">Autorització de tractament de dades de caràcter personal i Acord de comunicació de dades entre clubs/entitats/centres i el Consell Esportiu del Baix Llobregat</a>
                </md-checkbox>
                <div ng-messages="personForm.lopd.$error" role="alert">
                  <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                </div>
              </md-input-container>
            </div>

          </div>
          <div flex>
            <md-button class="md-fab md-accent md-fab-bottom-right" ng-disabled="personForm.$invalid || !vm.lodp" type="submit">
              <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
              <md-tooltip md-delay="50" md-direction="left">{{'SAVE' | translate}}</md-tooltip>
            </md-button>
          </div>
        </form>
      </div>
    </md-card-content>
  </md-card>
</div>
