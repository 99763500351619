<!DOCTYPE html>
<!--
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
-->
<table>
    <thead>
        <tr>
            <th>Id></th>
            <th> Non</th>
            <th> Cognom</th>
            <th> email</th>
            <th> Població</th>
               <th> Campionat</th>
               <th> Data</th>
                <th> Equip local</th>
                 <th> Equip Visitant</th>
             <th> TècL a TècV</th>
             <th> TècV a TècL</th>
              <th> TècL a EqV</th>
               <th> TècV a EqL</th>
               
               
        </tr>
    </thead>
    <tbody>
        <tr ng-repeat="partit in partidos">
             <td> {{partit.id}}</td>
            <td> {{partit.nombre}}</td>
            <td> {{partit.apellido}}</td>
            <td> {{partit.email}}</td>
            <td> {{partit.poblacion}}</td>
            <td> {{partit.campionat}}</td>
              <td> {{partit.fecha}}</td>
              <td> {{partit.elocal}}</td>
                <td> {{partit.evisitant}}</td>
            <td> {{partit.tecnicL}}</td>
             <td> {{partit.tecnicV}}</td>
             <td> {{partit.equipL}}</td>
             <td> {{partit.equipV}}</td>
             
             
    </tr>
        
    </tbody>   
</table>
