<div layout="row" layout-align="space-around center">

  <md-card flex-gt-md="70" flex="100">
    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline" ng-if="!vm.edit">{{'TEAM_DATA' | translate}}</span>
          <span class="md-headline" ng-if="vm.edit">{{'TEAM_MODIFY_DATA' | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>
      <div layout-align="center center" layout="row">
        <form name="teamForm" ng-submit="vm.updateTeam()" flex>
          <div layout="column" flex>
            <div layout="column" layout-align="space-around" flex>

              <div layout="column" layout-align="center none" style="padding: 1% 25% 0 25%;">
                <md-input-container>
                  <label>{{'NAME' | translate}}</label>
                  <md-icon md-svg-src="images/ic_person_black_48px.svg"></md-icon>
                  <input name="name" ng-required="true" ng-model="vm.team.name" ng-disabled="!vm.edit"/>
                  <div ng-messages="teamForm.name.$error" role="alert">
                    <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                  </div>
                </md-input-container>
                <md-autocomplete
                  ng-disabled="vm.entities.length === 1 || !vm.edit  || vm.team.inscribedToChampionship"
                  md-search-text="searchText"
                  md-items="entity in vm.entities | filterAccents: searchText"
                  md-selected-item="vm.entitySelected"
                  md-item-text="entity.name"
                  md-min-length="0"
                  md-require-match
                  md-clear-button="true"
                  ng-required="true"
                  md-floating-label="{{'ENTITY' | translate}}">
                  <md-item-template>
                    <span md-highlight-text="searchText" md-highlight-flags="^i">{{entity.name}}</span>
                  </md-item-template>
                  <md-not-found>
                    {{'RESULT_NOT_FOUND' | translate}}
                  </md-not-found>
                </md-autocomplete>
              </div>

              <div layout="row" layout-align="space-around center" flex="100">
                <div layout="column" flex="40" layout-padding>
                  <md-input-container class="md-block">
                    <label>{{'PROGRAM_TYPE' | translate}}</label>
                    <md-select name="programme" ng-model="vm.team.programmeId" ng-required="true"
                               ng-disabled="!vm.edit || vm.team.inscribedToChampionship">
                      <md-option ng-repeat="program in vm.programs" value="{{program.id}}">
                        {{program.name | translate }}
                      </md-option>
                    </md-select>
                    <div ng-messages="teamForm.programme.$error" role="alert">
                      <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                    </div>
                  </md-input-container>

                  <md-input-container class="md-block">
                    <label>{{'SPORT' | translate}}</label>
                    <md-select name="sport" ng-model="vm.team.sportId" ng-required="true"
                               ng-disabled="!vm.edit  || vm.team.inscribedToChampionship">
                      <md-option ng-repeat="sport in vm.sports" value="{{sport.id}}">
                        {{'SPORTS.'+ sport.name | translate}}
                      </md-option>
                    </md-select>
                    <div ng-messages="teamForm.sport.$error" role="alert">
                      <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                    </div>
                  </md-input-container>

                  <md-input-container class="md-block">
                    <label>{{'CATEGORY' | translate}}</label>
                    <md-icon md-svg-src="images/ic_folder_black_48px.svg"></md-icon>
                    <md-select name="category" ng-model="vm.team.categoryId" ng-required="true"
                               ng-disabled="!vm.edit  || vm.team.inscribedToChampionship">
                      <md-option ng-repeat="category in vm.categories" value="{{category.id}}">
                        {{'CATEGORIES.'+category.name | translate}}
                      </md-option>
                    </md-select>
                    <div ng-messages="teamForm.category.$error" role="alert">
                      <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                    </div>
                  </md-input-container>
                  <md-content ng-show="vm.showInsurance"> {{'INSURANCE_PRICE.CONTENT' | translate}} <b><span
                    ng-bind="vm.price.price"></span> {{'INSURANCE_PRICE.CURRENCY' | translate}}</b><span
                    ng-show="vm.price.provisional">{{'INSURANCE_PRICE.PROVISIONAL' | translate}}</span></md-content>
                </div>
                <div layout="column" flex="40">

                  <md-input-container class="md-block">
                    <label>{{'GENDER' | translate}}</label>
                    <md-icon md-svg-src="images/gender-male-female.svg"></md-icon>
                    <md-select name="gender" ng-model="vm.team.genderId" ng-required="true"
                               ng-disabled="!vm.edit  || vm.team.inscribedToChampionship">
                      <md-option ng-repeat="gender in vm.genders" value="{{gender.id}}">{{gender.name | translate}}
                      </md-option>
                    </md-select>
                    <div ng-messages="teamForm.gender.$error" role="alert">
                      <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                    </div>
                  </md-input-container>
                  <md-input-container>
                    <md-icon md-svg-src="images/ic_person_black_48px.svg"></md-icon>
                    <label>{{'COLOR_SHIRT' | translate}}</label>
                    <input title="shirtColor" type="text" ng-model="vm.team.shirtColor"
                           ng-disabled="!vm.edit"/>
                  </md-input-container>
                  <md-input-container>
                    <md-icon md-svg-src="images/ic_person_black_48px.svg"></md-icon>
                    <label>{{'COLOR_PANTS' | translate}}</label>
                    <input title="pantsColor" type="text" ng-model="vm.team.pantsColor"
                           ng-disabled="!vm.edit"/>
                  </md-input-container>
                </div>
              </div>
            </div>
            <md-divider></md-divider>
            <div layout="column" layout-align="space-around" layout-padding>
              <h1 class="md-title">{{'DATA_COACH' | translate}}</h1>
              <div layout="row" layout-align="space-around">
                <md-input-container>
                  <label>{{'NAME' | translate}}</label>
                  <md-icon md-svg-src="images/ic_person_black_48px.svg"></md-icon>
                  <input title="coachName" type="text" ng-model="vm.team.coachName" ng-disabled="!vm.edit"/>
                </md-input-container>
                <md-input-container>
                  <label>{{'EMAIL' | translate}}</label>
                  <md-icon md-svg-src="images/ic_email_black_48px.svg"></md-icon>
                  <input name="coachEmail" type="email" ng-model="vm.team.coachEmail" ng-pattern="/^.+@.+\..+$/"
                         ng-disabled="!vm.edit"/>
                  <div ng-messages="teamForm.coachEmail.$error" role="alert">
                    <div ng-message="pattern">{{'EMAIL_INVALID_FORMAT' | translate}}</div>
                  </div>
                </md-input-container>
                <md-input-container>
                  <label>{{'PHONE' | translate}}</label>
                  <md-icon md-svg-src="images/ic_phone_black_48px.svg"></md-icon>
                  <input name="coachPhoneNumber" type="tel" ng-model="vm.team.coachPhone"
                         ng-pattern="/^[+]{0,1}[0-9]+$/" ng-disabled="!vm.edit"/>
                  <div ng-messages="teamForm.coachPhoneNumber.$error" role="alert">
                    <div ng-message="pattern">{{'INVALID_PHONE_NUMBER' | translate}}</div>
                  </div>
                </md-input-container>
              </div>
              <md-divider></md-divider>
              <h1 class="md-title">{{'DATA_DELEGATE' | translate}}</h1>
              <div layout="row" layout-align="space-around">
                <md-input-container>
                  <label>{{'NAME' | translate}}</label>
                  <md-icon md-svg-src="images/ic_person_black_48px.svg"></md-icon>
                  <input name="delegateName" type="text" ng-model="vm.team.delegateName" ng-disabled="!vm.edit"/>
                </md-input-container>
                <md-input-container class="md-block">
                  <label>{{'EMAIL' | translate}}</label>
                  <md-icon md-svg-src="images/ic_email_black_48px.svg"></md-icon>
                  <input name="delegateEmail" type="email" ng-model="vm.team.delegateEmail"
                         ng-pattern="/^.+@.+\..+$/"
                         ng-disabled="!vm.edit"/>
                  <div ng-messages="teamForm.delegateEmail.$error" role="alert">
                    <div ng-message="pattern">{{'EMAIL_INVALID_FORMAT' | translate}}</div>
                  </div>
                </md-input-container>
                <md-input-container>
                  <label>{{'PHONE' | translate}}</label>
                  <md-icon md-svg-src="images/ic_phone_black_48px.svg"></md-icon>
                  <input name="delegatePhoneNumber" type="tel" ng-model="vm.team.delegatePhone"
                         ng-pattern="/^[+]{0,1}[0-9]+$/" ng-disabled="!vm.edit"/>
                  <div ng-messages="teamForm.delegatePhoneNumber.$error" role="alert">
                    <div ng-message="pattern">{{'INVALID_PHONE_NUMBER' | translate}}</div>
                  </div>
                </md-input-container>
              </div>
              <md-divider></md-divider>
              <h1 class="md-title">{{'JVP_INFO' | translate}}</h1>
              <div layout="row" layout-align="space-around">
                <md-input-container>
                  <label>{{'COACH_CODE' | translate}}</label>
                  <md-icon md-svg-src="images/ic_person_black_48px.svg"></md-icon>
                  <input title="coachCode" ng-model="vm.team.coachCode" disabled  />
                </md-input-container>
                <md-input-container>
                  <label>{{'STAND_TUTOR_CODE' | translate}}</label>
                  <md-icon md-svg-src="images/ic_person_black_48px.svg"></md-icon>
                  <input title="standTutorCode" ng-model="vm.team.standTutorCode" disabled/>
                </md-input-container>
              </div>
            </div>
          </div>

          <div flex>
            <md-button class="md-fab md-accent md-fab-bottom-right" type="submit"
                       ng-disabled="teamForm.$invalid || teamForm.$pristine"
                       ng-if="vm.edit">
              <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
              <md-tooltip md-delay="50" md-direction="left">{{'SAVE' | translate}}</md-tooltip>
            </md-button>
          </div>
          <div flex>
            <md-button class="md-fab md-warn md-fab-bottom-right" ng-click="vm.discardChanges()"
                       style="margin-bottom: 72px;" ng-if="vm.edit">
              <md-icon md-svg-src="images/ic_cancel_white_48px.svg" class="s24" aria-label="cancel"></md-icon>
              <md-tooltip md-delay="50" md-direction="left">{{'CANCEL' | translate}}</md-tooltip>
            </md-button>
          </div>
        </form>
      </div>
      <neo-action-button buttons="vm.buttons"></neo-action-button>
      <neo-print-team-template id="teamPrintPreview" ng-hide="true" team="vm.printTeam"></neo-print-team-template>
    </md-card-content>
  </md-card>
</div>
