<md-dialog flex="70">
  <form ng-cloak>
    <md-toolbar class="md-accent md-raised">
      <div class="md-toolbar-tools">
        <h2>{{'PEOPLE.DUPLICATED_TITLE' | translate}}</h2>
        <span flex></span>
      </div>
    </md-toolbar>

    <md-list>
      <md-list-item class="md-2-line" ng-repeat="(id, duplicated) in duplicatedPersons">
        <md-checkbox ng-model="duplicated.check" ng-change="unselectOthers(id)"></md-checkbox>
        <div class="md-list-item-text">
          <h3>{{duplicated.name}} {{duplicated.surname1}} {{duplicated.surname2}} ({{parseDate(duplicated.bornDate)}})</h3>

          <p ng-repeat="license in duplicated.licensesList">
            {{license.team.name}} <span ng-if="license.town">({{license.town}})</span> -
            {{'SPORTS.' + getSportName(license.team.sportId) | translate}} - {{'PERSON_TYPES.' + getLicenseName(license.licenceTypeId) |
            translate}}
          </p>

          <p ng-repeat="noTeamLicence in duplicated.noTeamLicencesList">
            {{'PERSON_TYPES.' + getLicenseName(noTeamLicence.licenceTypeId) |
            translate}} - {{noTeamLicence.organisationName | translate}}
          </p>

          <!--<p ng-repeat="noTeamlicense in duplicated.noTeamlicensesList">
            {{license.organisation}} <span ng-if="license.town">({{license.town}})</span> - {{license.team}} -
            {{'SPORTS.' + license.sport | translate}} - {{'PERSON_TYPES.' + getLicenseName(license.licenseTypeId) |
            translate}}
          </p>-->
        </div>
      </md-list-item>
    </md-list>

    <md-dialog-actions layout="row">
      <md-button class="md-accent md-raised" ng-click="cancel()" flex="none">
        {{'CANCEL' | translate}}
      </md-button>
      <div flex></div>
      <md-button class="md-accent md-raised" ng-click="newPerson()" flex="none">
        {{'PEOPLE.NEW_PERSON' | translate}}
      </md-button>
      <md-button class="md-accent md-raised" ng-click="selectedPerson()" ng-disabled="!someSelected()" flex="none">
        {{'USE_SELECTED_PERSON' | translate}}
      </md-button>
    </md-dialog-actions>
  </form>
</md-dialog>
