<div layout="row" layout-align="space-around center" flex>
  <md-card flex="70">
    <div class="md-whiteframe-1dp" flex>
    <md-card-header layout-padding>
      <md-card-header-text>
        <span class="md-headline">{{ "CHAMPIONSHIPS.TEAMS_ASSIGNMENT" | translate }}</span>
      </md-card-header-text>
    </md-card-header>
    </div>
    <md-card-content>
      <div layout-align="space-around center">
        <h1 class="md-title">{{'CHAMPIONSHIPS.DATA' | translate}}</h1>
        <md-divider></md-divider>
        <div layout-lt-md="column" layout="row" flex layout-align="space-around center" layout-padding>
          <div flex-lt-md flex="50" layout-align="space-around center">
            <!-- NAME -->
            <neo-input name="name" label="CHAMPIONSHIPS.NAME" icon="images/ic_title_black_48px.svg"
                       ng-model="vm.championship.name" flex
                       is-disabled="true"></neo-input>
            <!-- SPORT -->
            <neo-input name="sport" label="SPORT" icon="images/football.svg"
                       ng-model="vm.championship.sport" flex
                       is-disabled="true"></neo-input>
          </div>
          <div flex-lt-md flex="50" layout-align="space-around center">
            <neo-input name="category" label="CATEGORY" icon="images/ic_folder_black_48px.svg"
                       ng-model="vm.championship.category" flex
                       is-disabled="true"></neo-input>
            <neo-input name="gender" label="GENDER" icon="images/gender-male-female.svg"
                       ng-model="vm.championship.gender" flex
                       is-disabled="true"></neo-input>
          </div>
        </div>
        <md-checkbox ng-model="vm.displayAll" aria-label="Display all" ng-change="vm.setTeamsDisplay()">
          {{'CHAMPIONSHIPS.DISPLAY_TEAMS_ALREADY_ASSIGNED' | translate}}
        </md-checkbox>
        <div class="multiDemo" layout="row" layout-align="space-around">
          <div flex="50">
            <md-card>
              <md-card-title>
                <md-card-title-text>
                  <span class="md-headline">{{vm.unassignedTeams.listName | translate }}</span>
                </md-card-title-text>
              </md-card-title>
              <md-divider></md-divider>
              <md-card-content>
                <div class="panel panel-info">
                  <div class="panel-body" flex>
                    <ul dnd-list dnd-drop="vm.onDrop(vm.unassignedTeams, item, index)">
                      <li ng-repeat="item in vm.unassignedTeams.items" ng-if="item.display"
                          dnd-draggable="vm.getSelectedItemsIncluding(vm.unassignedTeams, item)"
                          dnd-dragstart="vm.onDragStart(vm.unassignedTeams, event)"
                          dnd-moved="vm.onMoved(vm.unassignedTeams)"
                          dnd-dragend="vm.unassignedTeams.dragging = false"
                          dnd-selected="item.selected = !item.selected"
                          ng-class="{'selected': item.selected}"
                          ng-hide="vm.unassignedTeams.dragging && item.selected">
                        {{item.teamName}} - {{item.gender | translate}} - {{item.town}}
                      </li>
                    </ul>
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </div>


          <div flex="50">
            <md-card>
              <md-card-title>
                <md-card-title-text>
                  <span class="md-headline">{{vm.assignedTeams.listName | translate }}</span>
                </md-card-title-text>
              </md-card-title>
              <md-divider></md-divider>
              <md-card-content>
                <div class="panel panel-info">
                  <div class="panel-body" flex>
                    <ul dnd-list dnd-drop="vm.onDrop(vm.assignedTeams, item, index)">
                      <li ng-repeat="item in vm.assignedTeams.items"
                          dnd-draggable="vm.getSelectedItemsIncluding(vm.assignedTeams, item)"
                          dnd-dragstart="vm.onDragStart(vm.assignedTeams, event)"
                          dnd-moved="vm.onMoved(vm.assignedTeams)"
                          dnd-dragend="vm.assignedTeams.dragging = false"
                          dnd-selected="item.selected = !item.selected"
                          ng-class="{'selected': item.selected}"
                          ng-hide="vm.assignedTeams.dragging && item.selected">
                        {{item.teamName}} - {{item.gender | translate}} - {{item.town}}
                      </li>
                    </ul>
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </div>
        </div>
      </div>
    </md-card-content>
  </md-card>
</div>
<md-button class="md-fab md-accent md-fab-bottom-right" ng-click="vm.updateAssignedTeams()">
  <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
  <md-tooltip md-delay="50" md-direction="left">{{'SAVE' | translate}}</md-tooltip>
</md-button>
