<div layout="row" layout-align="space-around center" style="padding-top: 1%;">
    <style type="text/css">
        .fileUpload {
    position: relative;
    overflow: hidden;
    margin: 10px;
}
.fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}
        </style>

 
 
  <md-card flex-gt-md="70" flex="100">
    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline" ng-if="!vm.edit">{{'PEOPLE.VINCULED_TO_TEAM_PERSON_DATA' | translate}}</span>
          <span class="md-headline" ng-if="vm.edit">{{'PEOPLE.MODIFY_VINCULED_TO_TEAM_PERSON_DATA' | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>
         <div   width="200" height="200"  class="dropzone" file-dropzone="[image/png, image/jpeg, image/gif, image/jpg]"
                  file="image" file-name="imageFileName" data-max-file-size="3"   ng_show="vm.edit">
   
      <img id="im" ng-src={{image}} width="200" height="200" alt="Arrossegar aqui foto" style="width: 200px;height: 200px;line-height: 200px;text-align: center;padding:1px;border:1px solid #021a40;" />
       <form action>
       <div class="fileUpload btn btn-primary" >
      
    <input type="file" fileread="image" >
    </div>
  </form>
    </div>
  <div  width="200" height="200"  ng_show="!vm.edit">
  <img ng-src="{{image}}" width="200" height="200" alt="Falta fotografia&nbsp;&nbsp;&nbsp;&nbsp;" style="width: 200px;height: 200px;line-height: 200px;text-align: center;padding:1px;border:1px solid #021a40;"  />
  </div>
      <div layout-align="center center" layout="row" flex>

        <form name="personForm" ng-submit="vm.updatePerson()" flex>
          <div layout="column" layout-padding flex>
              <div layout-gt-sm="row" layout-align="space-around center">
                <neo-input name="name" label="NAME" icon="images/ic_person_black_48px.svg" is-required="true"
                           ng-model="vm.person.name" flex="45" layout-gt-sm="column" is-disabled="!vm.edit"></neo-input>
                <div layout-gt-sm="row" flex="45">
                  <neo-input name="surname" label="SURNAME" icon="images/ic_person_black_48px.svg" is-required="true"
                             ng-model="vm.person.surname" flex="50"
                             layout-gt-sm="column" is-disabled="!vm.edit"></neo-input>
                  <neo-input name="secondSurname" label="SECOND_SURNAME" ng-model="vm.person.secondSurname" flex="50"
                             layout-gt-sm="column" is-disabled="!vm.edit"></neo-input>
                </div>
              </div>

              <div layout-gt-sm="row" layout-align="space-around center">
                <div layout-gt-sm="row" flex="50">
                  <md-input-container class="md-block" flex="50">
                    <label>{{'BIRTH_DATE' | translate}}</label>
                    <md-datepicker name="birthday" ng-model="vm.person.bornDate" md-current-view="year"
                                   ng-required="true" ng-disabled="!vm.edit" flex="100"></md-datepicker>
                    <div ng-messages="personForm.birthday.$error" role="alert">
                      <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                      <div ng-message="valid">{{'DATE_INVALID_FORMAT' | translate}}</div>
                    </div>
                  </md-input-container>

                  <md-input-container class="md-block" flex="50">
                    <label>{{'GENDER' | translate}}</label>
                    <md-icon md-svg-src="images/gender-male-female.svg"></md-icon>
                    <md-select name="gender" ng-model="vm.person.genderId" ng-required="true" ng-disabled="!vm.edit">
                      <md-option ng-repeat="gender in vm.personGenders" value="{{gender.id}}">{{gender.name |
                        translate}}
                      </md-option>
                    </md-select>
                    <div ng-messages="personForm.gender.$error" role="alert">
                      <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                    </div>
                  </md-input-container>
                </div>

                <neo-input name="email" label="CONTACT_EMAIL" icon="images/ic_email_black_48px.svg"
                           ng-model="vm.person.email" error="EMAIL_INVALID_FORMAT"
                           pattern="^.+@.+\..+$" flex="45" layout-gt-sm="column" is-disabled="!vm.edit"></neo-input>
              </div>

              <div layout-gt-sm="row" layout-align="space-around center">
                <neo-input name="nif" label="NIF/NIE" icon="images/ic_person_black_48px.svg" ng-model="vm.person.nif"
                           error="INVALID_NID" flex="45" layout-gt-sm="column" is-disabled="!vm.edit"></neo-input>
                <neo-input name="hic" label="HEALTH_INSURANCE_CARD_NUMBER" icon="images/ic_healing_black_48px.svg"
                           ng-model="vm.person.healthInsuranceCardNumber" flex="45" layout-gt-sm="column"
                           is-disabled="!vm.edit"></neo-input>
              </div>

              <div layout-gt-sm="row" layout-align="space-around center">
                <neo-input name="address" label="ADDRESS" icon="images/ic_location_black_48px.svg"
                           ng-model="vm.person.address" flex="45" layout-gt-sm="column"
                           is-disabled="!vm.edit"></neo-input>
                <div layout-gt-sm="row" flex="45">
                  <neo-input name="town" label="TOWN" icon="images/ic_location_city_black_48px.svg"
                             ng-model="vm.person.town" flex="70" layout-gt-sm="column"
                             is-disabled="!vm.edit"></neo-input>
                  <neo-input name="zipCode" label="POSTAL_CODE" ng-model="vm.person.zipCode"
                             error="INVALID_POSTAL_CODE" pattern="^[0-9]{5}$"
                             flex="30" layout-gt-sm="column" is-disabled="!vm.edit"></neo-input>
                </div>
              </div>

              <h1 class="md-title">{{'ASSOCIATIONS' | translate}}</h1>
              <md-divider layout-padding></md-divider>
              <div layout-gt-sm="row" layout-align="space-around center">
                <neo-input name="licenseType" label="LICENSE_TYPE" ng-model="vm.person.licenseType" flex="45"
                           layout-gt-sm="column" is-disabled="true"></neo-input>
                <neo-input name="licenseCode" label="LICENSE_CODE" ng-model="vm.person.licenceCode" flex="45"
                           layout-gt-sm="column" is-disabled="true"></neo-input>
              </div>
              <div layout-gt-sm="row" layout-align="space-around center">
                <neo-input name="entityName" label="ENTITY" ng-model="vm.team.organisationName" flex="45"
                           layout-gt-sm="column" is-disabled="true"></neo-input>
                <neo-input name="teamInfo" label="TEAM" ng-model="vm.team.info" flex="45"
                           layout-gt-sm="column" is-disabled="true"></neo-input>
              </div>
          </div>
          <div flex>
            <md-button class="md-fab md-accent md-fab-bottom-right"
                       ng-disabled="(personForm.$invalid || personForm.$pristine)&& DISABLEBUTTON" type="submit">
              <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
              <md-tooltip md-delay="50" md-direction="left">{{'SAVE' | translate}}</md-tooltip>
            </md-button>
          </div>
          <div flex>
            <md-button class="md-fab md-warn md-fab-bottom-right"
                       ng-if="vm.edit" ng-click="vm.disableEdit()" style="margin-bottom: 72px;">
              <md-icon md-svg-src="images/ic_cancel_white_48px.svg" class="s24" aria-label="cancel"></md-icon>
              <md-tooltip md-delay="50" md-direction="left">{{'CANCEL' | translate}}</md-tooltip>
            </md-button>
          </div>
        </form>
        <neo-print-card-template id="cardsPrintPreview" ng-hide="true" cards="vm.cards"></neo-print-card-template>
      </div>
    </md-card-content>
  </md-card>
</div>
