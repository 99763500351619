<div layout="row" class="lock-size" layout-align="end right" ng-cloak="">
  <md-fab-speed-dial md-open="false" md-direction="up" class="md-fab-bottom-right md-fling md-scale"
                     ng-if="vm.buttons.dial">
    <md-fab-trigger>
      <md-button aria-label="menu" class="md-fab md-accent">
        <md-icon md-svg-src="{{vm.buttons.buttonImage}}" class="s24" aria-label="more"></md-icon>
        <md-tooltip md-delay="50" md-direction="left">{{ vm.buttons.tooltip }}</md-tooltip>
      </md-button>
    </md-fab-trigger>
    <md-fab-actions>
      <ng-repeat ng-repeat="action in vm.buttons.actions">
        <a ui-sref="{{action.stateToChange}}" ng-if="action.changeState">
        <md-button aria-label="" class="md-fab md-raised md-mini" ng-class="[action.attrClass]">
          <md-icon md-svg-src="{{action.buttonImage}}" class="s24" aria-label=""></md-icon>
          <md-tooltip md-delay="50" md-direction="left">{{ action.tooltip }}</md-tooltip>
        </md-button>
        </a>
        <md-button aria-label="" class="md-fab md-raised md-mini" ng-class="[action.attrClass]"
                   ng-click="action.execute()" ng-if="!action.changeState">
          <md-icon md-svg-src="{{action.buttonImage}}" class="s24" aria-label=""></md-icon>
          <md-tooltip md-delay="50" md-direction="left">{{ action.tooltip }}</md-tooltip>
        </md-button>
      </ng-repeat>
    </md-fab-actions>
  </md-fab-speed-dial>
</div>
