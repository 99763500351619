<div layout="row" layout-align="center center" ui-view style="padding-top: 2%">

  <md-card flex-gt-md="50" flex-xs="100" flex="70">
    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline" ng-if="!vm.edit">{{'CHAMPIONSHIPS.DATA' | translate}}</span>
          <span class="md-headline" ng-if="vm.edit">{{'CHAMPIONSHIPS.MODIFY_DATA' | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>


      <div layout="column" layout-gt-sm="row">
        <div style="min-width: 75%;" flex>
          <div layout-align="center center" layout="row">
            <div flex>
              <form name="championshipForm" ng-submit="vm.updateChampionship()">
                <div layout="column" layout-padding>
                  <div>

                    <div layout-gt-sm="row" layout-align="space-around none" flex>
                      <!-- NAME -->
                      <neo-input name="name" label="CHAMPIONSHIPS.NAME" icon="images/ic_title_black_48px.svg"
                                 is-required="true"
                                 ng-model="vm.championship.name" flex="45" layout-gt-sm="column"
                                 is-disabled="!vm.edit"></neo-input>
                      <!-- PHASE -->
                      <md-input-container class="md-block" flex="45">
                        <label>{{'CHAMPIONSHIPS.PHASE' | translate}}</label>
                        <md-select name="phase" ng-model="vm.championship.phaseId" ng-required="true"
                                   ng-disabled="!vm.edit || vm.championship.hasTeams">
                          <md-option ng-repeat="phase in vm.phases" value="{{phase.id}}">
                            {{phase.name | translate }}
                          </md-option>
                        </md-select>
                        <div ng-messages="championshipForm.phase.$error" role="alert">
                          <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                        </div>
                      </md-input-container>
                    </div>
                    <div layout-gt-sm="row" layout-align="space-around center" flex>
                      <!-- PROGRAMME TYPE -->
                      <md-input-container class="md-block" flex="45">
                        <label>{{'PROGRAM_TYPE' | translate}}</label>
                        <md-select name="programme" ng-model="vm.championship.programmeId" ng-required="true"
                                   ng-disabled="true">
                          <md-option ng-repeat="programme in vm.programmes" value="{{programme.id}}">
                            {{programme.name | translate }}
                          </md-option>
                        </md-select>
                        <div ng-messages="championshipForm.programme.$error" role="alert">
                          <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                        </div>
                      </md-input-container>
                      <md-input-container class="md-block" flex="45">
                        <label>{{'CHAMPIONSHIPS.TYPE' | translate}}</label>
                        <md-select name="type" ng-model="vm.championship.typeId" ng-required="true" ng-disabled="true">
                          <md-option ng-repeat="type in vm.championshipTypes" value="{{type.id}}">
                            {{type.name | translate}}
                          </md-option>
                        </md-select>
                        <div ng-messages="championshipForm.type.$error" role="alert">
                          <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                        </div>
                      </md-input-container>
                    </div>
                    <div layout-gt-sm="row" layout-align="space-around center" flex>
                      <!-- SPORT -->
                      <md-input-container class="md-block" flex="45">
                        <label>{{'SPORT' | translate}}</label>
                        <md-icon md-svg-src="images/football.svg"></md-icon>
                        <md-select name="sport" ng-model="vm.championship.sportId" ng-required="true"
                                   ng-disabled="!vm.edit || vm.championship.hasTeams">
                          <md-option ng-repeat="sport in vm.sports" value="{{sport.id}}">
                            {{'SPORTS.' + sport.name | translate }}
                          </md-option>
                        </md-select>
                        <div ng-messages="championshipForm.sport.$error" role="alert">
                          <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                        </div>
                      </md-input-container>
                      <div layout-gt-sm="row" layout-align="space-between center" flex="45">
                        <!-- JVP -->
                        <md-checkbox ng-model="vm.championship.jvp" aria-label="verd-play"
                                     ng-disabled="!vm.edit || vm.championship.hasBeenDrawn">
                          {{'CHAMPIONSHIPS.VPLAY' | translate}}
                        </md-checkbox>
                        <!-- ROUNDS -->
                        <neo-input name="rounds" label="CHAMPIONSHIPS.ROUNDS" is-required="true"
                                   ng-model="vm.championship.rounds"
                                   error="CHAMPIONSHIPS.MAX_ROUNDS" layout-gt-sm="column"
                                   icon="images/ic_repeat_black_48px.svg"
                                   pattern="^([0-9])$"
                                   is-disabled="!vm.edit || vm.championship.hasBeenDrawn"></neo-input>
                         <md-checkbox ng-model="vm.championship.invisible" aria-label="invisible"  ng-disabled="!vm.edit">
                      {{'CHAMPIONSHIPS.INVISIBLE' | translate}}
                    </md-checkbox>
                      </div>
                    </div>
                    <div layout-gt-sm="row" layout-align="space-around center" flex>
                      <div layout-gt-sm="row" flex="45">
                        <!-- CATEGORY -->
                        <md-input-container class="md-block" flex="50">
                          <label>{{'CATEGORY' | translate}}</label>
                          <md-icon md-svg-src="images/ic_folder_black_48px.svg"></md-icon>
                          <md-select name="category" ng-model="vm.championship.categoryId" ng-required="true"
                                     ng-disabled="!vm.edit || vm.championship.hasTeams">
                            <md-option ng-repeat="category in vm.categories" value="{{category.id}}">
                              {{'CATEGORIES.'+category.name | translate}}
                            </md-option>
                          </md-select>
                          <div ng-messages="championshipForm.category.$error" role="alert">
                            <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                          </div>
                        </md-input-container>
                        <!-- GENDER -->
                        <md-input-container class="md-block" flex="50">
                          <label>{{'GENDER' | translate}}</label>
                          <md-icon md-svg-src="images/gender-male-female.svg"></md-icon>
                          <md-select name="gender" ng-model="vm.championship.genderId" ng-required="true"
                                     ng-disabled="!vm.edit || vm.championship.hasTeams">
                            <md-option ng-repeat="gender in vm.genders"
                                       value="{{gender.id}}">{{gender.name | translate}}
                            </md-option>
                          </md-select>
                          <div ng-messages="championshipForm.gender.$error" role="alert">
                            <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                          </div>
                        </md-input-container>
                      </div>
                      <!-- SCORES -->
                      <div layout-gt-sm="row" flex="45">
                        <neo-input name="victory_points" label="CHAMPIONSHIPS.SCORE.VICTORY"
                                   is-required="!vm.championship.jvp"
                                   ng-model="vm.championship.winPoints" flex layout-gt-sm="column"
                                   error="CHAMPIONSHIPS.SCORE.ERROR"
                                   is-disabled="vm.championship.jvp || !vm.edit || vm.championship.hasBeenDrawn"
                                   pattern="^([0-9]+)$"
                                   ng-show="!vm.championship.jvp"></neo-input>
                        <neo-input name="tie_points" label="CHAMPIONSHIPS.SCORE.TIE" is-required="!vm.championship.jvp"
                                   ng-model="vm.championship.drawPoints" flex layout-gt-sm="column"
                                   error="CHAMPIONSHIPS.SCORE.ERROR"
                                   is-disabled="vm.championship.jvp || !vm.edit || vm.championship.hasBeenDrawn"
                                   pattern="^([0-9]+)$"
                                   ng-show="!vm.championship.jvp"></neo-input>
                        <neo-input name="loss_points" label="CHAMPIONSHIPS.SCORE.LOSS"
                                   is-required="!vm.championship.jvp"
                                   ng-model="vm.championship.lostPoints" flex layout-gt-sm="column"
                                   error="CHAMPIONSHIPS.SCORE.ERROR"
                                   is-disabled="vm.championship.jvp || !vm.edit || vm.championship.hasBeenDrawn "
                                   pattern="^([0-9]+)$"
                                   ng-show="!vm.championship.jvp"></neo-input>
                      </div>
                    </div>

                    <div layout-gt-sm="row" layout-align="center center" flex>
                      <!-- EDITABLE MATCHES -->
                      <md-switch ng-model="vm.championship.editableMatches" aria-label="editable-matches"
                                 ng-if="vm.isAdmin()" ng-disabled="!vm.edit">
                        {{'CHAMPIONSHIPS.EDITABLE_MATCHES' | translate}}
                      </md-switch>
                      <span class="md-body-1" md-colors="{color:'warn'}"
                            ng-if="!vm.championship.editableMatches && !vm.isAdmin()">{{'CHAMPIONSHIPS.ALERT_ASSIGN_MATCHES_PERIOD' | translate}}</span>
                    </div>

                  </div>
                </div>
                <div flex>
                  <md-button type="submit" class="md-fab md-accent md-fab-bottom-right" ng-if="vm.edit"
                             ng-disabled="championshipForm.$invalid">
                    <md-icon class="material-icons md-24" aria-label="save">save</md-icon>
                  </md-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </md-card-content>
  </md-card>

  <neo-action-button buttons="vm.buttons" ng-if="vm.buttons.actions.length > 0"></neo-action-button>
</div>
