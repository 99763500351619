<div layout="row" layout-align="center center" flex layout-padding>
  <div class="md-whiteframe-2dp" flex="75" layout-padding>
    <h1 class="md-title">{{'USER_DATA' | translate}}</h1>
    <md-divider></md-divider>
    <form novalidate name="userProfileForm" ng-submit="vm.updateEmail()">
      <div layout="row">
        <div flex="33" layout="row" layout-align="center" layout-padding>
          <img
            src="http://www.motorolasolutions.com/content/dam/msi/images/business/products/accessories/_images/_staticfiles/b2b_product_placeholder_lg_us-en.jpg"
            style="width: 168px; height: 168px">
        </div>
        <div layout="row" flex="66">
          <div layout="column" flex="50">
            <md-input-container class="md-block">
              <label>Email</label>
              <input title="email" required ng-pattern="/^.+@.+\..+$/" ng-model="vm.profile.email"
                     ng-disabled="!vm.edit">
              <div ng-messages="editForm.email.$error" role="alert">
                <div ng-message-exp="['required', 'pattern']">
                  Aquest camp és obligatori i ha de ser com: example@example.com
                </div>
              </div>
            </md-input-container>
          </div>
        </div>
      </div>
      <md-button type="submit" class="md-fab md-accent md-fab-bottom-right" ng-if="vm.edit"
                 ng-disabled="userProfileForm.$invalid">
        <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
      </md-button>
    </form>
  </div>
</div>
</div>
