<div layout="row" layout-align="space-around center">
  <md-card flex-sm="90" flex-lg="50" flex-md="65">
    <div class="md-whiteframe-1dp" flex>
      <md-card-header>
        <md-card-header-text>
          <span class="md-title">{{'LOGIN' | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>
      <form name="logInForm" ng-submit="vm.login()">
        <div layout="column" layout-align="center stretch" class="horizontal-padding" flex-gt-md="70"
             flex-offset-gt-md="15">
          <md-input-container>
            <md-icon md-svg-src="images/ic_email_black_48px.svg"></md-icon>
            <label>Email</label>
            <input type="email" name="email" ng-model="vm.email" ng-required="true" ng-pattern="/^.+@.+\..+$/">
            <div ng-messages="logInForm.email.$error" role="alert">
              <div ng-message-exp="['required','pattern']">{{'EMAIL_INVALID_FORMAT' | translate}}</div>
            </div>
          </md-input-container>

          <md-input-container>
            <md-icon md-svg-src="images/ic_lock_black_48px.svg"></md-icon>
            <label>{{'PASSWORD' | translate}}</label>
            <input type="password" name="password" autocomplete="on" ng-model="vm.password" ng-required="true" ng-pattern="/^.{4,}?/">
          </md-input-container>

          <div layout="column" layout-align="center start" layout-padding>
            <a ui-sref="authentication.reset" class="link-style no-padding">{{'RESET_PASSWORD' | translate}}</a>
          </div>

          <div layout="column" layout-align="center end" flex>
            <md-button class="md-raised md-primary md-button" type="submit"
                       ng-disabled="logInForm.$invalid">
              <md-icon md-svg-src="images/ic_exit_white_48px.svg" class="s24" aria-label="logIn"></md-icon>
              {{'LOGIN' | translate}}
              <md-tooltip md-delay="50" md-direction="left">{{'LOGIN' | translate}}</md-tooltip>
            </md-button>
          </div>
        </div>
      </form>
    </md-card-content>
  </md-card>
</div>
