<div layout="row" layout-padding layout-align="space-around center" flex>
  <md-card class="md-whiteframe-z2" flex-gt-md="100" flex="100">
    <md-card-header layout-padding class="md-whiteframe-1dp">
      <md-card-header-text>
        <span class="md-headline">{{ 'CHAMPIONSHIPS.ASSIGN_MATCHES' | translate}}</span>
      </md-card-header-text>
    </md-card-header>
    <md-card-content layout="column" layout-align="space-around stretch">

      <div flex>
        <h1 class="md-title">{{'CHAMPIONSHIPS.CHAMPIONSHIP' | translate}}:&nbsp;&nbsp;{{vm.championship.name}}</h1>
        <md-divider></md-divider>
        <div layout="row" layout-align="space-around none" layout-padding layout-wrap>
          <!-- NAME -->
          <neo-input name="name" label="CHAMPIONSHIPS.NAME" icon="images/ic_title_black_48px.svg"
                     ng-model="vm.championship.name" flex="25" is-disabled="true"></neo-input>
          <!-- CATEGORY -->
          <neo-input name="category" label="CATEGORY" icon="images/ic_folder_black_48px.svg"
                     ng-model="vm.category" flex="25" is-disabled="true"></neo-input>
          <!-- SPORT -->
          <neo-input name="sport" label="SPORT" icon="images/football.svg"
                     ng-model="vm.sport" flex="25" is-disabled="true"></neo-input>
          <!-- ROUNDS -->
          <neo-input name="gender" label="GENDER" icon="images/gender-male-female.svg"
                     ng-model="vm.gender" flex="25" is-disabled="true"></neo-input>
        </div>
      </div>

      <div flex id="matches">
        <h1 class="md-title">{{'CHAMPIONSHIPS.MATCHES' | translate}}</h1>
        <md-divider></md-divider>

        <form name="matchesForm" layout="column" layout-wrap layout-padding>

          <div class="matchday-container" ng-repeat="matchDay in vm.matchDays" flex>
            <md-list layout="column" flex>
              <div class="match-header" md-colors="{background: 'primary'}" layout-padding flex>
                <span class="md-title">{{'CHAMPIONSHIPS.MATCH_DAY' | translate}} {{matchDay.number}}</span>
              </div>
              <md-list-item class="match-container" ng-repeat="match in matchDay.matches"
                            layout-xs="column" layout-sm="column" layout-md="row" layout-gt-md="row"
                            layout-align="space-around stretch" layout-fill layout-wrap
                            md-colors="::{background: {{ $odd ? '\'primary-50\'': '\'background\''}}}">

                <div flex="50" layout="row" layout-align="space-around stretch" layout-fill layout-wrap>
                  <md-input-container md-no-float flex="35">
                    <input type="text" ng-readonly="true" class="no-border"
                           placeholder="{{vm.getTeamName(match.localTeamId) || ('CALENDAR.REST' | translate)}}"
                           value="{{vm.getTeamName(match.localTeamId) || ('CALENDAR.REST' | translate)}}"/>
                  </md-input-container>
                  <div ng-if="!vm.championship.jvp" flex layout="row" layout-align="space-around stretch" layout-fill layout-wrap >
                    <md-input-container md-no-float flex="45">
                      <input type="number" step="1" ng-model="match.localScore" min="0"
                             placeholder="{{'SCORE' | translate}}" ng-disabled="!vm.canEditScore(match)"/>
                    </md-input-container>
                    <md-input-container md-no-float flex>
                      <input type="text" ng-readonly="true" class="no-border" value="-" placeholder="versus"/>
                    </md-input-container>
                    <md-input-container md-no-float flex="45">
                      <input type="number" step="1" ng-model="match.visitorScore" min="0"
                             placeholder="{{'SCORE' | translate}}" ng-disabled="!vm.canEditScore(match)"/>
                    </md-input-container>
                  </div>
                  <div ng-if="vm.championship.jvp" flex layout="row" layout-align="space-around stretch" layout-fill layout-wrap >
                    <md-input-container md-no-float flex>
                      <input type="text" ng-readonly="true" class="no-border" value="-" placeholder="versus"/>
                    </md-input-container>
                  </div>
                  <md-input-container md-no-float flex="35">
                    <input type="text" ng-readonly="true" class="no-border"
                           placeholder="{{vm.getTeamName(match.visitorTeamId) || ('CALENDAR.REST' | translate)}}"
                           value="{{vm.getTeamName(match.visitorTeamId) || ('CALENDAR.REST' | translate)}}"/>
                  </md-input-container>
                </div>

                <div flex="25" layout="row" layout-align="space-around stretch" layout-fill>
                  <md-input-container flex="60">
                    <md-datepicker name="date" ng-model="match.date" ng-required="true"
                                   md-placeholder="Enter date" ng-disabled="!vm.canEditDate(match)"></md-datepicker>
                    <div ng-messages="matchesForm.iniDate.$error" role="alert">
                      <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                    </div>
                  </md-input-container>
                  <md-input-container flex="40" md-no-float>
                    <label>hh:mm</label>
                    <input type="time" ng-model="match.time"   ng-disabled="!vm.canEditTime(match)"/>
                  </md-input-container>
                </div>

                <div flex="25" layout="row" layout-align="space-around stretch" layout-fill layout-wrap>
                  <md-input-container flex>
                    <md-select ng-model="match.facilityId" placeholder="{{'CALENDAR.FACILITY' | translate}}"
                               ng-required="true" md-no-asterisk="true" ng-disabled="!vm.canEditFacility(match)">
                      <md-option ng-value="null">
                        <em>{{'CALENDAR.NOT_FACILITY_ASSIGNED' | translate}}</em>
                      </md-option>
                      <md-option ng-repeat="(id, facility) in vm.getMatchFacilities(match)" ng-value="id">
                       {{facility.townName}} - {{facility.name}}
                      </md-option>
                    </md-select>
                  </md-input-container>
                </div>
              </md-list-item>
            </md-list>
          </div>
        </form>
      </div>

    </md-card-content>
  </md-card>
  <div flex>
    <md-button class="md-fab md-accent md-fab-bottom-right" ng-disabled="matchesForm.$invalid"
               ng-click="vm.saveMatches()">
      <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
      <md-tooltip md-delay="50" md-direction="left">{{'SAVE' | translate}}</md-tooltip>
    </md-button>
  </div>

</div>
