<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/>
<div layout="column" ui-view>
  <div layout="row" layout-align="space-around center" layout-padding>
    <form ng-submit="vm.applyFilters()" name="listFilters" layout="row" flex-gt-md="70" flex="100"
          layout-align="space-around center">
     
      <md-datepicker md-current-view="year" ng-required="true" ng-model="vm.iniDate"
                     md-placeholder="{{'INI_DATE' | translate}}" md-min-date="vm.currentSeason.iniDate"
                     md-max-date="vm.endDate"></md-datepicker>
    
      <md-button type="submit" class="md-raised md-primary">{{'FILTER' |
        translate}}
      </md-button>
    </form>
    
  </div>



   

    <div layout="row" layout-align="space-around center" ng-if="vm.isFiltered">
    <md-card flex-gt-md="80" flex="100">

      <div class="md-whiteframe-1dp" flex>
             <md-button ng-click="exportToExcel('#table1')">
                 <md-icon md-svg-src = '../../images/notebook.svg'></md-icon>
            </md-button> 
        <md-card-header layout-padding>
          <md-card-header-text>
            <span class="md-headline">Partits arbitrats</span> 
          </md-card-header-text>
        </md-card-header>
      </div>
      <md-card-content>
        <md-table-container>
          <table  md-table ng-model="vm.selected"  id="table1" >
            <thead md-head md-order="vm.query.order">
                
                
            <tr md-row ng-hide="!vm.filteredList.length">
               
           
              <th md-column md-order-by="cognom1"><span class="md-title">Cognom</span></th>
            
                <th md-column md-order-by="nom"><span class="md-title">Nom</span></th>
             
              <th md-column show-gt-xs hide><span class="md-title">DNI</span></th>
              <th md-column show-gt-xs hide><span class="md-title">Adreça</span></th>
               <th md-column show-gt-xs hide><span class="md-title">Municipi</span></th>
             <th md-column show-gt-xs hide><span class="md-title">Base 1er trim</span></th>
                 <th md-column show-gt-xs hide><span class="md-title">% Retenció 1er trim</span></th>
                   <th md-column show-gt-xs hide><span class="md-title">Import Retenció 1er trim</span></th>
                    <th md-column show-gt-xs hide><span class="md-title">Base 2on trim</span></th>
                 <th md-column show-gt-xs hide><span class="md-title">% Retenció 2on trim</span></th>
                   <th md-column show-gt-xs hide><span class="md-title">Import Retenció 2on trim</span></th>
                    <th md-column show-gt-xs hide><span class="md-title">Base 3er trim</span></th>
                 <th md-column show-gt-xs hide><span class="md-title">% Retenció 3er trim</span></th>
                   <th md-column show-gt-xs hide><span class="md-title">Import Retenció 3er trim</span></th>
                    <th md-column show-gt-xs hide><span class="md-title">Base 4t trim</span></th>
                 <th md-column show-gt-xs hide><span class="md-title">% Retenció 4t trim</span></th>
                   <th md-column show-gt-xs hide><span class="md-title">Import Retenció 4t trim</span></th>
                     <th md-column show-gt-xs hide><span class="md-title">Acumulat anual base</span></th>
                 <th md-column show-gt-xs hide><span class="md-title">Acumulat anual retenció</span></th>
            </tr>
            </thead>
            <tbody md-body>
            <tr md-row md-select="vm.select"
                ng-repeat="persona in vm.filteredList = (vm.llistatpersones | filter:searchText ) | orderBy: vm.query.order">
             
          
                 
                 <td md-cell><span class="md-body-2">{{ persona.cognom1 }} {{ persona.cognom2 }} </span></td>
                
              <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.nom }}</span></td>
          
            <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.dni }}</span></td>
             <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.adress}}</span></td>
             <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.municipi}}</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.base1}}</span></td>
             <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.irpf_tipo_1}}%</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.import_irpf_1}}</span></td>
                <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.base2}}</span></td>
             <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.irpf_tipo_2}}%</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.import_irpf_2}}</span></td>
                <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.base3}}</span></td>
             <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.irpf_tipo_3}}</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.import_irpf_3}}</span></td>
                <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.base4}}</span></td>
             <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.irpf_tipo_4}}%</span></td>
              <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.import_irpf_4}}</span></td>
                    <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.base_acumulada}}</span></td>
                     <td md-cell show-gt-xs hide><span class="md-body-2">{{ persona.retencio_acumulada}}</span></td>
            </tr>
            </tbody>
          </table>
        </md-table-container>
        <span class="md-body-2" ng-hide="vm.filteredList.length"> No s'han trobat dades </span>
      </md-card-content>
    </md-card>
</div>
    <md-button class="md-fab md-accent md-fab-bottom-right" ng-click="vm.imprimir()" >
            <md-icon md-svg-src="images/baseline-import_export-24px.svg" class="s24"
                     aria-label="Exportar a SAGE"></md-icon>
            <md-tooltip md-delay="50" md-direction="left">Exportar a SAGE</md-tooltip>
          </md-button>
</div>
            <div>
 
</div>
<div ng-hide="true">
 <neo-print-invoice-template id="invoicePrintPreview" ng-hide="true" persona="vm.persona"></neo-print-invoice-template>
</div>