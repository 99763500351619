<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <style>
   body {
    margin: 0 !important;
    -webkit-print-color-adjust: exact;
  }

  .cards-container {
    position: absolute;
     height: 470mm;
    margin: 0mm 0mm 0mm 100mm !important;
  }

  .page-margin-top {
    margin-top: 0mm !important;
  }

  .card-dimensions {
    width: 73mm;
    height: 47mm;
    /*border: 1px solid rgba(0, 0, 0, 0.2);*/
    margin-top: 222mm;
    margin-right: 4mm;
    
    float: left;
    display: block;
  }

  .card-dimensions > .card-data {
    padding: 3mm;
    width: 60%;
    float: left;
  }

  .card-dimensions > .card-images {
    float: right;
    width: 30%;
    height: 100%;
  }

  .card-dimensions > .card-images .card-logo {
    float: right;
    padding: 2mm 2mm 2mm 0;
    width: 80%;
    height: auto;
  }

  .card-dimensions > .card-images .card-photo {
    float: right;
    margin: 0 2mm 0 2mm;
    width: 20mm;
    height: 28mm;
    border: 0px solid rgba(0, 0, 0, 0);
  }

  .cards-container p {
    font-size: 2.2mm;
    line-height: 2.3mm;
  }


  .cards-container p {
    font-size: 2.2mm;
    line-height: 2.3mm;
  }
div.break-after {
   display: block; /* unhide all tabs */
    break-before: always;
    page-break-before: always;
}


.break-before {
    display: block; /* unhide all tabs */
    break-before: always;
    page-break-before: always;

}
</style>
</head>

<body>
<div class="cards-container">
   
  <div ng-repeat="card in vm.cards" class="card-dimensions">
      <div class="break-after" ></div>
    <div class="card-data">
      <p>{{'NAME' | translate | uppercase }}: {{card.name}}&nbsp;&nbsp;&nbsp;&nbsp;{{'PROGRAMME' | translate | uppercase }}: JJEE</p>
      <p>{{'SURNAMES' | translate | uppercase}}: {{card.surname}}</p>
      <p>{{card.birthday}} - {{'CATEGORIES.' + card.personCategory | translate}}</p>
      <p>{{'CALENDAR.ENTITY_NAME' | translate | uppercase }}: {{card.entity}}</p>
      <p>{{'TEAM' | translate | uppercase }}: {{card.team}}</p>
      <p>{{'CATEGORY' | translate | uppercase}} {{'TEAM' | translate | uppercase}}: {{'CATEGORIES.' + card.teamCategory | translate}}</p>
      <p>{{'SPORTS.' + card.sport | translate}} / {{'PERSON_TYPES.' + card.licenceType | translate}}</p>
      <p>{{'SCHOOL_YEAR' | translate | uppercase}}: {{card.season.initDate}} - {{card.season.endDate}}</p>
      <p>{{'TOWN_HALL' | translate | uppercase }}: {{card.townHall}}</p>
      <p>{{'LICENCE_CODE_SHORT' | translate | uppercase}}: {{card.licenseCode}}</p>
    </div>
    <div class="card-images">
      <img class="card-logo" src="images/consell.png">
      <div class="card-photo"> <img ng-src="{{card.foto[0]}}"  alt="" style=" width: 25mm; height: 35mm;"  />
      </div>
    </div>
   
      	
  </div>
</div>
</body>
</html>
