<md-toolbar class="md-whiteframe-1dp" layout="row" layout-align="space-between center"
            md-colors="{backgroundColor: 'background'}">
  <div flex layout="row" class="md-toolbar-tools" layout-margin layout-align="start center">
    <a ui-sref="home" style="height: 75%">
      <img src="images/neo.png" alt="Logo NEO" height="100%">
    </a>
    <a href="http://cebllob.org" style="height: inherit" target="_blank">
      <img src="images/consell.png" alt="Logo CEBLLOB" height="100%">
    </a>
    <a href="http://cebllob.org/?page_id=140" style="height: inherit"
       target="_blank">
      <img src="images/juga-verd-play.png" alt="Logo Juga Verd Play" height="100%">
    </a>
  </div>

  <div flex layout="row" layout-margin layout-align="start center">
    <md-button class="md-primary" ui-sref="public.calendar">
      <md-icon>date_range</md-icon>
      {{'CALENDAR.TITLE' | translate}}
    </md-button>
  </div>

  <div flex layout="row" layout-align="end center">
    <div>
      <md-menu ng-if="vm.authManager.isAuthenticated() && !vm.$state.includes('public')" md-position-mode="target-right bottom">
        <md-button ng-click="vm.openMenu($mdMenu, $event)">
          <md-icon md-colors="{color: 'grey'}">person</md-icon>
          <span class="black-font">{{userName}}</span>
        </md-button>
        <md-menu-content>
          <md-menu-item>
            <md-button ui-sref="home.changePassword">
              <md-icon>lock</md-icon>
              <span>{{'CHANGE_PASSWORD' | translate}}</span>
            </md-button>
          </md-menu-item>
          <md-menu-item>
            <md-button ui-sref="home.changeUsername">
              <md-icon>email</md-icon>
              <span>{{'CHANGE_EMAIL' | translate}}</span>
            </md-button>
          </md-menu-item>
        </md-menu-content>
      </md-menu>
    </div>
    <div>
      <md-button ng-if="!vm.authManager.isAuthenticated()" class="md-raised md-primary" aria-label="LogIn"
                 ui-sref="authentication.login">
        <md-icon>exit_to_app</md-icon>
        {{'LOGIN' | translate}}
      </md-button>
      <md-button ng-if="vm.authManager.isAuthenticated()" class="md-raised md-primary" aria-label="LogOut"
                 ng-click="logout()">
        <md-icon>exit_to_app</md-icon>
        {{'LOGOUT' | translate}}
      </md-button>
    </div>
  </div>
</md-toolbar>
