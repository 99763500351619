<!DOCTYPE html>
<!--
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
-->
<table>
    <thead>
        <tr>
             <th> Id</th>
            <th> Non</th>
            <th> Cognom</th>
            <th> email</th>
            <th> Població</th>
               <th> Campionat</th>
               <th> Data</th>
                <th> Equip local</th>
                 <th> Equip Visitant</th>
             <th> TècL</th>
             <th> TècV</th>
              <th> PúbL</th>
               <th> PúbV</th>
                <th> VPL</th>
               <th> VPV</th>
                <th> TVL</th>
               <th> TVV</th>
        </tr>
    </thead>
    <tbody>
        <tr ng-repeat="partit in partidos">
             <td> {{partit.idPartido}}</td>
            <td> {{partit.nombre}}</td>
            <td> {{partit.apellido}}</td>
            <td> {{partit.email}}</td>
            <td> {{partit.poblacion}}</td>
            <td> {{partit.campionat}}</td>
              <td> {{partit.fecha}}</td>
              <td> {{partit.elocal}}</td>
                <td> {{partit.evisitant}}</td>
            <td> {{partit.tecnicL}}</td>
             <td> {{partit.tecnicV}}</td>
             <td> {{partit.publicL}}</td>
             <td> {{partit.publicV}}</td>
              <td> {{partit.puntsvpL}}</td>
              <td> {{partit.puntsvpV}}</td>
              <td> {{partit.tvL}}</td>
              <td> {{partit.tvV}}</td>
    </tr>
        
    </tbody>   
</table>
