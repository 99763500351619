<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <style>

</style>
</head>

<body>
    <header>
			<h1>Factura</h1>
                        <p><u>Dades de l'emissor</u></p>
                        <br>
			<address contenteditable>
                            
				<p>{{persona2.name}}</p>
				<p>101 E. Chapman Ave<br>Orange, CA 92866</p>
				<p>(800) 555-1234</p>
			</address>
			<span></span>
		</header>
    	<article>
			   <p><u>Dades del preceptor</u></p>
                        <br>
			<address contenteditable>
				<p>CONSELL ESPORTIU DEL BAIX LLOBREGAT
                                    <br>Crta. N-340, km 1249 (ParcTorreblanca)
                                    <br>08980 - Sant Feliu de Llobregat
                                <br>G-58014838
                                <br>936852039</p>
			</address>
			<table class="meta">
				<tr>
					<th><span contenteditable>Factura número</span></th>
					<td><span contenteditable>101138</span></td>
				</tr>
				<tr>
					<th><span contenteditable>Data</span></th>
					<td><span contenteditable>28/03/2017</span></td>
				/</tr>
				<tr>
					<th><span contenteditable>Total</span></th>
					<td><span>600.00</span><span id="prefix" contenteditable> €</span></td>
				</tr>
			</table>
			<table class="inventory">
				<thead>
					<tr>
						<th><span contenteditable>Acció</span></th>
						
						<th><span contenteditable>Partit</span></th>
						<th><span contenteditable>€/Parit</span></th>
						<th><span contenteditable>Total</span></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td><a class="cut">-</a><span contenteditable>Front End Consultation</span></td>
						
                                                <td><span contenteditable>4</span></td>
						<td><span contenteditable>150.00</span><span data-prefix> €</span></td>
						<td><span>600.00</span><span data-prefix> €</span></td>
					</tr>
				</tbody>
			</table>
			<a class="add">+</a>
			<table class="balance">
				<tr>
					<th><span contenteditable>Base</span></th>
					<td><span>600.00</span><span data-prefix> €</span></td>
				</tr>
				<tr>
					<th><span contenteditable>I.R.P.F. -15%</span></th>
					<td><span contenteditable>0.00</span><span data-prefix> €</span></td>
				</tr>
				<tr>
					<th><span contenteditable>TOTAL</span></th>
					<td><span>600.00</span><span data-prefix> €</span></td>
				</tr>
			</table>
		</article>
    
    	<aside>
			<h1><span contenteditable>Forma de pagament</span></h1>
			<div contenteditable>
				<p>Tranferència Bancària: </p>
			</div>
		</aside>

</body>
</html>
