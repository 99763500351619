<div layout="row" layout-align="space-around center">
  <md-card flex-sm="90" flex-lg="50" flex-md="65">
    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline">{{vm.cardTitle | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>
      <form name="restorePasswordForm" ng-submit="vm.sendRestoreEmail()">
        <div layout="column" layout-align="center stretch" class="horizontal-padding" flex-gt-md="70"
             flex-offset-gt-md="15">
          <p class="md-body-2">{{'EMAIL_RESET_PASSWORD' | translate}}</p>
          <md-input-container>
            <label>{{'EMAIL' | translate}}</label>
            <md-icon md-svg-src="images/ic_email_black_48px.svg"></md-icon>
            <input type="email" name="email" ng-model="vm.email" ng-required="true" ng-pattern="/^.+@.+\..+$/">
            <div ng-messages="restorePasswordForm.email.$error" role="alert">
              <div ng-message-exp="['required','pattern']">{{'EMAIL_INVALID_FORMAT' | translate}}</div>
            </div>
          </md-input-container>
        </div>
        <div layout="column" layout-align="center end" flex>
          <md-button class="md-raised md-primary" ng-disabled="restorePasswordForm.$invalid" type="submit">
            <md-icon md-svg-icon="images/ic_exit_white_48px.svg"></md-icon>
            {{'SEND' | translate}}
          </md-button>
        </div>
      </form>
    </md-card-content>
  </md-card>
</div>
