<md-input-container ng-class="{'no-margin-neo-input': noMargin}">
  <label>{{label | translate}}</label>
  <md-icon ng-if="icon" md-svg-src="{{icon}}"></md-icon>
  <md-icon ng-if="neoIcon" class="material-icons md-dark md-inactive">{{neoIcon}}</md-icon>
  <input name="{{name}}" type="text" ng-required="isRequired" ng-model="ngModel" ng-pattern="vm.pattern"
         ng-disabled="isDisabled" ng-readonly="isReadonly"/>
  <div ng-messages="form[name].$error" role="alert">
    <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
    <div ng-message="pattern">{{error | translate}}</div>
  </div>
</md-input-container>
