<div layout="row" layout-align="space-around center">

  <md-card flex-gt-md="50" flex-xs="100" flex="70" >
    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline">{{'NEW_SEASON_UPLOAD' | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>
      <form name="seasonForm">
        <div layout-align="center center" layout="row" layout-padding>
          <div layout="column" flex layout-padding>
            <div layout="column" layout-align="center none" style="padding: 0 10% 0 10%">
              <md-input-container>
                <label>{{'SEASON_NAME' | translate}}</label>
                <md-icon md-svg-src="images/ic_person_black_48px.svg"></md-icon>
                <input title="name" type="text" required ng-model="vm.temporada.name"/>
                <div ng-messages="seasonForm.name.$error" role="alert">
                  <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                </div>
              </md-input-container>
            </div>
            <div layout="column" layout-gt-md="row" layout-align="space-around center" style="padding: 0 0 2% 0">
              <div layout="row" layout-align="start center" layout-padding>
                <label>{{'INI_DATE' | translate}}</label>
                <md-datepicker ng-model="vm.temporada.data1" md-current-view="year" ng-required="true"
                               md-placeholder="{{'INI_DATE' | translate}}"></md-datepicker>
              </div>
              <div layout="row" layout-align="start center" layout-padding>
                <label>{{'END_DATE' | translate}}</label>
                <md-datepicker ng-model="vm.temporada.data2" md-current-view="year" ng-required="true"
                               md-placeholder="{{'END_DATE' | translate}}"></md-datepicker>
              </div>
            </div>
            <md-divider></md-divider>
            <div layout="column" layout-gt-md="row" layout-align="space-around center" style="padding: 0 0 2% 0">
              <div layout="row" layout-align="start center" layout-padding>
                <label>{{'SPORT_MATCH_DAY' | translate}}</label>
                <md-datepicker ng-model="vm.temporada.datajornadaesportiva" md-current-view="year" ng-required="false"
                               md-placeholder="{{'SPORT_MATCH_DAY' | translate}}"></md-datepicker>
              </div>
            </div>
            <md-divider></md-divider>
            <div layout-align="center center">
              <span><i>{{'AUTOMATIC_IMPORT' | translate}}</i></span>
            </div>
          </div>
        </div>
        <div flex>
          <md-button class="md-fab md-accent md-fab-bottom-right" ng-disabled="seasonForm.$invalid"
                     ng-click="vm.confirmAlta()">
            <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
            <md-tooltip md-delay="50" md-direction="left">{{'SAVE' | translate}}</md-tooltip>
          </md-button>
        </div>
      </form>
    </md-card-content>
  </md-card>
</div>
