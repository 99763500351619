<div ui-view>
  <neo-list data="vm.data"></neo-list>
  <div layout-padding>
    <a ui-sref="home.championships.newChampionship" ng-if="vm.can('LOCAL_PHASE', 'C')">
      <md-button class="md-fab md-accent md-fab-bottom-right">
        <md-icon md-svg-src="images/ic_add_white_48px.svg" class="s24" aria-label="add"></md-icon>
        <md-tooltip md-delay="50" md-direction="left">{{'CHAMPIONSHIPS.NEW' | translate}}</md-tooltip>
      </md-button>
    </a>
  </div>
</div>
