<div layout="row" layout-align="space-around center">

  <md-card flex-gt-md="50" flex-xs="100" flex="70">
    <div class="md-whiteframe-1dp" flex>
      <md-card-header layout-padding>
        <md-card-header-text>
          <span class="md-headline">{{'NEW_PHASE_UPLOAD' | translate}}</span>
        </md-card-header-text>
      </md-card-header>
    </div>
    <md-card-content>
      <form name="phaseForm">
        <div layout-align="center center" layout="row" layout-padding>
          <div layout="column" layout-padding>
            <div layout="row" layout-align="space-around center" flex>
              <div layout="column" flex-lt-md="100" flex="50">
                <md-input-container>
                  <label>{{'PHASES.PHASE_NAME' | translate}}</label>
                  <md-icon md-svg-src="images/ic_title_black_48px.svg"></md-icon>
                  <input name="name" type="text" required ng-model="vm.phase.name"/>
                  <div ng-messages="phaseForm.name.$error" role="alert">
                    <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                  </div>
                </md-input-container>
              </div>
              <div layout="column" flex-lt-md="100" flex="35" ng-if="vm.can('REGIONAL_PHASE', 'C')">
                <md-radio-group name="type" required ng-model="vm.phase.type">
                  <md-radio-button value="regional">{{ "PHASES.PHASES_TYPES.REGIONAL" | translate }}
                  </md-radio-button>
                  <md-radio-button value="local">{{ "PHASES.PHASES_TYPES.LOCAL" | translate }}
                  </md-radio-button>
                </md-radio-group>
                <div layout="row" ng-if="vm.phase.type === 'local'" flex>
                  <md-autocomplete flex md-selected-item="vm.selectedTownHall"
                                   md-search-text="vm.searchText"
                                   md-items="townHall in vm.townHalls | filterAccents: vm.searchText"
                                   md-item-text="townHall.name"
                                   md-min-length="0"
                                   md-require-match
                                   ng-required="true"
                                   md-clear-button="true"
                                   md-floating-label="{{'SELECT_TOWNHALL' | translate}}">
                    <md-item-template>
                                <span md-highlight-text="filter.searchText"
                                      md-highlight-flags="^i">{{ townHall.name }}</span>
                    </md-item-template>
                    <md-not-found>
                      {{'RESULT_NOT_FOUND' | translate}}
                    </md-not-found>
                  </md-autocomplete>
                </div>
              </div>
            </div>
            <div layout="column" layout-gt-md="row" layout-align="space-around center" style="padding: 0 0 2% 0">
              <div layout="row" layout-align="start center" layout-padding>
                <md-input-container>
                  <label>{{'INI_DATE' | translate}}</label>
                  <md-datepicker name="iniDate" ng-model="vm.iniDate" md-current-view="month"
                                 ng-required="true"></md-datepicker>
                  <div ng-messages="phaseForm.iniDate.$error" role="alert">
                    <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                  </div>
                </md-input-container>
              </div>
              <div layout="row" layout-align="start center" layout-padding>

                <md-input-container>
                  <label>{{'END_DATE' | translate}}</label>
                  <md-datepicker name="endDate" ng-model="vm.endDate" md-current-view="month"
                                 ng-required="true"></md-datepicker>
                  <div ng-messages="phaseForm.endDate.$error" role="alert">
                    <div ng-message="required">{{'REQUIRED_INPUT' | translate}}</div>
                  </div>
                </md-input-container>
              </div>
            </div>
          </div>
        </div>
        <div flex>
          <md-button class="md-fab md-accent md-fab-bottom-right" ng-disabled="phaseForm.$invalid"
                     ng-click="vm.savePhase()">
            <md-icon md-svg-src="images/ic_save_white_48px.svg" class="s24" aria-label="save"></md-icon>
            <md-tooltip md-delay="50" md-direction="left">{{'SAVE' | translate}}</md-tooltip>
          </md-button>
        </div>
      </form>
    </md-card-content>
  </md-card>
</div>
